import { createStore, compose, applyMiddleware } from 'redux';
// import { config } from '../config/config';
import thunk from 'redux-thunk';
// import { log } from 'utils/utils';

import Reducer from './reducer';

const logger = store => next => (action) => {
  // if (config.debugStore) {
  //   log(`dispatching ${JSON.stringify(action)}`);
  // }
  const result = next(action);
  // if (config.debugStore) {
  //   log(`next state  ${JSON.stringify(store.getState())}`);
  // }
  return result;
};

const enhancers = compose(
  applyMiddleware(logger, thunk),
  window.devToolsExtension ? window.devToolsExtension() : f => f,
);

export default createStore(
  Reducer,
  enhancers,
);

import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './text-input-field.sass';

function TextInputField(props) {
  const {
    input,
    label,
    autocomplete,
    placeholder,
    type,
    meta,
    className,
    refName,
    hideError,
    autoFocus,
    disabled,
  } = props;
  const { touched, error } = meta;
  const showError = !hideError && (touched && error);

  return (
    <div className={classNames('text-input-field', className, { error: showError })}>
      <label htmlFor={input.name}>{label}</label>
      <div>
        <input
          {...input}
          ref={refName}
          placeholder={placeholder}
          autoComplete={autocomplete}
          type={type}
          className={classNames({ error: touched && error })}
          autoFocus={autoFocus}
          disabled={disabled}
        />
        { showError && <span className="input-error">{error}</span> }
      </div>
    </div>
  );
}

TextInputField.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  autocomplete: 'off',
};

TextInputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  type: PropTypes.string,
};

export default TextInputField;

import React from 'react';
import { reduxForm } from 'redux-form';
import { ReactSVG } from 'react-svg';
import { VerificationMethodsEnum } from '../../../../../actions/actions';
import AuthenticatorAppIcon from '../../../../../assets/images/software_totp.svg';
import SmsIcon from '../../../../../assets/images/sms.svg';
import VoiceIcon from '../../../../../assets/images/voicecall.svg';
import EmailIcon from '../../../../../assets/images/email.svg';
import Localization from '../../../../../assets/localization';
import './select-mfa.sass';

const SelectMfaForm = (props) => {
  const { handleSubmit, mfaFactors, onFactorChange } = props;
  const factors = Object.keys(VerificationMethodsEnum);

  const icons = {
    [VerificationMethodsEnum.SOFTWARE_TOTP]: AuthenticatorAppIcon,
    [VerificationMethodsEnum.SMS]: SmsIcon,
    [VerificationMethodsEnum.VOICECALL]: VoiceIcon,
    [VerificationMethodsEnum.EMAIL]: EmailIcon,
  };

  return (
    <form className="select-mfa-form" onSubmit={handleSubmit}>
      <div className="factors-container">
        {mfaFactors.filter(factor => factors.includes(factor)).map(methodName => (
          <div role="button" tabIndex={0} className="factor-container" onClick={() => onFactorChange(methodName)} key={methodName}>
            <span className="icon-cell">
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.classList.add('icon');
                }}
                src={`${icons[methodName]}`}
              />
            </span>
            <span className="factor-title">{Localization.SELECT_MFA_PAGE[`${methodName}_FACTOR_TITLE`]}</span>
          </div>
        ))}
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'select-mfaForm',
  touchOnBlur: false,
})(SelectMfaForm);

import {
  forgotOrg,
  ACTION_NAMES,
} from '../../actions/actions';
import Localization from '../../assets/localization';

const initialState = {
  loading: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.FORGOT_ORG_INITIAL_STATE });
  };
}

export function sendOrgsEmail(email) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.FORGOT_ORG_REQUEST_START });

    forgotOrg(email).then(() => {
      dispatch({ type: ACTION_NAMES.FORGOT_ORG_REQUEST_SUCCESS, email });
    }).catch((response = {}) => {
      const errorMessage = (response.response && response.response.data
        && response.response.data.title)
        || Localization.INTERNAL_ERROR_MESSAGE;
      dispatch({ type: ACTION_NAMES.FORGOT_ORG_REQUEST_FAIL, error: errorMessage });
    });
  };
}

export default function forgotOrgReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.FORGOT_ORG_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.FORGOT_ORG_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.FORGOT_ORG_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true,
        email: action.email };

    case ACTION_NAMES.FORGOT_ORG_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    default:
      return { ...state };
  }
}

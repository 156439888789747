import React, { Component } from 'react';
import MetaLoader from '../../components/meta-loader';
import {
  getUrlParameter,
  saveCookie,
  getCookie,
} from '../../utils/utils';
import moment from 'moment';
import {
  getAppearanceSettings,
} from '../../actions/actions';
import { PERSIST_LOGIN } from '../../consts/general-consts';

class PersistSSOViewContainer extends Component {
  componentDidMount() {
    const persistLogin = getCookie(PERSIST_LOGIN);
    const idpId = getUrlParameter('idp_id');
    const next = getUrlParameter('next');
    const now = moment();
    let persistObject;
    try {
      persistObject = persistLogin && JSON.parse(persistLogin);
    } catch (e) {
      console.log(e);
    }
    if (!persistObject?.idpId && idpId) {
      getAppearanceSettings().then((response = {}) => {
        const persistSSO = response?.data?.persist_sso;
        const expiration = now.add(persistSSO, 'minutes');
        const persistData = JSON.stringify({ idpId, expiration });
        saveCookie(PERSIST_LOGIN, persistData, { expirationInSeconds: persistSSO * 60 });
        window.location.href = next;
      });
    } else if (persistObject?.idpId && persistObject.idpId !== idpId) {
      const diffInSeconds = moment(persistObject.expiration).diff(now, 'seconds');
      const persistData = JSON.stringify({ idpId, expiration: persistObject.expiration });
      saveCookie(PERSIST_LOGIN, persistData, { expirationInSeconds: diffInSeconds });
      window.location.href = next;
    } else {
      window.location.href = next;
    }
  }

  render() {
    return (
      <div>
        <MetaLoader />
      </div>
    );
  }
}

export default PersistSSOViewContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import ClassNames from 'classnames';
import ShowPasswordIcon from '../../assets/images/show-password.svg';
import HidePasswordIcon from '../../assets/images/hide-password.svg';
import TextInputField from './text-input-field';
import './password-input-field.sass';

function TogglePasswordButton(props) {
  const { isPasswordShown, onClick } = props;
  const imgSrc = isPasswordShown ? ShowPasswordIcon : HidePasswordIcon;
  return (
    <div role="button" tabIndex={0} onClick={onClick} className={ClassNames('show-password-icon', isPasswordShown ? 'password-shown' : '')}>
      <ReactSVG
        beforeInjection={(svg) => {
          svg.classList.add('icon');
        }}
        src={imgSrc}
      />
    </div>
  );
}

class PasswordInputField extends Component {
  constructor(props) {
    super(props);
    this.togglePasswordReveal = this.togglePasswordReveal.bind(this);
    this.state = {
      isPasswordRevealed: false,
    };
  }

  togglePasswordReveal() {
    const { isPasswordRevealed } = this.state;
    this.setState({ isPasswordRevealed: !isPasswordRevealed });
  }

  render() {
    const { input, revealPassword = true } = this.props;
    const showRevealButton = Boolean(input.value) && revealPassword;
    return (
      <div className="password-input-field">
        <TextInputField {...this.props} className={showRevealButton ? 'wrapped-text-input-field' : ''} type={this.state.isPasswordRevealed ? 'text' : 'password'} />
        { showRevealButton && (
          <TogglePasswordButton
            isPasswordShown={this.state.isPasswordRevealed}
            onClick={this.togglePasswordReveal}
          />
        )}
      </div>
    );
  }
}

PasswordInputField.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  autocomplete: 'off',
};

PasswordInputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  type: PropTypes.string,
};

export default PasswordInputField;

import React, { Component } from 'react';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import MajorButton from '../../../../components/major-button';
import SecondaryButton from '../../../../components/secondary-button';
import { TextInputField } from '../../../../components/form-fields';
import Localization from '../../../../assets/localization';
import './verify-pin-form.sass';

const validate = (values) => {
  const errors = {};

  if (!values.pin) {
    errors.pin = 'This field is Required';
  }
  return errors;
};

class VerifyPinForm extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.method !== this.props.method) {
      this.focusOnInput();
    }
  }

  focusOnInput() {
    if (this.inputFieldRef) {
      this.inputFieldRef.focus();
    }
  }

  render() {
    const {
      handleSubmit,
      loading = false,
      invalid = false,
      showResend = false,
      onResend,
    } = this.props;

    return (
      <form className="verify-pin-form" onSubmit={handleSubmit}>
        <Field
          name="pin"
          type="text"
          component={TextInputField}
          autoFocus
          placeholder={Localization.VERIFY_PIN_PAGE.VERIFICATION_CODE_FIELD_PLACEHOLDER}
          className="password-field"
          icon="icon-ic-lock"
          refName={(input) => { this.inputFieldRef = input; }}
          hideError
        />
        <SecondaryButton
          title={Localization.VERIFY_PIN_PAGE.RESEND_CODE_BUTTON}
          onClick={onResend}
          style={{ marginBottom: '10px' }}
          className={classNames({ hidden: !showResend })}
        />
        <MajorButton
          title="verify"
          type="submit"
          disabled={loading || invalid}
          className="verify-pin-form-major-button"
          style={{ margin: '20px auto 20px', textTransform: 'uppercase' }}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'verifyPinForm',
  touchOnBlur: false,
  validate,
})(VerifyPinForm);

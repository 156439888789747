import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { config } from '../../../../config/config';
import MetaButton from '../../../../components/meta-button';
import { BUTTON_DESIGNS } from '../../../../consts/consts';
import BottomLink from '../../../../components/bottom-link/bottom-link';
import Localization from '../../../../localization';
import { ROUTES } from '../../../../consts/routes';
import './login-no-org-form.sass';

const validate = values => {
  const errors = {};

  if (!values.org) {
    errors.org = Localization.FORM_FIELDS.REQUIRED_FIELD_ERROR;
  }

  return errors;
};

const LoginNoOrgForm = props => {
  const { handleSubmit, loading = false, envSuffix, invalid } = props;

  return (
    <form className="login-no-org-form" onSubmit={handleSubmit}>
      <div className="input-group">
        <Field
          component="input"
          className="org-input"
          type="text"
          placeholder={Localization.LOGIN_NO_ORG_PAGE.SHORTNAME_FORM_FIELD}
          name="org"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          autoCorrect="off"
        />
        <span className="org-suffix">
          {envSuffix}
          .
          {config.metaSite}
        </span>
      </div>
      <MetaButton
        text={Localization.LOGIN_NO_ORG_PAGE.SUBMIT_BUTTON_TEXT}
        buttonType="submit"
        disabled={loading || invalid}
        variant={BUTTON_DESIGNS.SECONDARY}
      />
      <BottomLink textBeforeLink={Localization.LOGIN_NO_ORG_PAGE.BOTTOM_LINK_BEFORE_TEXT} />
      <BottomLink
        linkTitle={Localization.LOGIN_NO_ORG_PAGE.BOTTOM_LINK_TEXT}
        linkHref={ROUTES.FORGOT_ORG}
      />
    </form>
  );
};

export default reduxForm({
  form: 'loginNoOrgForm',
  touchOnBlur: false,
  validate,
})(LoginNoOrgForm);

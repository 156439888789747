export const ERRORS = {
  REQUIRED_PROPERTY: '\'username\' is a required property',
  LOCKED_USER: 'The user is locked',
  INVALID_CLIENT: 'invalid_client',
  INVALID_TOKEN: 'invalid_token',
  INVALID_CODE: 'Invalid OTP token',
  TOO_MANY_ATTEMPTS: 'Rate limit for login has exceeded, please wait before attempting to login again',
  SSO_REQUIRED: 'The client must use SSO to login',
};

export default {
  ERRORS,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MetaLoader from '../../../../../components/meta-loader';
import PageTitles from '../../../../../components/meta-page-titles';
import Localization from '../../../../../assets/localization';
import { FormError } from '../../../../../components/form-fields';
import SetPhoneForm from './set-phone-form';
import './set-phone.sass';

class SetPhonePage extends Component {
  render() {
    const { loading, errorMessage } = this.props;
    return (
      <div>
        <div className="form-background">
          <div className="form-content">
            <PageTitles
              subtitle={Localization.SET_EMAIL_PAGE.TITLE}
            />
            <SetPhoneForm
              onSubmit={this.props.setPhone}
              loading={this.props.loading}
              initialValues={{ phone: this.props.phone }}
              handleBackBtnClick={this.props.handleBackBtnClick}
            />
          </div>
          {loading
            ? <div className="loader-wrapper"><MetaLoader isShown={loading} /></div>
            : <FormError message={errorMessage} isShown={Boolean(errorMessage)} />}
        </div>
      </div>
    );
  }
}

SetPhonePage.defaultProps = {
  errorMessage: '',
  loading: false,
  setPhone: () => {},
};

SetPhonePage.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  setPhone: PropTypes.func,
};

export default SetPhonePage;

export default {
  BLOCK_PAGE: {
    MAIN_TITLE: 'Accesso negato!',
    TITLE: 'Sei bloccato dalla navigazione a',
    REASONS: {
      CUSTOM: 'È classificato come sito Web vietato dalla tua azienda',
      ATP: 'È classificato come un file ',
      CONTENT: 'È classificato come ',
      THREAT: 'È classificato come ',
      COUNTRY: 'Si trova in un paese vietato in base alla politica aziendale',
    },
    MALICIOUS_WEBSITE: 'sito Web dannoso',
    SECURED_BY: 'La navigazione in Internet è protetta da',
    REPLY: 'Per richiedere una riclassificazione del sito web, ',
    CLICK_HERE: 'clicca qui',
    FIRST: 'Per ulteriori informazioni sulla politica aziendale\ncontatta il tuo amministratore IT.',
    SUBJECT_TEXT: 'Richiesta di rivalutazione della classificazione del sito web',
    DEFAULT_OPENING: 'Credo che un URL che ho provato a navigare sia stato bloccato in modo errato. Tutte le informazioni pertinenti possono essere trovate di seguito.',
    TENANT_ID: 'Tenant ID',
    URL: 'URL',
    POLICY_ID: 'Policy ID',
    USERNAME: 'Nome utente',
    THANK_END: 'Grazie',
  },
};

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { VerificationMethodsEnum } from '../../../../actions/actions';
import { ROUTES } from '../../../../consts/routes';
import { STATUS_CODES } from '../../../../consts/status-codes';
import { sendPhoneChallenge } from '../../../app/app-state';
import { cleanState, chooseFactor } from './select-mfa-state';
import { skipEnroll } from '../../../login/login-state';
import SelectMfaPage from './components/select-mfa-page';

class SelectMfaContainer extends Component {
  componentWillUnmount() {
    this.props.cleanState(['mfaFactors', 'factor', 'mfaStatus', 'skippable']);
  }

  onChangeMethod = (factor) => {
    const { stateToken, mfaStatus } = this.props;
    if (mfaStatus === STATUS_CODES.MFA_REQUIRED
      && factor !== VerificationMethodsEnum.SOFTWARE_TOTP) {
      this.props.sendPhoneChallenge(stateToken, factor);
    }
    return this.props.chooseFactor(factor);
  };

  onSkipEnroll = () => {
    const { stateToken, pkceChallenge } = this.props;
    this.props.skipEnroll(stateToken, pkceChallenge);
  };

  render() {
    const {
      history,
      factor,
      loading,
      skippable,
      error,
      mfaFactors,
      sendPhoneChallengeSuccess,
      mfaStatus,
      stateToken,
    } = this.props;

    if (!mfaFactors || !stateToken) {
      return <Redirect to={`${ROUTES.LOGIN}${history.location.search}`} />;
    }
    if (sendPhoneChallengeSuccess) {
      return <Redirect to={`${ROUTES.VERIFY_PHONE}${history.location.search}`} />;
    }
    if (mfaStatus && (mfaStatus !== STATUS_CODES.MFA_REQUIRED)) {
      if (factor === VerificationMethodsEnum.SMS || factor === VerificationMethodsEnum.VOICECALL) {
        return <Redirect to={`${ROUTES.SET_PHONE}${history.location.search}`} />;
      }
    }
    if (factor === VerificationMethodsEnum.SOFTWARE_TOTP) {
      if (mfaStatus === STATUS_CODES.MFA_REQUIRED) {
        return <Redirect to={`${ROUTES.VERIFY_AUTHENTICATOR}${history.location.search}`} />;
      }
      return <Redirect to={`${ROUTES.SET_AUTHENTICATOR}${history.location.search}`} />;
    }
    return (
      <SelectMfaPage
        loading={loading}
        skippable={skippable}
        errorMessage={error}
        mfaFactors={mfaFactors}
        mfaStatus={mfaStatus}
        onFactorChange={this.onChangeMethod}
        onSkipEnroll={this.onSkipEnroll}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    stateToken: state.AppState.stateToken,
    ...state.selectMfaState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cleanState, chooseFactor, skipEnroll, sendPhoneChallenge,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectMfaContainer));

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { ROUTES } from '../../../../consts/routes';
import { backToLoginClick, cleanState, setEmail } from './set-email-state';
import SetEmailPage from './components/set-email-page';

class SetEmailContainer extends Component {
  componentWillUnmount() {
    this.props.cleanState();
  }

  setEmail = (formData) => {
    this.props.setEmail(formData.email);
  };

  backToLoginClick = () => {
    this.props.backToLoginClick();
  };

  render() {
    const { success, history, email } = this.props;
    if (success) {
      const parsedQs = queryString.parse(history.location.search);
      parsedQs.email = email;
      // redirecting to verify-email while adding user email as a url param
      return <Redirect to={`${ROUTES.VERIFY_EMAIL}?${queryString.stringify(parsedQs)}`} />;
    }
    return (
      <SetEmailPage
        setEmail={this.setEmail}
        loading={this.props.loading}
        errorMessage={this.props.error}
        handleLinkClick={this.backToLoginClick}
        email={email}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.setEmailState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setEmail, backToLoginClick, cleanState }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SetEmailContainer);

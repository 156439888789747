import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTES } from '../../consts/routes';
import { setPassword, cleanState } from '../reset-password/set-password-state';
import Localization from '../../assets/localization';
import ResetPasswordPage from './components/reset-password-page';

class SetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
    };
  }

  componentWillUnmount() {
    this.props.cleanState();
  }

  changePassword = (formData) => {
    const { stateToken, pkceChallenge } = this.props;
    this.props.setPassword(formData.password, stateToken, pkceChallenge);
  };

  passwordFieldChange = (state) => {
    this.setState({ isValid: state.isValid });
  };

  render() {
    const { mfaFactors, history, wrongStatus, stateToken, passwordExpired } = this.props;
    const { isValid } = this.state;
    if (!stateToken) {
      return <Redirect to={`${ROUTES.LOGIN}${history.location.search}`} />;
    }
    if (mfaFactors && mfaFactors.length > 0 && !passwordExpired) {
      return <Redirect to={`${ROUTES.SELECT_MFA}${history.location.search}`} />;
    }
    if (wrongStatus) {
      return (
        <Redirect to={{
          pathname: `${ROUTES.ERROR_PAGE}${history.location.search}`,
          state: { status: wrongStatus },
        }}
        />
      );
    }
    return (
      <div className="login-wrapper">
        <ResetPasswordPage
          resetPassword={this.changePassword}
          loading={this.props.loading}
          errorMessage={this.props.error}
          subtitle={this.props.subtitle}
          paragraph={this.props.paragraph}
          btnText={this.props.btnText}
          passwordFieldChange={this.passwordFieldChange}
          passwordExpired={passwordExpired}
          isValid={isValid || false}
          bottomLinkBeforeText={Localization.SET_PASSWORD_PAGE.BOTTOM_LINK_BEFORE_TEXT}
          bottomLinkText={Localization.SET_PASSWORD_PAGE.BOTTOM_LINK_TEXT}
          bottomLinkHref={ROUTES.SET_EMAIL}
          bottomLinkShow={this.props.tokenExpired}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.AppState,
    ...state.setPasswordState,
    mfaFactors: state.selectMfaState.mfaFactors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setPassword, cleanState }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetPasswordContainer));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import successIcon from '../../assets/images/status-success.svg';
import './form-success.sass';

function FormSuccess(props) {
  const { message, className, isShown } = props;
  return (
    <div className={classNames('meta-form-success', isShown ? '' : 'hidden', className)}>
      <span className="input-success">{message}</span>
      <div className="success-icon">
        <img src={successIcon} alt="success" />
      </div>
    </div>
  );
}

FormSuccess.defaultProps = {
  message: '',
  isShown: true,
};

FormSuccess.propTypes = {
  message: PropTypes.string,
  isShown: PropTypes.bool,
};

export default FormSuccess;

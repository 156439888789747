import React, { Component } from 'react';
import classNames from 'classnames';
import { getWhiteLabelAsset, checkImage } from '../../utils/utils';
import Logo from '../logo';
import './background.sass';

class Background extends Component {
  state = {};

  componentDidMount() {
    const backgroundPath = getWhiteLabelAsset('login_background');
    checkImage(
      backgroundPath,
      () => this.setState({ style: { backgroundImage: `url(${backgroundPath})` }, whiteLabel: true }),
      () => this.setState({
        style: {
          backgroundColor: '#01579B',
        },
      }),
    );
  }

  render() {
    const { style, whiteLabel } = this.state;
    const { className, noHexagon, children } = this.props;
    return style ? (
      <div className={classNames('background-container', className)} style={style}>
        {!noHexagon && !whiteLabel && <div className="background-hexagon" />}
        <div className="background-children-container">
          <Logo />
          {children}
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

export default Background;

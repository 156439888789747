export default {
  BLOCK_PAGE: {
    MAIN_TITLE: '¡Acceso denegado!',
    TITLE: 'Está bloqueado para navegar a',
    REASONS: {
      CUSTOM: 'Está categorizado como un sitio web prohibido por su empresa.\n',
      ATP: 'Está categorizado como ',
      CONTENT: 'Está categorizado como ',
      THREAT: 'Está categorizado como ',
      COUNTRY: 'Está en un país prohibido según la política de su empresa',
    },
    MALICIOUS_WEBSITE: 'sitio web malicioso',
    SECURED_BY: 'La navegación por Internet está protegida por',
    REPLY: 'Para solicitar una reclasificación del sitio web, ',
    CLICK_HERE: 'haga clic aquí',
    FIRST: 'Para obtener más información sobre la política de la empresa\ncomuníquese con su administrador de TI.',
    SUBJECT_TEXT: 'Solicitud para reevaluar la clasificación del sitio web',
    DEFAULT_OPENING: 'Creo que una URL que intenté navegar fue bloqueada incorrectamente. Toda la información pertinente se puede encontrar a continuación.',
    TENANT_ID: 'Tenant ID',
    URL: 'URL',
    POLICY_ID: 'Policy ID',
    USERNAME: 'Nombre de usuario\n',
    THANK_END: 'Gracias',
  },
};

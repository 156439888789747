import Localization from '../assets/localization';

export const REPORTS_NAMES = {
  users_proxy: {
    title: Localization.REPORT_DISPLAY_NAMES.WS_USERS,
    titleForDownload: 'users',
    isWS: true,
  },
  users_ztna: {
    title: Localization.REPORT_DISPLAY_NAMES.ZTNA_USERS,
    titleForDownload: 'users',
    isZTNA: true,
  },
  proxy_sessions: {
    title: Localization.REPORT_DISPLAY_NAMES.WS_DEVICES,
    titleForDownload: 'devices',
    isWS: true,
  },
  devices_ztna: {
    title: Localization.REPORT_DISPLAY_NAMES.ZTNA_DEVICES,
    titleForDownload: 'devices',
    isZTNA: true,
  },
  network_resources: {
    title: Localization.REPORT_DISPLAY_NAMES.MAPPED_RESOURCES,
    titleForDownload: 'mapped_resources',
    isZTNA: true,
  },
  proxy_users_activity: {
    title: Localization.REPORT_DISPLAY_NAMES.WS_USER_ACTIVITY,
    isWS: true,
  },
  proxy_categories: {
    title: Localization.REPORT_DISPLAY_NAMES.CATEGORIES,
    isWS: true,
  },
  proxy_top_sites: {
    title: Localization.REPORT_DISPLAY_NAMES.TOP_SITES,
    isWS: true,
  },
  proxy_actions: {
    title: Localization.REPORT_DISPLAY_NAMES.TOP_ACTIONS,
    isWS: true,
  },
  proxy_dst_countries: {
    title: Localization.REPORT_DISPLAY_NAMES.DEST_COUNTRIES,
    isWS: true,
  },
  proxy_top_cloud_apps: {
    title: Localization.REPORT_DISPLAY_NAMES.TOP_CLOUD_APPS,
    isWS: true,
  },
  proxy_executive: {
    title: Localization.REPORT_DISPLAY_NAMES.WEB_SECURITY,
    titleForDownload: 'executive_report',
    isWS: true,
  },
  activeUsers: {
    title: Localization.REPORT_DISPLAY_NAMES.ACTIVE_USERS,
    isZTNA: true,
  },
  proxy_unprotected_devices: {
    title: Localization.REPORT_DISPLAY_NAMES.PROXY_UNPROTECTED_DEVICES,
    isWS: true,
  },
};

export default REPORTS_NAMES;

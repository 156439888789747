import { removeCookie } from '../../utils/utils';
import {
  authorize as authorizeAction,
  verifyPin,
  setFactorAuthenticationMethod,
  sendChallenge,
  ACTION_NAMES,
} from '../../actions/actions';
import {
  APPLICATION_ENUMS,
  getClientId,
  getNetworkElementId,
  getNextRoute,
  redirectToRoute,
  extractErrMsg,
  log,
} from '../../utils/utils';
import { SESSION_TOKEN } from '../../consts/general-consts';
import Localization from '../../assets/localization';

const initialState = {};

export function authorize(sessionToken, silent = false, pkceChallenge) {
  return (dispatch) => {
    log(`ACTION: authorize isSilent ${silent}`);
    dispatch({ type: ACTION_NAMES.AUTHORIZE_REQUEST_START });
    const clientId = getClientId();
    let networkElementId = '';
    if (clientId === APPLICATION_ENUMS.OVERLAY) {
      networkElementId = getNetworkElementId();
    }

    const nextRoute = getNextRoute();
    const { nextUrl, redirectUri } = nextRoute;
    authorizeAction(clientId, networkElementId, sessionToken, pkceChallenge, redirectUri)
      .then((response) => {
        const { code } = response.data;
        dispatch({ type: ACTION_NAMES.AUTHORIZE_REQUEST_SUCCESS });
        redirectToRoute(nextUrl, { code });
      }).catch((error = {}) => {
        const errorMessage = extractErrMsg(error, true);
        log(`ERR: authorize ${errorMessage}`);
        removeCookie(SESSION_TOKEN);
        dispatch({ type: ACTION_NAMES.AUTHORIZE_REQUEST_FAIL, error, silent, errorMessage });
      });
  };
}

/* TODO check if verifyFactor can be used instead - UI-207 */
export function verifyPinCode(pin, stateToken, verificationMethod, pkceChallenge) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.VERIFY_PIN_REQUEST_START });
    log(`ACTION: verifyPinCode verificationMethod: ${verificationMethod}`);
    verifyPin(pin, stateToken, verificationMethod).then((response = {}) => {
      const sessionToken = (response && response.data && response.data.session_token);
      dispatch({ type: ACTION_NAMES.VERIFY_PIN_REQUEST_SUCCESS });
      dispatch(authorize(sessionToken, false, pkceChallenge));
    }).catch((response = {}) => {
      const errorMessage = extractErrMsg(response);
      log(`ERR: verifyPinCode ${errorMessage}`);
      const error = response.message === 'Network Error'
        ? Localization.NO_CONNECTION_MESSAGE
        : Localization.LOGIN_PAGE.FAILED_TO_VERIFY_TRY_AGAIN;
      dispatch({ type: ACTION_NAMES.VERIFY_PIN_REQUEST_FAIL, error });
    });
  };
}

export function setPhone(phone, factor, sessionToken) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SET_PHONE_REQUEST_START });
    log(`ACTION: setPhone: ${phone}`);
    setFactorAuthenticationMethod(sessionToken, { phone, factor_type: factor }).then((response) => {
      const stateToken = (response && response.data && response.data.state_token);
      dispatch({ type: ACTION_NAMES.GET_STATE_TOKEN_SUCCESS, stateToken });
      dispatch({ type: ACTION_NAMES.SET_PHONE_REQUEST_SUCCESS, phone });
    }).catch((response = {}) => {
      const errorMessage = extractErrMsg(response);
      log(`ERR: setPhone ${errorMessage}`);
      dispatch({ type: ACTION_NAMES.SET_PHONE_REQUEST_FAIL, error: errorMessage });
    });
  };
}

export function sendPhoneChallenge(stateToken, factor) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SEND_PIN_REQUEST_START });
    log(`ACTION: sendPhoneChallenge: ${factor}`);
    sendChallenge(factor, stateToken).then((response = {}) => {
      const phone = response.data && response.data.phone;
      dispatch({ type: ACTION_NAMES.SEND_PHONE_CHALLENGE_SUCCESS, phone });
    }).catch((response = {}) => {
      const errorMessage = extractErrMsg(response, true);
      log(`ERR: sendPhoneChallenge ${errorMessage}`);
      dispatch({ type: ACTION_NAMES.SEND_PHONE_CHALLENGE_FAIL, error: errorMessage });
    });
  };
}

export default function AppReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.GET_STATE_TOKEN_SUCCESS:
      return { ...state,
        stateToken: action.stateToken,
        passwordExpired: false };
    case ACTION_NAMES.PASSWORD_EXPIRED:
      return { ...state,
        stateToken: action.stateToken,
        passwordExpired: true };
    case ACTION_NAMES.GET_USER_PHONE_SUCCESS:
      return { ...state, phone: action.phone };

    case ACTION_NAMES.WRONG_STATUS: {
      return { ...state, wrongStatus: action.status };
    }

    default:
      return { ...state };
  }
}

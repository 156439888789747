import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import MetaLoader from '../../../../components/meta-loader';
import PageTitles from '../../../../components/meta-page-titles';
import { FormError, FormSuccess } from '../../../../components/form-fields';
import { VerificationMethodsEnum } from '../../../../actions/actions';
import Localization from '../../../../assets/localization';
import AuthenticatorAppIcon from '../../../../assets/images/software_totp.svg';
import DropDownArrow from '../../../../assets/images/drop-down-arrow.svg';
import SmsIcon from '../../../../assets/images/sms.svg';
import VoiceIcon from '../../../../assets/images/voicecall.svg';
import VerifyPinForm from './verify-pin-form';
import VerifyPinPopover from './verify-pin-popover';
import './verify-pin-page.sass';

const AuthenticatorVerificationMessage = () => (
  <PageTitles
    paragraph={Localization.VERIFY_PIN_PAGE.AUTHENTICATOR_GET_VERIFICATION_CODE}
    paragraphStyle={{ marginBottom: '44px', minHeight: '46px' }}
  />
);

const SmsVerificationMessage = props => (
  <React.Fragment>
    <PageTitles
      paragraph={`${Localization.VERIFY_PIN_PAGE.SMS_MESSAGE_WITH_CODE_SENT}\n${props.phoneNumber}`}
      paragraphStyle={{ marginBottom: '44px' }}
    />
  </React.Fragment>
);

const VoiceVerificationMessage = props => (
  <React.Fragment>
    <PageTitles
      paragraph={`${Localization.VERIFY_PIN_PAGE.VOICE_CALL_WITH_CODE_SENT}\n${props.phoneNumber}`}
      paragraphStyle={{ marginBottom: '44px' }}
    />
  </React.Fragment>
);

const EmailVerificationMessage = props => (
  <React.Fragment>
    <PageTitles
      paragraph={`${Localization.VERIFY_PIN_PAGE.EMAIL_WITH_CODE_SENT}\n${props.email}`}
      paragraphStyle={{ marginBottom: '44px' }}
    />
  </React.Fragment>
);

const getVerificationIcon = (verificationMethod) => {
  switch (verificationMethod) {
    case VerificationMethodsEnum.SOFTWARE_TOTP:
      return AuthenticatorAppIcon;
    case VerificationMethodsEnum.VOICECALL:
      return VoiceIcon;
    default:
      return SmsIcon;
  }
};

const getVerificationMessage = (verificationMethod, factorInfo) => {
  switch (verificationMethod) {
    case VerificationMethodsEnum.SOFTWARE_TOTP:
      return <AuthenticatorVerificationMessage />;
    case VerificationMethodsEnum.EMAIL:
      return <EmailVerificationMessage email={factorInfo} />;
    case VerificationMethodsEnum.VOICECALL:
      return <VoiceVerificationMessage phoneNumber={factorInfo} />;
    default:
      return <SmsVerificationMessage phoneNumber={factorInfo} />;
  }
};

function showResend(verificationMethod) {
  //  TODO: check if this email addition is relevant if this page can even be reached
  return (
    verificationMethod === VerificationMethodsEnum.SMS
    || verificationMethod === VerificationMethodsEnum.VOICECALL
    || verificationMethod === VerificationMethodsEnum.EMAIL
  );
}

class VerifyPinPage extends Component {
  onClosePopover = () => {
    this.props.toggleMoreMethodsModal(false);
  };

  onChangeMethod = (verificationMethod) => {
    this.props.changeVerificationMethod(verificationMethod);
  };

  getFormErrorOrSuccess = () => {
    const { errorMessage, successMessage } = this.props;
    return errorMessage
      ? <FormError message={errorMessage} />
      : <FormSuccess message={successMessage} isShown={Boolean(successMessage)} />;
  };

  render() {
    const {
      verificationMethod,
      userEmail,
      phoneNumber,
      verificationMethodOptions,
      verifyPinCode,
      resendPinCode,
      loading,
    } = this.props;

    const showMoreVerificationMethodsButton = verificationMethodOptions.length
     && verificationMethodOptions.length > 1;

    const verifyMethodInfo = verificationMethod === VerificationMethodsEnum.EMAIL
      ? userEmail
      : phoneNumber;
    return (
      <div className="verify-pin-page">
        <div className="form-background">
          <div className="form-content">
            <PageTitles
              subtitle={Localization.VERIFY_PIN_PAGE.SUBTITLE}
              subtitleStyle={{ marginBottom: '0' }}
            />
            {showMoreVerificationMethodsButton && (
              <VerifyPinPopover
                visible={this.props.showVerificationMethodsModal}
                onClose={this.onClosePopover}
                changeMethod={this.onChangeMethod}
                methods={this.props.verificationMethodOptions}
              >
                <div
                  className="more-methods"
                  onClick={() => this.props.toggleMoreMethodsModal(
                    !this.props.showVerificationMethodsModal,
                  )}
                  role="presentation"
                >
                  <div className="more-methods-button-wrapper">
                    <ReactSVG
                      beforeInjection={(svg) => {
                        svg.classList.add('app-icon');
                      }}
                      src={getVerificationIcon(this.props.verificationMethod)}
                    />
                    <div className="drop-down-container">
                      <ReactSVG
                        beforeInjection={(svg) => {
                          svg.classList.add('icon');
                        }}
                        src={DropDownArrow}
                      />
                    </div>
                  </div>
                </div>
              </VerifyPinPopover>
            )}
            {getVerificationMessage(
              this.props.verificationMethod,
              verifyMethodInfo,
            )}
            <VerifyPinForm
              onSubmit={verifyPinCode}
              method={this.props.verificationMethod}
              onResend={resendPinCode}
              showResend={showResend(this.props.verificationMethod)}
              loading={this.props.loading}
            />
          </div>
          {
            loading
              ? <MetaLoader isShown={loading} />
              : this.getFormErrorOrSuccess()
          }
        </div>
      </div>
    );
  }
}

VerifyPinPage.defaultProps = {
  errorMessage: '',
  successMessage: '',
  loading: false,
  showVerificationMethodsModal: false,
};

VerifyPinPage.propTypes = {
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  loading: PropTypes.bool,
  showVerificationMethodsModal: PropTypes.bool,
  verifyPinCode: PropTypes.func.isRequired,
  resendPinCode: PropTypes.func.isRequired,
  toggleMoreMethodsModal: PropTypes.func.isRequired,
  changeVerificationMethod: PropTypes.func.isRequired,
};

export default VerifyPinPage;

import { ACTION_NAMES } from '../../actions/actions';
import Localization from '../../assets/localization';
import { getQueryParams } from '../../utils/utils';
import { createCategoriesString, createBlockDescription } from './blocked-page-utils';

const initialState = {
  title: '',
  threat: '',
  content: '',
  reason: '',
  blockDescription: '',
  blockCategories: '',
};

export function getUrlParams() {
  return (dispatch) => {
    const queryParams = getQueryParams();
    const {
      url = '',
      org_id = '',
      email = '',
      rule_id = '',
      content = '',
      threat = '',
      reason = '',
      contact_email = '',
    } = queryParams;

    dispatch({
      type: ACTION_NAMES.BLOCKED_PAGE_FETCH_PARAMS,
      url,
      orgId: org_id,
      email,
      contact_email,
      rule: rule_id,
      title: Localization.BLOCK_PAGE.TITLE,
      threat,
      blockDescription: createBlockDescription(reason),
      blockCategories: createCategoriesString(reason, content, threat),
    });
  };
}

export default function blockedPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.BLOCKED_PAGE_FETCH_PARAMS:
      return { ...state,
        url: action.url,
        orgId: action.orgId,
        email: action.email,
        rule: action.rule,
        title: action.title,
        threat: action.threat,
        contactEmail: action.contact_email,
        blockDescription: action.blockDescription,
        blockCategories: action.blockCategories };

    default:
      return { ...state };
  }
}

import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isolateRedirect } from './isolate-redirect-state';
import MetaLoader from '../../components/meta-loader';

const AppIsolateRedirect = props => {
  const { samlData, error, errorMessage, redirectURL } = props;

  useEffect(() => {
    if (samlData) {
      setTimeout(() => {
        if (document.metaIdpForm) {
          document.metaIdpForm.submit();
        }
      }, 1000);
    }
  }, [props]);

  useEffect(props.isolateRedirect, []);

  useEffect(() => {
    if (redirectURL) {
      window.location.href = redirectURL;
    }
  }, [redirectURL]);

  return (
    <div className="app-isolation">
      {!error && <MetaLoader />}
      {
        samlData && (
          <form name="metaIdpForm" action={samlData.destination} method="post">
            <input type="hidden" name="SAMLResponse" value={samlData.saml_response} />
            <input type="hidden" name="RelayState" value={samlData.relay_state || ''} />
          </form>
        )
      }
      {
        error && (
          <div>
            {errorMessage}
          </div>
        )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.appIsolationState,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    isolateRedirect,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppIsolateRedirect);

import React, { Component } from 'react';
import SuccessPage from '../../components/success-page';
import Localization from '../../assets/localization';
import { redirectToApp } from '../../utils/utils';

class OpenAppContainer extends Component {
  componentDidMount() {
    redirectToApp();
  }

  render() {
    return (
      <div className="login-wrapper">
        <SuccessPage
          subtitle={Localization.OPEN_APP_PAGE.SUBTITLE}
          paragraphA={Localization.OPEN_APP_PAGE.PARAGRAPH}
        />
      </div>
    );
  }
}

export default OpenAppContainer;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MetaLoader from '../../../../components/meta-loader';
import PageTitles from '../../../../components/meta-page-titles';
import Localization from '../../../../assets/localization';
import IdpButton from '../../../../components/idp-button';
import { FormError } from '../../../../components/form-fields';
import { isAppAndSignup, getIdpIconPath, getIdpIconStyle } from '../../../../utils/utils';
import { INVOKE_LOCAL_LOGIN } from '../../../../consts/general-consts';
import LoginForm from '../login-page/login-form';
import './choose-login-method.sass';

class ChooseLoginMethodPage extends Component {
  getPageTitle() {
    if (isAppAndSignup()) {
      return Localization.LOGIN_CHOOSE_METHOD.SUBTITLE_ONBOARD;
    }
    return Localization.formatString(
      Localization.LOGIN_CHOOSE_METHOD.SUBTITLE,
      this.props.organizationName || this.props.organizationShortName,
    );
  }

  render() {
    const {
      loginWithSaml,
      idps,
      samlCheckError,
      invokeLoginWithSSO,
      appearance,
      hideSSOFromQS,
      hideLocalLoginFromQS,
      pkceChallenge,
      loading,
    } = this.props;
    const hideSSOLogin = (appearance.hideSSOLogin && hideSSOFromQS !== false) || hideSSOFromQS;
    const hideLocalLogin = (appearance.hideLocalLogin && hideLocalLoginFromQS !== false)
      || hideLocalLoginFromQS;
    const visibleIdps = idps.filter(idp => !idp.hidden);
    const hasIdp = !!visibleIdps.length
      && invokeLoginWithSSO !== INVOKE_LOCAL_LOGIN
      && !hideSSOLogin;
    const hasTwoLoginOptions = hasIdp && !hideLocalLogin;
    const loginFormErrorMessage = samlCheckError
      ? Localization.LOGIN_CHOOSE_METHOD.SAML_CHECK_ERROR
      : this.props.errorMessage;
    return (
      <div>
        <div className="form-background choose-login-method">
          <div className="form-content">
            <PageTitles
              subtitle={this.getPageTitle()}
              subtitleStyle={{ marginBottom: '10px' }}
            />
            <PageTitles
              subtitle={Localization.formatString(
                Localization.LOGIN_CHOOSE_METHOD.SUBTITLE_2,
                this.props.serviceName,
              )}
              subtitleStyle={{ marginBottom: '40px', fontSize: '16px' }}
            />

            {
              hasIdp && (
                <div className="idp-container">
                  {visibleIdps.map(idp => (
                    <IdpButton
                      title={Localization.formatString(
                        Localization.LOGIN_CHOOSE_METHOD.IDP_BUTTON,
                        idp.name,
                      )}
                      iconPath={getIdpIconPath(idp.icon)}
                      key={idp.url}
                      automationID={`idp-button-${idp.name}`}
                      onClick={() => loginWithSaml(idp.id, pkceChallenge)}
                      className="idp-button"
                      style={getIdpIconStyle(idp.icon)}
                    />
                  ))}
                </div>
              )
            }
            {hasTwoLoginOptions && (
              <div className="separator-text">
                {Localization.LOGIN_PAGE.SSO_SEPARATOR_TEXT}
              </div>
            )}
            {!hideLocalLogin && (
              <PageTitles
                paragraph={
                  appearance.hideSSOLogin
                    ? Localization.LOGIN_CHOOSE_METHOD.LOGIN_BUTTON_DESCRIPTION
                    : Localization.formatString(
                      Localization.LOGIN_CHOOSE_METHOD.GUEST_LOGIN_BUTTON_DESCRIPTION,
                      this.props.organizationName || this.props.organizationShortName,
                    )
                }
              />
            )}
            {!hideLocalLogin && (
              <LoginForm
                onSubmit={this.props.login}
                loading={this.props.loading}
                isAllValuesExist={this.props.isAllValuesExist}
                showRememberMe={this.props.showRememberMe}
                samlAvailable={this.props.samlAvailable}
                resetErrorMessage={this.props.resetErrorMessage}
                showRefreshButton={samlCheckError}
                loginWithSaml={this.props.loginWithSaml}
                initialValues={{ email: this.props.email }}
              />
            )}
          </div>
          { loading
            ? <MetaLoader isShown={loading} />
            : (
              <FormError
                message={loginFormErrorMessage}
                isShown={Boolean(loginFormErrorMessage)}
              />
            )}
        </div>
      </div>
    );
  }
}

ChooseLoginMethodPage.defaultProps = {
  idps: [],
  organizationName: '',
};

ChooseLoginMethodPage.propTypes = {
  loginWithSaml: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  organizationShortName: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  idps: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(ChooseLoginMethodPage);

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resetPassword, cleanState } from './reset-password-state';
import Localization from '../../assets/localization';
import { ROUTES } from '../../consts/routes';
import ResetPasswordPage from './components/reset-password-page';
import ResetPasswordSuccessPage from './components/reset-password-success-page';

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
    };
  }

  componentWillUnmount() {
    this.props.cleanState();
  }

  resetPassword = (formData) => {
    this.props.resetPassword(formData.password);
  };

  passwordFieldChange = (state) => {
    this.setState({ isValid: state.isValid });
  };

  render() {
    const { isValid } = this.state;
    return (
      <div className="login-wrapper">
        {this.props.success
          ? <ResetPasswordSuccessPage />
          : (
            <ResetPasswordPage
              resetPassword={this.resetPassword}
              loading={this.props.loading}
              errorMessage={this.props.error}
              passwordFieldChange={this.passwordFieldChange}
              isValid={isValid || false}
              bottomLinkBeforeText={Localization.RESET_PASSWORD_PAGE.BOTTOM_LINK_BEFORE_TEXT}
              bottomLinkText={Localization.RESET_PASSWORD_PAGE.BOTTOM_LINK_TEXT}
              bottomLinkHref={ROUTES.FORGOT_PASSWORD}
              bottomLinkShow={this.props.tokenExpired}
            />
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.resetPasswordState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetPassword, cleanState }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forgotPassword, cleanState } from './forgot-password-state';
import SuccessPage from '../../components/success-page';
import Localization from '../../assets/localization';
import { ROUTES } from '../../consts/routes';
import ForgotPasswordPage from './components/forgot-password-page';

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  componentWillUnmount() {
    this.props.cleanState();
  }

  forgotPassword(formData) {
    this.props.forgotPassword(formData.email);
  }

  render() {
    return (
      <div className="login-wrapper">
        {this.props.success
          ? (
            <SuccessPage
              subtitle={Localization.FORGOT_PASSWORD_PAGE.SUBTITLE}
              email={this.props.email}
              paragraphA={Localization.FORGOT_PASSWORD_SUCCESS_PAGE.PARAGRAPH}
              paragraphB={Localization.FORGOT_PASSWORD_SUCCESS_PAGE.REMEMBER_YOUR_PASSWORD}
              linkTitle={Localization.BACK_TO_LOGIN_LINK}
              linkHref={ROUTES.LOGIN}
            />
          )
          : (
            <ForgotPasswordPage
              forgotPassword={this.forgotPassword}
              loading={this.props.loading}
              errorMessage={this.props.error}
              email={this.props.email}
            />
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.forgotPasswordState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ forgotPassword, cleanState }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);

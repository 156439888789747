import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EnterPinPage from '../../../../../components/enter-pin';
import Localization from '../../../../../assets/localization';
import { ROUTES } from '../../../../../consts/routes';
import {
  cleanState,
  verifyFactor,
  resetErrorMessage,
} from '../verify-factor-state';
import { cleanState as cleanMfaSelectState } from '../../select-mfa/select-mfa-state';

const CONFIRMATION_CODE_LENGTH = 6;

class VerifyAuthenticatorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      goBack: false,
    };
  }

  componentWillUnmount() {
    this.props.cleanState();
  }

  onChange = (value) => {
    if (value.length === CONFIRMATION_CODE_LENGTH) {
      const {
        stateToken, factor, mfaStatus, pkceChallenge,
      } = this.props;
      this.setState({ isValid: true });
      this.props.verifyFactor(
        value,
        stateToken,
        factor,
        mfaStatus,
        pkceChallenge,
      );
    }
  };

  onBackToSelectMfaClick = () => {
    this.props.cleanMfaSelectState(['mfaFactors', 'mfaStatus', 'skippable']);
    this.setState({ goBack: true });
  };

  render() {
    const { stateToken, passwordExpired, history } = this.props;
    const { isValid, goBack } = this.state;
    const goBackLink = {
      linkTitle: Localization.SELECT_MFA_LINK_TITLE,
      onClick: this.onBackToSelectMfaClick,
      type: 'button',
    };
    if (!stateToken) {
      return <Redirect to={`${ROUTES.LOGIN}${history.location.search}`} />;
    }
    if (passwordExpired) {
      return (
        <Redirect to={`${ROUTES.SET_PASSWORD}${history.location.search}`} />
      );
    }
    if (goBack) {
      return <Redirect to={`${ROUTES.SELECT_MFA}${history.location.search}`} />;
    }
    return (
      <div className="login-wrapper">
        <EnterPinPage
          onChange={this.onChange}
          loading={this.props.loading}
          errorMessage={this.props.error}
          isValid={isValid || false}
          title={Localization.VERIFY_AUTHENTICATOR_PAGE.TITLE}
          paragraph={Localization.VERIFY_AUTHENTICATOR_PAGE.PARAGRAPH}
          formFooterText={Localization.VERIFY_FACTOR_PAGE.FOOTER(
            'Authenticator',
          )}
          goBackLink={goBackLink}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.AppState,
    ...state.verifyFactorState,
    pkceChallenge: state.loginState.pkceChallenge,
    factor: state.selectMfaState.factor,
    mfaStatus: state.selectMfaState.mfaStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cleanState,
      verifyFactor,
      resetErrorMessage,
      cleanMfaSelectState,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyAuthenticatorContainer);

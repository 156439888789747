import { getUrlParameter } from '../../utils/utils';
import {
  ACTION_NAMES,
  setNewPassword,
} from '../../actions/actions';
import Localization from '../../assets/localization';

const initialState = {
  loading: false,
  tokenExpired: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.RESET_PASSWORD_INITIAL_STATE });
  };
}

export function resetPassword(password) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.RESET_PASSWORD_REQUEST_START });
    const token = getUrlParameter('token');
    setNewPassword(password, token).then(() => {
      dispatch({ type: ACTION_NAMES.RESET_PASSWORD_REQUEST_SUCCESS });
    }).catch((response = {}) => {
      let error = '';
      if (response.response && response.response.status === 401) {
        //  Until we have enum to count on this will tell us it's score issue and not token
        // (NSOF-3067)
        const errorDetails = response.response.data.detail && response.response.data.detail.indexOf('Password score') === 0;
        error = errorDetails
          ? Localization.RESET_PASSWORD_PAGE.PASSWORD_NOT_STRONG_ENOUGH
          : Localization.RESET_PASSWORD_PAGE.TOKEN_EXPIRED;
        dispatch({ type: ACTION_NAMES.SET_PASSWORD_TOKEN_EXPIRED });
      } else {
        error = (response.response && response.response.data && response.response.data.detail)
          || Localization.INTERNAL_ERROR_MESSAGE;
      }
      dispatch({ type: ACTION_NAMES.RESET_PASSWORD_REQUEST_FAIL, error });
    });
  };
}

export default function resetPasswordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.RESET_PASSWORD_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.RESET_PASSWORD_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.RESET_PASSWORD_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true };

    case ACTION_NAMES.RESET_PASSWORD_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    case ACTION_NAMES.SET_PASSWORD_TOKEN_EXPIRED: {
      return { ...state, tokenExpired: true };
    }

    default:
      return { ...state };
  }
}

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MetaButton from '../../../../components/meta-button';
import { BUTTON_DESIGNS } from '../../../../consts/consts';
import BottomLink from '../../../../components/bottom-link';
import { TextInputField } from '../../../../components/form-fields';
import { ROUTES } from '../../../../consts/routes';
import Localization from '../../../../assets/localization';
import './forgot-password.sass';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = Localization.FORM_FIELDS.EMAIL_FIELD.REQUIRED;
  } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)) {
    errors.email = Localization.FORM_FIELDS.EMAIL_FIELD.INVALID;
  }
  return errors;
};

const ForgotPasswordForm = (props) => {
  const {
    handleSubmit,
    loading = false,
  } = props;
  return (
    <form className="forgot-password-form" onSubmit={handleSubmit}>
      <Field
        name="email"
        type="email"
        component={TextInputField}
        placeholder={Localization.FORM_FIELDS.EMAIL_FIELD.PLACEHOLDER}
        autocomplete="username"
        autoFocus
        icon="icon-ic-send"
      />
      <MetaButton
        text={Localization.SEND_BUTTON_TEXT}
        buttonType="submit"
        disabled={loading}
        variant={BUTTON_DESIGNS.SECONDARY}
      />
      <BottomLink
        textBeforeLink={Localization.GO_BACK_LINK}
        linkTitle={Localization.BACK_TO_LOGIN_LINK}
        type="link"
        linkHref={ROUTES.LOGIN}
      />
    </form>
  );
};

export default reduxForm({
  form: 'forgotPasswordForm',
  touchOnBlur: false,
  validate,
})(ForgotPasswordForm);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import MetaButton from '../../../../../../..//components/meta-button';
import { BUTTON_DESIGNS } from '../../../../../../../consts/consts';
import { ROUTES } from '../../../../../../../consts/routes';
import PageTitles from '../../../../../../../components/meta-page-titles/index';
import BottomLink from '../../../../../../../components/bottom-link';
import Localization from '../../../../../../../assets/localization';

class SetAuthenticatorManualPage extends Component {
  render() {
    const { secret, cancelFactorSelection } = this.props;
    return (
      <div className="set-authenticator-page">
        <div className="form-background">
          <div className="form-content">
            <PageTitles
              subtitle={Localization.SET_AUTHENTICATOR_PAGE.TITLE}
            />
            <ul className="instructions">
              <li>
                {Localization.SET_AUTHENTICATOR_MANUAL_PAGE.INSTRUCTION_1}
                <span>{Localization.SET_AUTHENTICATOR_MANUAL_PAGE.INSTRUCTION_1A}</span>
              </li>
              <li>
                {Localization.SET_AUTHENTICATOR_MANUAL_PAGE.INSTRUCTION_2}
                <span>{Localization.SET_AUTHENTICATOR_MANUAL_PAGE.INSTRUCTION_2A}</span>
              </li>
              <li>{Localization.SET_AUTHENTICATOR_MANUAL_PAGE.INSTRUCTION_3}</li>
            </ul>
            <form className="set-authenticator-form manual">
              <div className="secret">{secret}</div>
              <CopyToClipboard text={this.props.secret}>
                <button type="button" className="clipboard" />
              </CopyToClipboard>
              <MetaButton
                text={Localization.SET_AUTHENTICATOR_PAGE.CONFIRM_BUTTON}
                link={ROUTES.VERIFY_AUTHENTICATOR}
                variant={BUTTON_DESIGNS.SECONDARY}
              />
            </form>
            <BottomLink
              textBeforeLink={
                Localization.SET_AUTHENTICATOR_PAGE.BACK_TO_MFA_LINK_BEFORE_TEXT
              }
              linkTitle={Localization.SET_AUTHENTICATOR_PAGE.BACK_TO_MFA_LINK_TEXT}
              linkHref={ROUTES.SELECT_MFA}
              handleLinkClick={cancelFactorSelection}
            />
          </div>
        </div>
      </div>
    );
  }
}

SetAuthenticatorManualPage.defaultProps = {
  secret: '',
};

SetAuthenticatorManualPage.propTypes = {
  secret: PropTypes.string,
};

export default SetAuthenticatorManualPage;

import { ACTION_NAMES, getUrlParameter } from '../../actions/actions';
import { addUrlParam, isBase64 } from '../../utils/utils';

const initialState = {
  error: '',
};

const getErrorMessage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const encodedText = urlParams.get('reason');
  return isBase64(encodedText) ? Buffer.from(encodedText, 'base64').toString() : getUrlParameter('reason');
};

export function getUrlParams() {
  return (dispatch) => {
    const errorMessage = getErrorMessage();
    const title = getUrlParameter('title');
    const requestId = getUrlParameter('request_id');
    const nextUrl = getUrlParameter('next');
    const shouldRedirect = getUrlParameter('shouldRedirect');
    const relatedURL = getUrlParameter('relatedURL');
    const avoidAdminMessage = getUrlParameter('avoidAdminMessage');
    if (nextUrl && shouldRedirect) {
      window.location.href = nextUrl;
    } else {
      if (nextUrl) {
        addUrlParam('shouldRedirect', true);
      }
      dispatch({
        type: ACTION_NAMES.ERROR_PAGE_FETCHED_PARAMS,
        error: errorMessage,
        title,
        requestId,
        relatedURL,
        avoidAdminMessage,
      });
    }
  };
}

export default function errorPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.ERROR_PAGE_FETCHED_PARAMS:
      return { ...state,
        error: action.error,
        title: action.title,
        requestId: action.requestId,
        relatedURL: action.relatedURL,
        avoidAdminMessage: action.avoidAdminMessage };

    default:
      return { ...state };
  }
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MetaLoader from '../../components/meta-loader';
import { getUrlParameter } from '../../utils/utils';
import PageTitles from '../../components/meta-page-titles';
import { FormError } from '../../components/form-fields';
import EnterPinForm from './enter-pin-form';
import './enter-pin.sass';

const app = getUrlParameter('app') || '';
const iosPlatformKey = 'ios';

class EnterPinPage extends Component {
  isErrorShown = () => Boolean(this.props.errorMessage) && app !== iosPlatformKey;

  render() {
    const { loading, errorMessage } = this.props;
    return (
      <div className="enter-pin-page">
        <div className="form-background">
          <div className="form-content">
            <PageTitles
              subtitle={this.props.title}
              paragraph={this.props.paragraph}
            />
            <EnterPinForm
              onChange={this.props.onChange}
              loading={this.props.loading}
              errorMessage={this.props.errorMessage}
              isValid={this.props.isValid}
              formFooterText={this.props.formFooterText}
              resendCodeLink={this.props.resendCodeLink}
              goBackLink={this.props.goBackLink}
            />
          </div>
          { loading
            ? <div className="loader-wrapper"><MetaLoader isShown={loading} /></div>
            : <FormError message={errorMessage} isShown={this.isErrorShown()} />}
        </div>
      </div>
    );
  }
}

EnterPinPage.defaultProps = {
  errorMessage: '',
  loading: false,
  onChange: () => {},
  isValid: false,
  formFooterText: '',
  resendCodeLink: {},
  goBackLink: {},
};

EnterPinPage.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  formFooterText: PropTypes.string,
  resendCodeLink: PropTypes.shape({
    class: PropTypes.string,
    linkTitle: PropTypes.string,
    onClick: PropTypes.func,
  }),
  goBackLink: PropTypes.shape({
    class: PropTypes.string,
    linkTitle: PropTypes.string,
    href: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default EnterPinPage;

import { ACTION_NAMES } from '../../actions/actions';

const initialState = {
  tokenFetched: false,
  oauthInfoLoaded: false,
};

const redirectContainerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_SUCCESS:
      return { ...state,
        tokenFetched: true,
        refreshToken: action.response.data.refresh_token,
        accessToken: action.response.data.access_token };

    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_FAIL:
    case ACTION_NAMES.GET_OAUTH_INFO_FAIL:
      return { ...state,
        success: false,
        error: action.error };

    case ACTION_NAMES.GET_OAUTH_INFO_SUCCESS:
      return { ...state,
        oauthInfoLoaded: true,
        ztnaLicense: action.response.data.ztna_license,
        wsLicense: action.response.data.proxy_license,
        privileges: action.response.data.privileges };

    default:
      return { ...state };
  }
};

export default redirectContainerReducer;

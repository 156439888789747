import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { getUrlParams } from './error-page-state';
import ErrorPage from './components/error-page';

class ErrorPageContainer extends Component {
  componentDidMount() {
    this.props.getUrlParams();
  }

  render() {
    return (
      <div className="login-wrapper">
        <ErrorPage
          error={this.props.error}
          title={this.props.title}
          relatedURL={this.props.relatedURL}
          requestResponse={get(this.props, 'location.state.status')}
          requestId={this.props.requestId}
          avoidAdminMessage={this.props.avoidAdminMessage}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.errorPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getUrlParams }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPageContainer);

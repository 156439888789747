import { saveAs } from 'file-saver';
import moment from 'moment';
import snakeCase from 'lodash/snakeCase';
import { reportDateLongTime } from '../../consts/date-formats';
import { REPORTS_NAMES } from '../../consts/report-consts';
import Localization from '../../assets/localization';
import {
  getReport as getReportAction,
  ACTION_NAMES,
} from '../../actions/actions';

const initialState = {
  logInLoading: true,
  reportLoading: true,
  reportLoaded: false,
};

export const onReportsContainerLoaded = () => (dispatch => {
  dispatch({ type: ACTION_NAMES.REPORTS_CONTAINER_PAGE_LOADED });
});

const generateReportFileName = reportData => {
  const { reportId, reportFormat, timeFrom, timeTo } = reportData;
  const splits = reportId?.split('-') || [];
  const report = REPORTS_NAMES[splits?.[1]] || {};
  const reportName = snakeCase(report?.titleForDownload || report?.title || '');
  // convert timestamp to seconds
  const timestamp = parseInt(splits?.[2], 10) / 1000 || '';
  const license = report?.isWS
    ? Localization.REPORT_LICENSE_NAMES.WS
    : Localization.REPORT_LICENSE_NAMES.ZTNA;
  if (timeFrom && timeTo) {
    const timeFromFormatted = moment(timeFrom).format(reportDateLongTime);
    const timeToFormatted = moment(timeTo).format(reportDateLongTime);
    return `proofpoint_${license}_${reportName}_${timeFromFormatted}_${timeToFormatted}.${reportFormat}`;
  }
  const reportDate = timestamp ? `_${(moment.unix(timestamp).format(reportDateLongTime))}` : '';
  return `proofpoint_${license}_${reportName}${reportDate}.${reportFormat}`;
};

export const getReport = reportData => {
  const { reportId, reportFormat, accessToken } = reportData;
  return (dispatch => {
    dispatch({ type: ACTION_NAMES.DOWNLOAD_REPORT_START });
    getReportAction(reportId, reportFormat, accessToken)
      .then((response = {}) => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'content-type' });
          const filename = generateReportFileName(reportData);
          saveAs(blob, filename);
          dispatch({ type: ACTION_NAMES.DOWNLOAD_REPORT_SUCCESS });
        }
      })
      .catch((response = {}) => {
        const errDetail = response?.data?.detail;
        const error = {
          title: Localization.REPORT_ERROR_PAGE.TITLE,
          error: `${Localization.REPORT_ERROR_PAGE.REPORT_ID}: ${reportId}\n${
            errDetail ? `${Localization.REPORT_ERROR_PAGE.DESCRIPTION}: ${errDetail}` : response}`,
        };
        dispatch({
          type: ACTION_NAMES.DOWNLOAD_REPORT_FAIL,
          error,
        });
      });
  });
};

export default function reportsContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.REPORTS_CONTAINER_PAGE_LOADED:
      return { ...state, logInLoading: false };
    case ACTION_NAMES.REPORTS_CONTAINER_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_START:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_START:
    case ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_START:
      return { ...state, logInLoading: true };

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_SUCCESS:
      return { ...state,
        logInLoading: false,
        refreshToken: action.response.data.refresh_token,
        accessToken: action.response.data.access_token };

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_FAIL:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_FAIL:
      return { ...state,
        logInLoading: false,
        success: false,
        error: action.error };

    case ACTION_NAMES.DOWNLOAD_REPORT_START:
      return { ...state, reportLoading: true };

    case ACTION_NAMES.DOWNLOAD_REPORT_FAIL:
      return { ...state,
        reportLoading: false,
        reportLoaded: true,
        success: false,
        getReportError: action.error };

    case ACTION_NAMES.DOWNLOAD_REPORT_SUCCESS:
      return { ...state,
        reportLoading: false,
        reportLoaded: true };

    default:
      return { ...state };
  }
}

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUrlParameter } from '../../utils/utils';
import PageTitles from '../../components/meta-page-titles';
import SetEmailForm from '../../components/set-email';
import SuccessPage from '../../components/success-page';
import Localization from '../../assets/localization';
import { ROUTES } from '../../consts/routes';
import { cleanState, sendOrgsEmail } from './forgot-org-state';

class forgotOrgContainer extends Component {
  componentWillUnmount() {
    this.props.cleanState();
  }

  sendOrgsEmail = (formData) => {
    this.props.sendOrgsEmail(formData.email);
  };

  render() {
    return (
      <div className="login-wrapper">
        {this.props.success ? (
          <SuccessPage
            subtitle={Localization.FORGOT_ORG.TITLE}
            email={this.props.email}
            paragraphA={Localization.FORGOT_ORG_SUCCESS.PARAGRAPH_A}
            paragraphB={Localization.FORGOT_ORG_SUCCESS.PARAGRAPH_B}
            linkTitle={Localization.FORGOT_ORG_SUCCESS.BOTTOM_LINK_TEXT}
            linkHref={getUrlParameter('prevRoute') || ROUTES.LOGIN}
          />
        ) : (
          <div>
            <div className="form-background">
              <div className="form-content">
                <PageTitles subtitle={Localization.FORGOT_ORG.TITLE} />
                <SetEmailForm
                  onSubmit={this.sendOrgsEmail}
                  loading={this.props.loading}
                  errorMessage={this.props.error}
                  formTitle={Localization.FORGOT_ORG.SUB_TITLE}
                  bottomLinkTextBefore={Localization.FORGOT_ORG.BOTTOM_LINK_BEFORE_TEXT}
                  bottomLinkTitle={Localization.FORGOT_ORG.BOTTOM_LINK_TEXT}
                  bottomLinkHref={getUrlParameter('prevRoute') || ROUTES.LOGIN}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.forgotOrgState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cleanState, sendOrgsEmail }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(forgotOrgContainer);

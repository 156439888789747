import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MetaButton from '../../../../../components/meta-button';
import { BUTTON_DESIGNS } from '../../../../../consts/consts';
import ReactPhoneInput from 'react-phone-input-2';
import { ROUTES } from '../../../../../consts/routes';
import PageTitles from '../../../../../components/meta-page-titles';
import Localization from '../../../../../assets/localization';
import BottomLink from '../../../../../components/bottom-link/bottom-link';
import validate from './validate-set-phone';
import './set-phone.sass';

const SetPhoneForm = (props) => {
  const {
    handleSubmit,
    loading = false,
    invalid,
    phone,
    handleBackBtnClick,
  } = props;

  const phoneInputComponent = ({ input }) => (
    <ReactPhoneInput
      {...input}
      autoFormat={false}
      inputExtraProps={{
        autoFocus: true,
      }}
      defaultCountry="us"
      placeholder={input.placeholder}
    />
  );

  return (
    <form className="set-phone-form" onSubmit={handleSubmit}>
      <div className="phone-input-container">
        <PageTitles
          paragraph={Localization.SET_PHONE_PAGE.PHONE_FIELD_TITLE}
        />
        <Field
          name="phone"
          placeholder={phone || Localization.SET_PHONE_PAGE.PHONE_FIELD_PLACEHOLDER}
          component={phoneInputComponent}
        />
      </div>
      <MetaButton
        text={Localization.SET_EMAIL_PAGE.CONFIRM_BUTTON}
        buttonType="submit"
        disabled={loading || invalid}
        variant={BUTTON_DESIGNS.SECONDARY}
      />
      <BottomLink
        textBeforeLink={Localization.SET_PHONE_PAGE.GO_BACK_LINK_BEFORE_TEXT}
        linkTitle={Localization.SET_PHONE_PAGE.GO_BACK_LINK_TITLE}
        handleLinkClick={handleBackBtnClick}
        linkHref={ROUTES.SELECT_MFA}
      />
    </form>
  );
};

export default reduxForm({
  form: 'setPhoneForm',
  touchOnBlur: false,
  validate,
})(SetPhoneForm);

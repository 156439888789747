import React, { Component } from 'react';
import { getUrlParameter, getEnvSuffix, redirectToRoute } from '../../utils/utils';
import { ROUTES } from '../../consts/routes';
import LoginNoOrgPage from '../login/components/login-no-org-page';

const ORG_SHORTNAME = 'ORG_SHORTNAME';

class NoOrgRedirectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      envSuffix: getEnvSuffix(),
    };
  }

  componentDidMount() {
    const currentPrevRoute = getUrlParameter('prevRoute');
    const nextRoute = getUrlParameter('next');
    if (!currentPrevRoute && nextRoute) {
      window.location.href = `${window.location.href}&prevRoute=${ROUTES.ORG_REDIRECT}`;
    }
  }

  goToNextUrl = (formData) => {
    const { org } = formData;
    const nextRoute = getUrlParameter('next');
    const nextWithShortname = nextRoute.replace(ORG_SHORTNAME, org);
    redirectToRoute(nextWithShortname);
  };

  render() {
    const { envSuffix } = this.state;
    return <LoginNoOrgPage onSubmit={this.goToNextUrl} envSuffix={envSuffix} />;
  }
}

export default NoOrgRedirectContainer;

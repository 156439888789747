import { getUrlParameter } from '../../utils/utils';
import {
  ACTION_NAMES,
  sendPasswordInvite,
} from '../../actions/actions';
import Localization from '../../assets/localization';
import { log } from '../../utils/utils';

const initialState = {
  loading: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.FORGOT_PASSWORD_INITIAL_STATE });
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    log(`ACTION: forgotPassword ${email}`);
    dispatch({ type: ACTION_NAMES.FORGOT_PASSWORD_REQUEST_START });
    sendPasswordInvite(email).then(() => {
      dispatch({ type: ACTION_NAMES.FORGOT_PASSWORD_REQUEST_SUCCESS, email });
    }).catch((response = {}) => {
      const errorMessage = (response.response && response.response.data)
        || Localization.INTERNAL_ERROR_MESSAGE;
      log(`ERR: forgotPassword message: ${errorMessage}`);
      dispatch({ type: ACTION_NAMES.FORGOT_PASSWORD_REQUEST_FAIL, error: errorMessage });
    });
  };
}

export default function forgotPasswordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.FORGOT_PASSWORD_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.FORGOT_PASSWORD_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true,
        email: action.email };

    case ACTION_NAMES.FORGOT_PASSWORD_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    default: {
      const email = getUrlParameter('email');
      if (email) {
        return { ...state, email };
      }
      return { ...state };
    }
  }
}

module.exports = {
  GO_BACK_LINK: 'Go back to ',
  SELECT_MFA_LINK_TITLE: 'select a different authentication method',
  ERROR_PAGE: {
    TITLE: 'There seems to be a problem',
    SUBTITLE: {
      FIRST: 'Please contact your administrator',
    },
    RELATED_URL: 'URL:',
    COPY_INDICATION: 'Copied!',
    REQUEST_ID: 'Request ID:',
    SERVER_RESPONSE: 'Server Response: ',
  },
  BLOCK_PAGE: {
    MAIN_TITLE: 'Access Denied!',
    TITLE: 'You are blocked from browsing to',
    REASONS: {
      CUSTOM: 'It is categorized as a forbidden website by your company',
      ATP: 'It is categorized as a ',
      CONTENT: 'It is categorized as ',
      THREAT: 'It is categorized as ',
      COUNTRY: 'It is in a forbidden country according to your company policy',
    },
    MALICIOUS_WEBSITE: 'malicious website',
    REPLY: 'To request a website reclassification, ',
    CLICK_HERE: 'click here',
    FIRST: 'For more information about company policy\ncontact your IT administrator.',
    SUBJECT_TEXT: 'Request to re-evaluate website classification',
    DEFAULT_OPENING:
      'I believe a URL I tried browsing was incorrectly blocked. All pertinent information can be found below.',
    TENANT_ID: 'Tenant ID',
    URL: 'URL',
    POLICY_ID: 'Policy ID',
    USERNAME: 'Username',
    THANK_END: 'Thanks',
    CUSTOM_URL_TITLE: 'Warning!',
    CUSTOM_URL_MESSAGE:
      'You are browsing to a site that violates company policy.\nWe encourage you to use acceptable services that we provide.\n\nClick below to access ',
    PROCEED: 'Proceed',
    PROXY_ERROR_TITLE: 'Site is Unreachable',
    DEFAULT_BLOCK_TITLE: 'Access Denied!',
    FILE_SCAN_TITLE: 'Attention!',
    FILE_SCAN_PARAGRAPH: 'Downloaded file is being checked for malicious content.',
    BLOCKED_FROM_BROWSING: 'You are blocked from browsing to',
    CATEGORIZED_AS: 'It is categorized as',
    CANNOT_BE_REACHED: 'This website cannot be reached',
    DISABLED_ORG_TEXT: 'Proofpoint Web Security license has expired. Contact your system administrator.',
    FULL_CATEGORIZED_MALICIOUS: 'It is categorized as a malicious website.',
    FORBIDDEN_BY: 'It is categorized as a forbidden website by your company.',
    APP_FORBIDDEN_LOGIN: 'You are not allowed to login to',
    COUNTRY_FORBIDDEN_TEXT: 'It is in a forbidden country according to your company policy',
    CLOUD_APP_RISK_GROUPS: 'cloud app risk group',
    IS_PART_OF: 'It is a part of',
  },
  TIME_UNITS: {
    HOUR: 'hour',
    HOURS: 'hours',
    MINUTE: 'minute',
    MINUTES: 'minutes',
    SECOND: 'second',
    SECONDS: 'seconds',
  },
  FORGOT_ORG: {
    TITLE: 'Forgot organization',
    SUB_TITLE:
      'We will send you an email to confirm your address and find existing organizations you have joined or can join',
    BOTTOM_LINK_BEFORE_TEXT: 'Go back to ',
    BOTTOM_LINK_TEXT: 'Login',
  },
  FORGOT_ORG_SUCCESS: {
    PARAGRAPH_A: 'An email with your organizations has been sent to:\n',
    PARAGRAPH_B: 'Please check your email.',
    BOTTOM_LINK_TEXT: 'Back to Organization setup',
  },
  WELCOME_KNOWN_ORG: {
    TITLE: orgName => `You are part of ${orgName}`,
    CONFIRM_BUTTON: 'Continue',
    BOTTOM_LINK_LABEL: 'Not your organization?\nClick here to ',
    BOTTOM_LINK_LINK: 'switch organization',
  },
  SET_EMAIL_PAGE: {
    TITLE: 'Proofpoint Information and Cloud Security',
    EMAIL_FIELD_TITLE: 'Enter your email',
    HAVE_AN_ACCOUNT_LABEL: 'Have an account? ',
    HAVE_AN_ACCOUNT_LINK: 'Log in',
    CONFIRM_BUTTON: 'Confirm',
  },
  VERIFY_FACTOR_PAGE: {
    PREV_LINK_TEXT: page => `${page} setup`,
    FOOTER: page => `keep this dialog open, while checking your ${page}`,
  },
  VERIFY_EMAIL_PAGE: {
    TITLE: 'Check Your Email',
    PARAGRAPH: email => `Enter a confirmation code sent to ${email}`,
  },
  VERIFY_CONFIRMATION_CODE_PAGE: {
    PREV_LINK_TEXT: 'login',
    PREV_LINK_TEXT_EXPIRED: 'Resend code',
    TITLE: method => `Check your ${method}`,
    PARAGRAPH: factorValue => `Enter a confirmation code sent to ${factorValue}`,
    METHOD_EMAIL: 'email',
    METHOD_SMS: 'phone',
    METHOD_VOICECALL: 'phone',
    FOOTER: method => `keep this dialog open, while checking your ${method}`,
  },
  AUTHENTICATOR_ISSUER: 'Proofpoint',
  VERIFY_AUTHENTICATOR_PAGE: {
    TITLE: 'Check Your Authenticator app',
    PARAGRAPH: 'Enter the six digit confirmation code here:',
  },
  SET_AUTHENTICATOR_PAGE: {
    TITLE: 'Set up Authenticator',
    INSTRUCTION_1: 'Get the Authenticator from the App store or Play Store',
    INSTRUCTION_2: 'Choose ',
    INSTRUCTION_2A: 'Scan a barcode',
    CONFIRM_BUTTON: 'Verify your authenticator code',
    GO_TO_MANUAL_BEFORE_TEXT: 'Can\'t scan the barcode? ',
    GO_TO_MANUAL_TEXT: 'setup manually',
    BACK_TO_MFA_LINK_BEFORE_TEXT: 'Choose a different ',
    BACK_TO_MFA_LINK_TEXT: 'authentication method',
  },
  SET_AUTHENTICATOR_MANUAL_PAGE: {
    INSTRUCTION_1: 'In Authenticator app create a ',
    INSTRUCTION_1A: 'new account',
    INSTRUCTION_2: 'Chose the option to enter the code ',
    INSTRUCTION_2A: 'manually',
    INSTRUCTION_3: 'Enter your email address and this key',
  },
  FORGOT_PASSWORD_PAGE: {
    SUBTITLE: 'Forgot Password',
    PARAGRAPH: 'Enter your email and an email with reset password instructions will be sent to you',
  },
  OPEN_APP_PAGE: {
    SUBTITLE: 'Login Successful',
    PARAGRAPH: 'Please allow the application to open and close this window',
  },
  ZERO_LOGIN_DONE_PAGE: {
    SUBTITLE: 'Success',
    PARAGRAPH: 'You can view your status in the Proofpoint Agent',
  },
  HEADLESS_LOGIN_DONE_PAGE: {
    SUBTITLE: 'Authentication completed',
    PARAGRAPH:
      'Copy the resulting code and paste it into the CLI prompt to finish the authentication',
    NO_CODE_PROVIDED_SUBTITLE: 'Authentication failure',
    NO_CODE_PROVIDED_PARAGRAPH:
      'Repeat browser-based authentication and copy-paste the resulting code into the CLI prompt',
    BUTTON: 'Copy',
  },
  FORGOT_PASSWORD_SUCCESS_PAGE: {
    PARAGRAPH: 'An email with reset password instructions has been sent to:',
    REMEMBER_YOUR_PASSWORD: 'Please check your email.',
  },
  RESET_PASSWORD_PAGE: {
    SUBTITLE: 'Reset Password',
    EXPIRED_PASSWORD_SUBTITLE: 'Your password has expired',
    EXPIRED_PASSWORD_PARAGRAPH: 'Please enter a new password',
    PASSWORD_NOT_STRONG_ENOUGH:
      'Avoid personal details in your password and try a more complex password',
    PASSWORD_REUSED: 'New password must be different from existing one',
    TOKEN_EXPIRED: 'Invalid or expired confirmation code. Send request for a new code.',
    BOTTOM_LINK_BEFORE_TEXT: 'Go back to ',
    BOTTOM_LINK_TEXT: 'Forgot Password',
  },
  SET_PASSWORD_PAGE: {
    SUBTITLE: 'Proofpoint Information and Cloud Security',
    PARAGRAPH: 'Create your login password',
    BUTTON_TEXT: 'Get Started',
    BOTTOM_LINK_BEFORE_TEXT: 'Go back to ',
    BOTTOM_LINK_TEXT: 'Signup',
  },
  SELECT_MFA_PAGE: {
    SUBTITLE: 'Select multi-factor authentication',
    PARAGRAPH:
      'Your organization has enabled multi-factor authentication for your account.\nSelect one of the following methods: ',
    SUBTITLE_ENROLL_ANOTHER: 'Add another authentication method',
    PARAGRAPH_ENROLL_ANOTHER:
      'Use additional verification methods to avoid getting locked out of your account.\n'
      + 'Select one of the following methods:',
    SOFTWARE_TOTP_FACTOR_TITLE: 'Authenticator App',
    SMS_FACTOR_TITLE: 'SMS',
    VOICECALL_FACTOR_TITLE: 'Voice Call',
    EMAIL_FACTOR_TITLE: 'Email',
    NEED_HELP_TEXT:
      'Having problems with your Multi-Factor Authentication?\nIf your Multi Factor is lost or not working, please contact your System Administrator',
    SKIP_BUTTON_TEXT: 'Skip',
  },
  SET_PHONE_PAGE: {
    PHONE_FIELD_PLACEHOLDER: 'Phone number',
    PHONE_FIELD_TITLE: 'Enter your phone',
    GO_BACK_LINK_BEFORE_TEXT: 'Choose a different ',
    GO_BACK_LINK_TITLE: 'authentication method',
  },
  CHOOSE_LOGIN_METHOD: {
    APP_NAMES: {
      APPS: 'Apps',
      META_CONNECT: 'Meta Connect',
      PORTAL: '',
      MFA: 'Secure Remote Access to Your Corporate Network',
      SSP: 'Self-Service Portal',
    },
  },
  RESET_PASSWORD_SUCCESS_PAGE: {
    SUBTITLE: 'Password has been reset successfully',
    PARAGRAPH: 'To login with your new password please go back to login',
  },
  LOGIN_PAGE: {
    SUBTITLE: 'Login to {0}',
    UNABLE_TO_SEND_SMS: 'Unable to send SMS',
    UNABLE_TO_SEND_CODE_BY_PHONE: 'Unable to send code by phone',
    INVALID_CREDENTIALS: 'Invalid email address or password.',
    SSO_DECODE_FAILURE: 'Failed to decode URL from SSO',
    IDP_NOT_FOUND: 'Failed to locate requested idp',
    FAILED_TO_VERIFY_TRY_AGAIN: 'Failed to verify. Please try again',
    NEW_CODE_SENT: 'Code was sent successfully',
    SSO_SEPARATOR_TEXT: 'OR',
    FORGOT_PASSWORD_LINK: 'Forgot your password?',
    GO_TO_SIGNUP_LABEL: ', if not registered.',
    GO_TO_SIGNUP_LINK: 'Sign up',
    REFRESH: 'Refresh',
  },
  LOGIN_NO_ORG_PAGE: {
    SUBTITLE: 'Log into your account',
    PARAGRAPH: 'Enter your organization URL',
    BOTTOM_LINK_TEXT: 'Click here',
    BOTTOM_LINK_BEFORE_TEXT: 'Don\'t know your organization short name?\n',
    SHORTNAME_FORM_FIELD: 'Org name',
    SUBMIT_BUTTON_TEXT: 'Continue',
  },
  ZONE_LOGIN: {
    SUBTITLE: 'Log into org and zone',
    PARAGRAPH: 'Fill zone, ui-zone and org shortname',
    ORG_SHORTNAME_FORM_FIELD: 'Organization shortname',
    ZONE_FIELD: 'Zone',
    ZONE_FIELD_PLACEHOLDER: 'p, stg1 etc blank for Production',
    UI_ZONE_FIELD: 'UI Zone',
    SUBMIT_BUTTON_TEXT: 'Submit',
  },
  LOGIN_CHOOSE_METHOD: {
    SUBTITLE: '{0}',
    SUBTITLE_2: '{0}',
    SUBTITLE_ONBOARD: 'Onboard',
    IDP_BUTTON: 'Log in with {0}',
    SAML_CHECK_ERROR:
      'Unexpected error, please check your internet connection and refresh the page',
    LOGIN_BUTTON_DESCRIPTION: 'Sign in with your credentials',
    GUEST_LOGIN_BUTTON_DESCRIPTION: 'Sign in with your {0} credentials',
  },
  VERIFY_PIN_PAGE: {
    SUBTITLE: '2-Step-Verification',
    AUTHENTICATOR_GET_VERIFICATION_CODE: 'Get a verification code from the Authenticator app',
    SMS_MESSAGE_WITH_CODE_SENT: 'A text message with your code has been sent to ',
    VOICE_CALL_WITH_CODE_SENT: 'A phone call with the code is being made to ',
    EMAIL_WITH_CODE_SENT: 'An email with your code has been sent to ',
    VERIFICATION_CODE_FIELD_PLACEHOLDER: 'Enter Verification Code',
    RESEND_CODE_BUTTON: 'Re-Send Code',
  },
  VERIFY_PIN_MODAL: {
    HEADER: 'More verification options',
    AUTHENTICATOR_METHOD_LINK: 'Get verification method from authenticator app',
    SMS_METHOD_LINK: 'Get verification by SMS to user phone number',
    VOICE_METHOD_LINK: 'Get verification by a call to user phone number',
    EMAIL_METHOD_LINK: 'Get verification by email',
  },
  FORM_FIELDS: {
    REQUIRED_FIELD_ERROR: 'This field is required',
    EMAIL_FIELD: {
      PLACEHOLDER_SHORT: 'Email',
      PLACEHOLDER: 'Email address',
      REQUIRED: 'Email is Required',
      INVALID: 'Invalid Email',
    },
    PASSWORD_FIELD: {
      PASSWORD_PLACEHOLDER: 'Password',
      NEW_PASSWORD_PLACEHOLDER: 'New password',
      PASSWORD_STRENGTH_WORDS: ['weak', 'weak', 'ok', 'good', 'strong'],
      TOO_SHORT: 'too short',
    },
  },
  FORM_BUTTONS: {
    RESET: 'Reset',
  },
  EXPIRED_SESSION: 'Session has expired',
  EXPIRED_SESSION_REFRESH: 'Session has expired, Refresh your browser',
  INTERNAL_ERROR_MESSAGE: 'Internal Error',
  CONFIRMATION_CODE_EXPIRED: 'Confirmation code expired',
  CONFIRMATION_CODE_ALREADY_USED: 'Confirmation code was already used',
  CONFIRMATION_CODE_INVALID: 'Invalid confirmation code',
  RESTART_THE_PROCESS: 'You are missing details, Please go back and restart the process',
  NO_CONNECTION_MESSAGE: 'Connectivity error, Please check your internet connection',
  RATE_LIMIT_ERROR: time => `Too many login attempts, please try again in ${time}`,
  LOGIN_WITHOUT_SSO_ERROR: 'Login without SSO is not allowed by {0} system administrator',
  SEND_BUTTON_TEXT: 'Send',
  LOGIN_BUTTON_TEXT: 'Log in',
  BACK_TO_LOGIN_LINK: 'Login',
  INTERNAL_ERROR_BAD_RESPONSE: 'Internal Error bad response',
  DOWNLOAD_REPORT_PAGE: {
    DOWNLOADING_REPORT: 'Downloading Report',
    REPORT_DOWNLOADED: 'Report Downloaded',
    PARAGRAPH: 'If the download fails, click here to ',
    LINK: 'retry',
  },
  REPORT_ERROR_PAGE: {
    TITLE: 'Failed to download the report',
    REPORT_ID: 'Report ID',
    DESCRIPTION: 'Description',
  },
  REPORT_LICENSE_NAMES: {
    WS: 'web_security',
    ZTNA: 'secure_access',
  },
  REPORT_DISPLAY_NAMES: {
    ZTNA_USERS: 'Secure Access Users',
    WS_USERS: 'Web Security Users',
    ZTNA_DEVICES: 'Secure Access Devices',
    WS_DEVICES: 'Web Security Devices',
    ACTIVE_USERS: 'Active Users',
    WS_USER_ACTIVITY: 'User Activity',
    MAPPED_RESOURCES: 'Secure Access Mapped Resources',
    TOP_SITES: 'Top Sites',
    CATEGORIES: 'Category Types',
    TOP_ACTIONS: 'Actions',
    DEST_COUNTRIES: 'Destination Countries',
    TOP_CLOUD_APPS: 'Top Cloud Apps',
    WEB_SECURITY: 'Web Security',
    PROXY_UNPROTECTED_DEVICES: 'Web Security Unprotected Devices',
  },
  WARN_PAGE: {
    DEFAULT_TITLE: 'Warning!',
    DEFAULT_WARN_TEXT: 'You are trying to access a website that violates company policy.',
    PROCEED_AT_RISK: 'Proceed at your own risk.',
    PROCEED_BUTTON: 'Proceed',
    PLACEHOLDERS: {
      WARN: 'Provide a reason for proceeding (Optional)',
      WARN_ISOLATE: 'Provide a reason for proceeding (Optional)',
      JUSTIFIED_WARN: 'Provide a reason for accessing this website which is not permitted by existing company policy',
      JUSTIFIED_WARN_ISOLATE: 'Provide a reason for accessing this website which is not permitted by existing company policy',
    },
  },
  EMAIL: {
    CONTACT_ADMIN: 'For more information about company policy contact your IT administrator.',
    SUBJECT: 'Request to re-evaluate website classification',
    BASIC_BODY: 'I believe a URL I tried browsing was incorrectly blocked. All pertinent information can be found below.',
    TENANT_ID: 'Tenant ID',
    POLICY_ID: 'Policy ID',
    URL: 'URL',
    THANKS: 'Thanks',
    CLICK_HERE_LABEL: 'Click Here',
  },
  UNKNOWN_SITE: 'Unknown site',
  UNKNOWN_CATEGORY: 'Unknown category',
  UNKNOWN_ERROR_DETAILS: 'Unknown error details',
  UNKNOWN_ERROR_CODE: 'Unknown error code',
  UNKNOWN_APP: 'Unknown app',
  UNKNOWN_COUNTRY: 'Unknown country',
  BANNER_TITLE: 'TAP Browser Defense',
}

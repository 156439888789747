import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { getUrlParameter, updateOrgsInConfig, saveCookie, parseApiEnv } from './utils/utils';
import './index.css';
import { REALM_TYPES_ENUM, REALM_COOKIE } from './consts/consts';
import { startDebugCalls, updateServiceName } from './utils/utils';
import { initConfig, initRealmConfig } from './config/config';
import App from './scenes/app';
import Store from './redux/store';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { getRealm } from './actions/actions';
import './assets/styles/main.sass';

const LoginWrapperContainer = props => (
  <div className={props.appClientName ? `app ${props.appClientName}` : ''}>
    <Switch>
      <Route path="/login" component={App} />
      <Route
        path="*"
        render={
          () => (
            <Redirect to={{
              pathname: '/login/login',
              search: window.location.search,
            }}
            />
          )
        }
      />
    </Switch>
  </div>
);

const AppContainer = props => (
  <Provider store={Store}>
    <Router>
      <LoginWrapperContainer appClientName={props.appClientName} />
    </Router>
  </Provider>
);

function renderApp() {
  updateServiceName();
  startDebugCalls();
  const appClientName = getUrlParameter('app') || '';
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<AppContainer appClientName={appClientName} />);
}

function initConfigAndRender(realm) {
  initConfig({
    isRunningLocalMonkey: getUrlParameter('isRunningLocalMonkey'),
    realm,
  });
  renderApp();
}
function initConfigForRealm() {
  initRealmConfig({
    isRunningLocalMonkey: getUrlParameter('isRunningLocalMonkey'),
  });
  updateOrgsInConfig();
}

function initRealm() {
  getRealm().then((response = {}) => {
    const data = (response && response.data) || {};
    const { realm } = data;
    saveCookie(REALM_COOKIE, realm);
    initConfigAndRender(realm);
  }).catch(() => {
    let defaultRealm = REALM_TYPES_ENUM.EU;
    const env = parseApiEnv(true, true);
    if (env === 'p-') {
      defaultRealm = REALM_TYPES_ENUM.US;
    }
    saveCookie(REALM_COOKIE, defaultRealm);
    initConfigAndRender(defaultRealm);
  });
}

(function init() {
  initConfigForRealm();
  initRealm();
}());

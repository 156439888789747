import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './idp-button.sass';

function IdpButton(props) {
  return (
    <div
      role="presentation"
      className={classNames('idp-button', props.className)}
      style={{ ...props.style }}
      onClick={props.onClick}
      data-automation={props.automationID}
    >
      <div>
        <span className="idp-button-icon">
          { props.iconPath
            ? (
              <img
                src={props.iconPath}
                style={props.iconStyle}
                alt={props.title}
                title={props.title}
              />
            ) : <span className={props.idpIconClassName} />}
        </span>
        <div className={classNames('idp-button-text-wrapper', { short: props.title.length < 19 })}>
          <span className="idp-button-text">{props.title}</span>
        </div>
      </div>

    </div>
  );
}

IdpButton.defaultProps = {
  title: '',
  onClick: () => {
  },
};

IdpButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default IdpButton;

import {
  loginWithAac as loginWithAacAction,
  ACTION_NAMES,
} from '../../actions/actions';
import Localization from '../../assets/localization';

const initialState = {
  loading: true,
};

export function cleanState() {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.AAC_APP_VIEW_INITIAL_STATE });
  };
}

export function loginWithAac(appId, accessToken, query) {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.LOGIN_WITH_AAC_REQUEST_START });
    loginWithAacAction(appId, accessToken, query)
      .then((response = {}) => {
        dispatch({
          type: ACTION_NAMES.LOGIN_WITH_AAC_REQUEST_SUCCESS,
          response: response.data,
        });
      })
      .catch((response = {}) => {
        const errorMessage = (
          response.response
          && response.response.data
          && response.response.data.title
        )
          || Localization.INTERNAL_ERROR_MESSAGE;
        const error = response.response && response.response.data;
        dispatch({ type: ACTION_NAMES.LOGIN_WITH_AAC_REQUEST_FAIL, errorMessage, error });
      });
  };
}

export function onAacAppViewLoaded() {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.AAC_APP_VIEW_PAGE_LOADED });
  };
}

export default function aacAppViewReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.AAC_APP_VIEW_PAGE_LOADED:
      return { ...state, loading: false };
    case ACTION_NAMES.AAC_APP_VIEW_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_START:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_START:
    case ACTION_NAMES.LOGIN_WITH_AAC_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        refreshToken: action.response.data.refresh_token,
        accessToken: action.response.data.access_token };

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_FAIL:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    case ACTION_NAMES.LOGIN_WITH_AAC_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        idpLoginError: action.error };

    case ACTION_NAMES.LOGIN_WITH_AAC_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        idpRequestSuccessful: true,
        destinationURL: action.response.destination,
        RelayState: action.response.relay_state,
        SAMLResponse: action.response.saml_response };

    default:
      return { ...state };
  }
}

import React, { Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import ClassNames from 'classnames';
import includes from 'lodash/includes';
import BlockedImg from '../../../../assets/images/blocked.svg';
import PageTitles from '../../../../components/meta-page-titles';
import Localization from '../../../../assets/localization';
import { BLOCKED_PAGE_KEYS, BLOCKED_PAGE_URLS, gartnerOrgName } from '../../blocked-page-consts';
import { getOrgFromUrl } from '../../blocked-page-utils';
import './blocked-page.sass';

const orgNameFromUrl = getOrgFromUrl();

const BlockedPage = props => {
  const {
    orgId,
    rule,
    url,
    email,
    contactEmail,
    title,
    blockDescription,
    blockCategories,
  } = props;
  const blockedPageTitle = BLOCKED_PAGE_URLS[url]
    ? Localization.BLOCK_PAGE.CUSTOM_URL_TITLE
    : localStorage.getItem(BLOCKED_PAGE_KEYS.BLOCKED_PAGE_TITLE);
  const blockedPageMessage = localStorage.getItem(BLOCKED_PAGE_KEYS.BLOCKED_PAGE_MESSAGE);

  const getBlockedPageMessage = urlIndex => {
    if (BLOCKED_PAGE_URLS[url] && includes(orgNameFromUrl, gartnerOrgName)) {
      return (
        <Fragment>
          {Localization.BLOCK_PAGE.CUSTOM_URL_MESSAGE}
          <span className="acceptable-service">
            {BLOCKED_PAGE_URLS[url].navigationTitle}
          </span>
          .
        </Fragment>
      );
    }
    return blockedPageMessage?.split(' %url% ')[urlIndex];
  };

  const createMailWithInfo = () => {
    const body = `${Localization.BLOCK_PAGE.DEFAULT_OPENING}\n\n`
    + `\t${Localization.BLOCK_PAGE.TENANT_ID}: ${orgId}\n`
    + `\t${Localization.BLOCK_PAGE.POLICY_ID}: ${rule}\n`
    + `\t${Localization.BLOCK_PAGE.URL}: ${url}\n`
      + `\t${Localization.BLOCK_PAGE.USERNAME}: ${email}\n\n`
      + `${Localization.BLOCK_PAGE.THANK_END}.`;
    return `mailto:${contactEmail}?subject=${Localization.BLOCK_PAGE.SUBJECT_TEXT}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="form-background block-page">
      <div className="form-content">
        <ReactSVG
          beforeInjection={(svg) => {
            svg.classList.add('block-image');
          }}
          src={BlockedImg}
        />
        <PageTitles
          className="title"
          subtitle={blockedPageTitle || Localization.BLOCK_PAGE.MAIN_TITLE}
          paragraph={getBlockedPageMessage(0) || title}
        />
        {BLOCKED_PAGE_URLS[url] && includes(orgNameFromUrl, gartnerOrgName) ? (
          <div className="proceed-button">
            <a href={BLOCKED_PAGE_URLS[url].navigationUrl}>{Localization.BLOCK_PAGE.PROCEED}</a>
          </div>
        ) : (
          <Fragment>
            <PageTitles
              className={ClassNames('blocked-url', { title: blockedPageMessage })}
              paragraph={url}
              paragraphTitle={url}
            />
            <div className="meta-page-paragraph">
              {blockedPageMessage ? (
                <Fragment>
                  {getBlockedPageMessage(1)}
                </Fragment>
              ) : (
                <Fragment>
                  {blockDescription}
                  <b>{blockCategories}</b>
                  .
                </Fragment>
              )}
            </div>
          </Fragment>
        )}
        <PageTitles
          className="instructions"
          paragraph={Localization.BLOCK_PAGE.FIRST}
        />
        {contactEmail && (
          <div className="reply-box">
            <div className="contest-email">
              {Localization.BLOCK_PAGE.REPLY}
              <a href={createMailWithInfo()}>{Localization.BLOCK_PAGE.CLICK_HERE}</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default BlockedPage;

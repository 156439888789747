import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form';
//
import AppState from '../scenes/app/app-state';
//
import forgotPasswordState from '../scenes/forgot-password/forgot-password-state';
import setEmailState from '../scenes/signup/components/set-email/set-email-state';
import verifyEmailState from '../scenes/signup/components/verify-email/verify-email-state';
import setPhoneState from '../scenes/signup/components/set-phone/set-phone-state';
import selectMfaState from '../scenes/signup/components/select-mfa/select-mfa-state';
import setAuthenticatorState from '../scenes/signup/components/set-authenticator/set-authenticator-state';
import verifyFactorState from '../scenes/signup/components/verify-factor/verify-factor-state';
import errorPageState from '../scenes/error/error-page-state';
import resetPasswordState from '../scenes/reset-password/reset-password-state';
import setPasswordState from '../scenes/reset-password/set-password-state';
import loginState from '../scenes/login/login-state';
import forgotOrgState from '../scenes/forgot-org/forgot-org-state';
import appViewState from '../scenes/app-view/app-view-state';
import aacAppViewState from '../scenes/aac-app-view/aac-app-view-state';
import blockedPageState from '../scenes/blocked/blocked-page-state';
import redirectContainerState from '../scenes/redirect-container/redirect-container-state';
import reportsContainerState from '../scenes/reports-container/reports-container-state';
import enforcerPageState from '../scenes/enforcer/enforcer-state';
import appIsolationState from '../scenes/isolation/isolate-redirect-state';
import oidcAppViewState from '../scenes/oidc-apps-view/oidc-apps-state';

export default combineReducers({
  AppState,
  errorPageState,
  blockedPageState,
  forgotPasswordState,
  loginState,
  appViewState,
  enforcerPageState,
  appIsolationState,
  aacAppViewState,
  oidcAppViewState,
  resetPasswordState,
  setPasswordState,
  setEmailState,
  verifyEmailState,
  selectMfaState,
  setPhoneState,
  setAuthenticatorState,
  verifyFactorState,
  forgotOrgState,
  redirectContainerState,
  reportsContainerState,
  form: reduxFormReducer,
});

export default {
  BLOCK_PAGE: {
    MAIN_TITLE: 'Zugriff abgelehnt!',
    TITLE: 'Sie können nicht zu navigieren',
    REASONS: {
      CUSTOM: 'Es wird von Ihrem Unternehmen als verbotene Website eingestuft',
      ATP: 'Es ist als kategorisiert ',
      CONTENT: 'Es ist kategorisiert als ',
      THREAT: 'Es ist kategorisiert als ',
      COUNTRY: 'Es befindet sich in einem verbotenen Land gemäß Ihrer Unternehmensrichtlinie',
    },
    MALICIOUS_WEBSITE: 'bösartige Website',
    SECURED_BY: 'Das Surfen im Internet wird durch gesichert',
    REPLY: 'Um eine Neuklassifizierung der Website anzufordern, ',
    CLICK_HERE: 'Klicke hier',
    FIRST: 'Weitere Informationen zu Unternehmensrichtlinien\nWenden Sie sich an Ihren IT-Administrator.',
    SUBJECT_TEXT: 'Antrag auf Neubewertung der Website-Klassifizierung',
    DEFAULT_OPENING: 'Ich glaube, eine URL, die ich beim Surfen versucht habe, wurde falsch blockiert. Alle relevanten Informationen finden Sie unten.',
    TENANT_ID: 'Tenant ID',
    URL: 'URL',
    POLICY_ID: 'Policy ID',
    USERNAME: 'Nutzername',
    THANK_END: 'Vielen Dank',
  },
};

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Localization from '../../assets/localization';
import { ROUTES } from '../../consts/routes';

import SetPasswordContainer from '../reset-password/set-password-container';
import SelectMfaContainer from './components/select-mfa/select-mfa-container';
import SetPhoneContainer from './components/set-phone/set-phone-container';
import SetEmailContainer from './components/set-email/set-email-container';
import VerifyEmailContainer from './components/verify-email/verify-email-container';
import VerifyPhoneContainer from './components/verify-factor/verify-phone/verify-phone-container';
import SetAuthenticatorContainer from './components/set-authenticator/set-authenticator-container';
import VerifyAuthenticatorContainer from './components/verify-factor/verify-authenticator/verify-authenticator-container';

class SignupContainer extends Component {
  render() {
    const customProps = {
      changePassword: true,
      subtitle: Localization.SET_PASSWORD_PAGE.SUBTITLE,
      paragraph: Localization.SET_PASSWORD_PAGE.PARAGRAPH,
      btnText: Localization.SET_PASSWORD_PAGE.BUTTON_TEXT,
    };

    return (
      <div className="login-wrapper">
        <Switch>
          <Route path={ROUTES.SET_EMAIL} component={SetEmailContainer} />
          <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmailContainer} />
          <Route path={ROUTES.SELECT_MFA} component={SelectMfaContainer} />
          <Route path={ROUTES.SET_PHONE} component={SetPhoneContainer} />
          <Route path={ROUTES.VERIFY_PHONE} component={VerifyPhoneContainer} />
          <Route path={ROUTES.SET_AUTHENTICATOR} component={SetAuthenticatorContainer} />
          <Route path={ROUTES.VERIFY_AUTHENTICATOR} component={VerifyAuthenticatorContainer} />
          <Route
            path={ROUTES.SET_PASSWORD}
            render={() => (<SetPasswordContainer {...customProps} />)}
          />
        </Switch>
      </div>
    );
  }
}

export default SignupContainer;

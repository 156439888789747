import React, { Component } from 'react';
import classNames from 'classnames';
import { getWhiteLabelAsset, checkImage } from '../../utils/utils';
import MetaLogo from '../../assets/images/proofpoint-logo-white.svg';
import './logo.sass';

const logoStyle = imagePath => ({
  backgroundImage: `url(${imagePath})`,
});

class Logo extends Component {
  state = {};

  componentDidMount() {
    const logoPath = getWhiteLabelAsset('logo');

    checkImage(
      logoPath,
      () => this.setState({ logoPath }),
      () => this.setState({ logoPath: MetaLogo }),
    );
  }

  isMetaLogo = () => this.state.logoPath?.search('proofpoint-logo-white.svg') !== -1;

  render() {
    const { logoPath } = this.state;
    return (
      <div className={classNames('logo-container', { basic: this.isMetaLogo() })}>
        {logoPath ? <div className="logo-img" style={logoStyle(logoPath)} /> : <div />}
      </div>
    );
  }
}

export default Logo;

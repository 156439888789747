import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MetaButton from '../../components/meta-button';
import { BUTTON_DESIGNS } from '../../consts/consts';
import BottomLink from '../../components/bottom-link';
import { TextInputField } from '../../components/form-fields';
import Localization from '../../assets/localization';
import './set-email.sass';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = Localization.FORM_FIELDS.EMAIL_FIELD.REQUIRED;
  } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)) {
    errors.email = Localization.FORM_FIELDS.EMAIL_FIELD.INVALID;
  }
  return errors;
};

const SetEmailForm = (props) => {
  const {
    handleLinkClick,
    handleSubmit,
    loading = false,
    invalid,
    bottomLinkTextBefore,
    bottomLinkTitle,
    bottomLinkHref,
  } = props;

  return (
    <form className="set-email-form" onSubmit={handleSubmit}>
      <Field
        name="email"
        type="email"
        component={TextInputField}
        placeholder={Localization.FORM_FIELDS.EMAIL_FIELD.PLACEHOLDER}
        autocomplete="username"
        autoFocus
        icon="icon-ic-send"
        label={props.formTitle}
      />
      <MetaButton
        text={Localization.SET_EMAIL_PAGE.CONFIRM_BUTTON}
        buttonType="submit"
        disabled={loading || invalid}
        variant={BUTTON_DESIGNS.SECONDARY}
      />
      <BottomLink
        handleLinkClick={handleLinkClick}
        textBeforeLink={bottomLinkTextBefore}
        linkTitle={bottomLinkTitle}
        linkHref={bottomLinkHref}
      />
    </form>
  );
};

export default reduxForm({
  form: 'setEmailForm',
  touchOnBlur: false,
  validate,
})(SetEmailForm);

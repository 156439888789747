import {
  getNewToken,
  loginWithCode,
  loginWithMetaIdP as loginWithMetaIdpAction,
  getDirectSSOForApplication,
  ACTION_NAMES, getPreAuthSSOLogin,
} from '../../actions/actions';
import { log, extractErrMsg, saveCookiesToDomain, clearCookiesFromDomain } from '../../utils/utils';
import { APP_SSO_AUTH_TYPE } from '../../consts/consts';

const initialState = {
  loading: true,
};

export function cleanState() {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.APP_VIEW_INITIAL_STATE });
  };
}

export function getTokenFromCookie(refreshToken) {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_START });
    log('ACTION: getTokenFromCookie');
    getNewToken(refreshToken)
      .then((response = {}) => {
        saveCookiesToDomain(response.data);
        dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS, response });
      })
      .catch((error = {}) => {
        clearCookiesFromDomain();
        const errorMessage = error.message;
        log(`ERR: getTokenFromCookie ${error.message}`);
        dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_FAIL, error: errorMessage });
      });
  };
}

export function getTokenFromOtp(otp) {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_START });
    log('ACTION: getTokenFromOtp');
    loginWithCode(otp)
      .then((response = {}) => {
        saveCookiesToDomain(response.data);
        dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_SUCCESS, response });
      })
      .catch((error = {}) => {
        const errorMessage = error.message;
        log(`ERR: getTokenFromOtp ${error.message}`);
        dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_FAIL, error: errorMessage });
      });
  };
}

export function loginWithMetaIdp(appId, accessToken, query) {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_START });
    loginWithMetaIdpAction(appId, accessToken, query)
      .then((response = {}) => {
        dispatch({
          type: ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_SUCCESS,
          response: response.data,
        });
      })
      .catch((response = {}) => {
        const errorMessage = extractErrMsg(response, true);
        const error = response?.response?.data || {};
        dispatch({
          type: ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_FAIL,
          error,
          errorMessage,
        });
      });
  };
}

export const getAACPreAuthSSO = (appId, params) => {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.PRE_AUTH_SSO_AUTH_TYPE_START });
    getPreAuthSSOLogin(appId, params)
      .then(response => {
        // 200 will behave like a direct_sso
        if (response.status === 200) {
          dispatch({
            type: ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_SUCCESS,
            response: response.data,
          });
        }
        if (response.status === 202) {
          dispatch({
            type: ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_SUCCESS,
            response: response.data,
          });
        }
      })
      .catch(error => {
        const errorMessage = error.response.data.detail;
        dispatch({
          type: ACTION_NAMES.PRE_AUTH_FAIL,
          error: error?.response?.data,
        });
      });
  }
};
export function getDirectSSOAndRedirectToLogin(appId) {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_START });
    getDirectSSOForApplication(appId)
      .then((response = {}) => {
        const { data } = response;
        switch (data?.sso_auth_type) {
          case APP_SSO_AUTH_TYPE.DIRECT_SSO:
            dispatch({
              type: ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_SUCCESS,
              response: response.data,
            });
            break;
          case APP_SSO_AUTH_TYPE.AAC_PREAUTH:
            dispatch({ type: ACTION_NAMES.PRE_AUTH_SSO_AUTH_TYPE_START });
            break;
          default:
            dispatch({
              type: ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_SUCCESS ,
              response: {},
            })
        }
      })
      .catch((response = {}) => {
        if (response?.response?.status === 404) {
          dispatch({
            type: ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_SUCCESS,
            response: {},
          });
        } else {
          const errorMessage = extractErrMsg(response, true);
          const error = response?.response?.data || {};
          dispatch({ type: ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_FAIL, error, errorMessage });
        }
      });
  };
}

export function onAppViewLoaded() {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.APP_VIEW_PAGE_LOADED });
  };
}

export default function appViewReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.APP_VIEW_PAGE_LOADED:
      return { ...state, loading: false };
    case ACTION_NAMES.APP_VIEW_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_START:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_START:
    case ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        refreshToken: action.response.data.refresh_token,
        accessToken: action.response.data.access_token };

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_FAIL:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    case ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        idpLoginError: action.error,
        idpLoginErrorMessage: action.errorMessage };

    case ACTION_NAMES.LOGIN_WITH_META_IDP_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        idpRequestSuccessful: true,
        destinationURL: action.response.destination,
        RelayState: action.response.relay_state,
        SAMLResponse: action.response.saml_response,
        redirectURL: action.response.redirect_url };

    case ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        redirectToLoginUrl: action.response };

    case ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_FAIL:
      return { ...state,
        loading: false,
        redirectToLoginUrl: {} };
    case ACTION_NAMES.PRE_AUTH_SSO_AUTH_TYPE_START:
      return {
        ...state,
        performPreAuthLogin: true,
      };
    case ACTION_NAMES.PRE_AUTH_FAIL:
      return {
        ...state,
        idpLoginError: action.error,
      }
    default:
      return { ...state };
  }
}

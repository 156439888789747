import {
  ACTION_NAMES,
} from '../../../../actions/actions';

const initialState = {
  loading: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SET_PHONE_INITIAL_STATE });
  };
}

export default function setPhoneReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.SET_PHONE_INITIAL_STATE:
      return { ...initialState, phone: state.phone };

    case ACTION_NAMES.SET_PHONE_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.SET_PHONE_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true,
        phone: action.phone };

    case ACTION_NAMES.SET_PHONE_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    default:
      return { ...state };
  }
}

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCookie, redirectToLogin } from '../../utils/utils';
import { Redirect } from 'react-router-dom';
import { NSOF_REFRESH_TOKEN } from '../../consts/general-consts';
import { ROUTES } from '../../consts/routes';
import { loginWithOIDCDispatch } from './oidc-apps-state';
import { getQueryParams } from '../../utils/utils';
import { getTokenFromOtp, getTokenFromCookie } from '../../redux/action-creators';
import { getDirectSSOAndRedirectToLogin } from '../app-view/app-view-state';

class OidcAppsView extends Component {
  componentDidMount() {
    const parsedQueryParams = getQueryParams();
    const existingCookie = getCookie(NSOF_REFRESH_TOKEN);
    if (parsedQueryParams.code) {
      this.props.getTokenFromOtp(parsedQueryParams.code);
    } else if (existingCookie && this.props.enableCookie) {
      this.props.getTokenFromCookie(existingCookie);
    } else if (!parsedQueryParams.code && parsedQueryParams.client_id) {
      this.props.getDirectSSOAndRedirectToLogin(parsedQueryParams.client_id);
    } else {
      redirectToLogin(null, true);
    }
  }

  render() {
    const {
      loading,
      accessToken,
      redirectURL,
      error,
      ssoRedirectToLogin,
    } = this.props;

    if (ssoRedirectToLogin) {
      const { direct_sso_login } = ssoRedirectToLogin;
      const invokeToSSO = direct_sso_login ? `invokeLoginWithSSO=${encodeURIComponent(direct_sso_login)}&` : '';
      const redirectUrl = `${ROUTES.LOGIN}?${invokeToSSO}next=${encodeURIComponent(window.location.href)}`;
      return (
        <Redirect
          to={redirectUrl}
        />
      );
    }
    if (redirectURL) {
      window.location.href = redirectURL;
      return <div />;
    }
    if (accessToken) {
      this.props.loginWithOIDCDispatch(accessToken);
    }
    if (error) {
      const { title, detail, request_id: requestId } = error;
      return (
        <Redirect
          to={{
            pathname: `${ROUTES.ERROR_PAGE}/?title=${encodeURIComponent(
              title,
            )}&reason=${encodeURIComponent(detail)}&request_id=${encodeURIComponent(requestId)}`,
          }}
        />
      );
    }
    if (loading) {
      return (
        <div />
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    ...state.oidcAppViewState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginWithOIDCDispatch,
      getTokenFromOtp,
      getTokenFromCookie,
      getDirectSSOAndRedirectToLogin,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OidcAppsView);

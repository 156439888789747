import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MetaButton from '../../../../components/meta-button';
import { BUTTON_DESIGNS } from '../../../../consts/consts';
import BottomLink from '../../../../components/bottom-link';
import { TextInputField, PasswordInputField } from '../../../../components/form-fields';
import { ROUTES } from '../../../../consts/routes';
import Localization from '../../../../assets/localization';
import './login-form.sass';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = Localization.FORM_FIELDS.REQUIRED_FIELD_ERROR;
  }

  if (!values.password) {
    errors.password = Localization.FORM_FIELDS.REQUIRED_FIELD_ERROR;
  }
  return errors;
};

const LoginForm = (props) => {
  const {
    handleSubmit,
    loading = false,
    showRefreshButton = false,
    resetErrorMessage,
    isAllValuesExist,
    invalid,
  } = props;
  const isLoginButtonDisabled = isAllValuesExist && (loading || invalid);
  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <Field
        name="email"
        type="email"
        component={TextInputField}
        placeholder={Localization.FORM_FIELDS.EMAIL_FIELD.PLACEHOLDER_SHORT}
        autocomplete="username"
        autoFocus
      />
      <Field
        name="password"
        type="password"
        component={PasswordInputField}
        revealPassword={false}
        placeholder={Localization.FORM_FIELDS.PASSWORD_FIELD.PASSWORD_PLACEHOLDER}
        autocomplete="current-password"
        className="password-field"
      />
      <MetaButton
        text={Localization.LOGIN_BUTTON_TEXT}
        disabled={isLoginButtonDisabled}
        buttonType="submit"
        variant={BUTTON_DESIGNS.SECONDARY}
      />
      <div className="signup-link-container">
        <BottomLink
          handleLinkClick={resetErrorMessage}
          linkTitle={Localization.LOGIN_PAGE.GO_TO_SIGNUP_LINK}
          textAfterLink={Localization.LOGIN_PAGE.GO_TO_SIGNUP_LABEL}
          linkHref={ROUTES.SET_EMAIL}
        />
        <BottomLink
          handleLinkClick={resetErrorMessage}
          linkTitle={Localization.LOGIN_PAGE.FORGOT_PASSWORD_LINK}
          linkHref={ROUTES.FORGOT_PASSWORD}
        />
      </div>
      { showRefreshButton && (
        <BottomLink
          linkTitle={Localization.LOGIN_PAGE.REFRESH}
          handleLinkClick={() => window.location.reload()}
        />
      ) }
    </form>
  );
};

export default reduxForm({
  form: 'loginForm',
  touchOnBlur: false,
  validate,
})(LoginForm);

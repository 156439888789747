export default {
  BLOCK_PAGE: {
    MAIN_TITLE: 'Acesso negado!',
    TITLE: 'Você está impedido de navegar para',
    REASONS: {
      CUSTOM: 'Está classificado como um site proibido pela sua empresa',
      ATP: 'É categorizado como um ',
      CONTENT: 'É categorizado como ',
      THREAT: 'É categorizado como ',
      COUNTRY: 'Está em um país proibido de acordo com a política da sua empresa',
    },
    MALICIOUS_WEBSITE: 'site malicioso',
    SECURED_BY: 'A navegação na Internet é protegida por',
    REPLY: 'Para solicitar a reclassificação de um site, ',
    CLICK_HERE: 'clique aqui',
    FIRST: 'Para obter mais informações sobre a política da empresa\nentre em contato com seu administrador de TI.',
    SUBJECT_TEXT: 'Solicitação para reavaliar a classificação do site',
    DEFAULT_OPENING: 'Acredito que um URL que tentei navegar foi bloqueado incorretamente. Todas as informações pertinentes podem ser encontradas abaixo.',
    TENANT_ID: 'Tenant ID',
    URL: 'URL',
    POLICY_ID: 'Policy ID',
    USERNAME: 'Nome de usuário',
    THANK_END: 'Obrigado',
  },
};

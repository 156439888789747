import React from 'react';
import PropTypes from 'prop-types';
import PageImage from '../meta-page-image';
import PageTitles from '../meta-page-titles';
import BottomLink from '../bottom-link';
import image from '../../assets/images/status-success.svg';
import Localization from '../../assets/localization';
import './success-page.sass';

const SuccessPage = props => (
  <div>
    <div className="form-background success-page-wrapper">
      <div className="form-content">
        <PageTitles
          subtitle={props.subtitle}
        />
        {!props.hideImage && (
          <PageImage
            image={image}
          />
        )}
        <div className="text-wrapper">
          <PageTitles
            paragraph={props.paragraphA}
          />
          <PageTitles
            subtitle={props.email}
            subtitleTitle={props.email}
          />
          <PageTitles
            paragraph={props.paragraphB}
          />
        </div>
        {
          (props.linkTitle || props.linkHref)
            && (
              <BottomLink
                textBeforeLink={props.textBeforeLink || Localization.GO_BACK_LINK}
                linkTitle={props.linkTitle}
                linkHref={props.linkHref}
                handleLinkClick={props.onClick}
              />
            )
        }
        {props.children}
      </div>
    </div>
  </div>
);

SuccessPage.defaultProps = {
  email: '',
};

SuccessPage.propTypes = {
  email: PropTypes.string,
};

export default SuccessPage;

export default {
  BLOCK_PAGE: {
    MAIN_TITLE: 'Accès refusé!',
    TITLE: 'Vous n\'êtes pas autorisé à naviguer vers',
    REASONS: {
      CUSTOM: 'Il est classé comme site Web interdit par votre entreprise',
      ATP: 'Il est classé comme un ',
      CONTENT: 'Il est classé comme un ',
      THREAT: 'Il est classé comme un ',
      COUNTRY: 'C\'est dans un pays interdit selon la politique de votre entreprise',
    },
    MALICIOUS_WEBSITE: 'site Web malveillant',
    SECURED_BY: 'La navigation Internet est sécurisée par',
    REPLY: 'Pour demander une reclassification de site Web, ',
    CLICK_HERE: 'Cliquez ici',
    FIRST: 'Pour plus d\'informations sur la politique de l\'entreprise\ncontactez votre administrateur informatique.',
    SUBJECT_TEXT: 'Demande de réévaluation de la classification du site Web',
    DEFAULT_OPENING: 'Je crois qu\'une URL que j\'ai essayée de naviguer a été incorrectement bloquée.Toutes les informations pertinentes peuvent être trouvées ci-dessous.',
    TENANT_ID: 'Tenant ID',
    URL: 'URL',
    POLICY_ID: 'Policy ID',
    USERNAME: 'Nom d\'utilisateur',
    THANK_END: 'Merci',
  },
};

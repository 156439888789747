import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUrlParameter } from '../../../../utils/utils';
import EnterPinPage from '../../../../components/enter-pin';
import Localization from '../../../../assets/localization';
import { ROUTES } from '../../../../consts/routes';
import { getQueryStringWithoutParam } from '../../../../utils/utils';
import { verifyEmail, cleanState } from './verify-email-state';

const CONFIRMATION_CODE_LENGTH = 6;

class VerifyEmailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      emailUrlParam: getUrlParameter('email'),
      loaded: true,
    });
  }

  componentWillUnmount() {
    this.props.cleanState();
  }

  onChange = (value) => {
    if (value.length === CONFIRMATION_CODE_LENGTH) {
      this.setState({ isValid: true });
      this.props.verifyEmail(this.props.email, value);
    }
  };

  render() {
    const { success, history, wrongStatus } = this.props;
    const { loaded, isValid, emailUrlParam } = this.state;
    const goBackLink = {
      linkTitle: Localization.VERIFY_FACTOR_PAGE.PREV_LINK_TEXT('Email'),
      href: ROUTES.SET_EMAIL,
      type: 'link',
    };
    if (!loaded) {
      return <div />;
    }
    if (success) {
      const qs = getQueryStringWithoutParam('email');
      return <Redirect to={`${ROUTES.SET_PASSWORD}?${qs}`} />;
    }
    if (wrongStatus) {
      return (
        <Redirect to={{
          pathname: `${ROUTES.ERROR_PAGE}${history.location.search}`,
          state: { status: wrongStatus },
        }}
        />
      );
    }
    if (!emailUrlParam) {
      return <Redirect to={`${ROUTES.LOGIN}${history.location.search}`} />;
    }

    return (
      <EnterPinPage
        onChange={this.onChange}
        loading={this.props.loading}
        errorMessage={this.props.error}
        email={emailUrlParam}
        isValid={isValid || false}
        title={Localization.VERIFY_EMAIL_PAGE.TITLE}
        paragraph={Localization.VERIFY_EMAIL_PAGE.PARAGRAPH(emailUrlParam)}
        formFooterText={Localization.VERIFY_FACTOR_PAGE.FOOTER('email')}
        goBackLink={goBackLink}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.setEmailState.email,
    ...state.AppState,
    ...state.verifyEmailState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ verifyEmail, cleanState }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailContainer);

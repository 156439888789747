// import React, { useEffect, useState } from 'react'
// import WebSecPage from './components/ws-page/web-sec-page';
// import { LogoComponent } from './components/logo-component/logo-component';
// import ExtensionBanner from './components/extension-banner';
// import { BackgroundComponent } from './components/background-component/background-component';
// import { UTILS } from './utils';
import './app.sass'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { create } from 'jss';
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
  jssPreset,
} from '@material-ui/core/styles';
import Background from '../../components/background/index';
import { fuseTheme } from '@proofpoint/fuse-ui';
import { config } from '../../config/config';
import { ROUTES } from '../../consts/routes';
import { saveCookie, removeCookie } from '../../utils/utils';
import { HOST_REFRESH_TOKEN, NSOF_REFRESH_TOKEN, SESSION_TOKEN, PERSIST_LOGIN } from '../../consts/general-consts';

import LoginContainer from '../login/login-container'
import ForgotPasswordContainer from '../forgot-password/forgot-password-container';
import ResetPasswordContainer from '../reset-password/reset-password-container';
import ErrorPageContainer from '../error/error-page-container';
import SignupContainer from '../signup/signup-container';
import OpenAppContainer from '../open-app/open-app-container';
import HeadlessLoginDoneContainer from '../headless-login-done/headless-login-done-container';
import ZeroLoginDoneContainer from '../zero-login-done/zero-login-done-container';
import WelcomeKnownOrgContainer from '../welcome-known-org/welcome-known-org-container';
import LoadingContainer from '../loading/loading-container';
import ForgotOrgContainer from '../forgot-org/forgot-org-container';
import ZoneLoginContainer from '../zone-login/zone-login-container';
import AppViewContainer from '../app-view/app-view-container';
import AacAppViewContainer from '../aac-app-view/aac-app-view-container';
import PersistSSOViewContainer from '../persist-sso-view/persist-sso-view-container';
import NoOrgRedirectContainer from '../no-org-redirect/no-org-redirect-container';
import BlockedPageContainer from '../blocked/blocked-page-container';
import RedirectContainer from '../redirect-container';
import ReportsContainer from '../reports-container';

import EnforcerPage from '../enforcer/enforcer-page';
import AppIsolateRedirect from '../isolation/isolate-redirect';
import OidcAppsView from '../oidc-apps-view/oidc-apps-view';

const NoOverlayPage = () => <div>NoOverlay Screen</div>;

const PageNotFound = () => <div>404 Page</div>;

const generateClassName = createGenerateClassName({
  productionPrefix: 'pf',
});
const jss = create({
  ...jssPreset(),
});

class App extends Component {
  render() {
    return (
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <ThemeProvider theme={fuseTheme}>
          <Background>
            <Switch>
              <Route path={ROUTES.REPORTS}>
                <ReportsContainer />
              </Route>
              <Route path={ROUTES.REDIRECT}>
                <RedirectContainer />
              </Route>
              <Route path={ROUTES.FORGOT_PASSWORD}>
                <ForgotPasswordContainer />
              </Route>
              <Route path={ROUTES.RESET_PASSWORD}>
                <ResetPasswordContainer />
              </Route>
              <Route path={ROUTES.NO_OVERLAY}>
                <NoOverlayPage />
              </Route>
              <Route path={ROUTES.ORG_REDIRECT}>
                <NoOrgRedirectContainer />
              </Route>
              <Route path={ROUTES.PAGE_NOT_FOUND}>
                <PageNotFound />
              </Route>
              <Route path={ROUTES.ERROR_PAGE}>
                <ErrorPageContainer />
              </Route>
              <Route path={ROUTES.BLOCK_PAGE}>
                <BlockedPageContainer />
              </Route>
              <Route path={ROUTES.ENFORCER}>
                <EnforcerPage />
              </Route>
              <Route exact path={ROUTES.APPS_ISOLATION}>
                <AppIsolateRedirect />
              </Route>
              <Route path={ROUTES.LOGIN_SSO_OIDC_PAGE}>
                <OidcAppsView />
              </Route>
              <Route path={ROUTES.LOGIN_SSO_OIDC_PAGE_WITH_COOKIE}>
                <OidcAppsView enableCookie />
              </Route>
              <Route path={ROUTES.SIGNUP}>
                <SignupContainer />
              </Route>
              <Route path={ROUTES.LOADING}>
                <LoadingContainer />
              </Route>
              <Route path={ROUTES.OPEN_APP}>
                <OpenAppContainer />
              </Route>
              <Route path={ROUTES.ZERO_LOGIN_DONE}>
                <ZeroLoginDoneContainer />
              </Route>
              <Route path={ROUTES.HEADLESS_LOGIN_DONE}>
                <HeadlessLoginDoneContainer />
              </Route>
              <Route path={ROUTES.WELCOME_KNOWN_ORG}>
                <WelcomeKnownOrgContainer />
              </Route>
              <Route path={ROUTES.FORGOT_ORG}>
                <ForgotOrgContainer />
              </Route>
              <Route path={ROUTES.LOGIN_SSO_OIDC_PAGE}>
                <OidcAppsView />
              </Route>
              <Route path={ROUTES.LOGIN_SSO_OIDC_PAGE_WITH_COOKIE}>
                <OidcAppsView enableCookie />
              </Route>
              <Route path={ROUTES.SP_APP_VIEW}>
                <AppViewContainer />
              </Route>
              <Route path={ROUTES.SP_APP_VIEW_WITH_COOKIE}>
                <AppViewContainer enableCookie />
              </Route>
              <Route path={ROUTES.AAC_APP_VIEW}>
                <AacAppViewContainer />
              </Route>
              <Route path={ROUTES.PERSIST_SSO_VIEW}>
                <PersistSSOViewContainer />
              </Route>
              <Route path={ROUTES.ZONE}>
                <ZoneLoginContainer />
              </Route>
              <Route
                path={ROUTES.LOGOUT}
                render={() => {
                  removeCookie(SESSION_TOKEN);
                  removeCookie(NSOF_REFRESH_TOKEN);
                  removeCookie(PERSIST_LOGIN);
                  saveCookie(`${HOST_REFRESH_TOKEN}${config.org_short_name}`, '', {
                    domain: config.metaSite,
                  });
                  return (
                    <Redirect
                      to={{
                        pathname: ROUTES.LOGIN,
                        search: window.location.search,
                      }}
                    />
                  );
                }}
              />
              <Route path={ROUTES.LOGIN}>
                <LoginContainer />
              </Route>
              <Route
                path="*"
                render={() => (
                  <Redirect
                    to={{
                      pathname: ROUTES.LOGIN,
                      search: window.location.search,
                    }}
                  />
                )}
              />
            </Switch>
          </Background>
        </ThemeProvider>
      </StylesProvider>
    )
  }
}

export default withRouter(connect(state => state.AppState)(App));

import React, { Component } from 'react';
import queryString from 'query-string';
import MetaLoader from '../../components/meta-loader';
import './loading-container.sass';

class LoadingContainer extends Component {
  componentDidMount() {
    const parsedQueryParams = queryString.parse(this.props.location?.search);
    if (this.props.history) {
      this.props.history.replace(`#code=${parsedQueryParams.code}${parsedQueryParams.app ? `?app=${parsedQueryParams.app}` : ''}`);
    }
  }

  render() {
    return (
      <div className="loading-page-container">
        <MetaLoader />
      </div>
    );
  }
}

export default LoadingContainer;

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getOrgName } from '../../../../utils/utils';
import { ROUTES } from '../../../../consts/routes';
import { cleanState as cleanMfaSelectState } from '../../../../scenes/signup/components/select-mfa/select-mfa-state';
import SetAuthenticatorManualPage from './components/manually/components/set-authenticator-manual-page';
import { cleanState, getSecret } from './set-authenticator-state';
import SetAuthenticatorPage from './components/set-authenticator-page';

class SetAuthenticatorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isManual: false,
      isAuthSet: false,
    };
  }

  componentDidMount() {
    this.props.getSecret(this.props.stateToken);
  }

  componentWillUnmount() {
    this.props.cleanState();
  }

  goToManualAuthenticator = () => {
    this.setState({
      isManual: true,
    });
  };

  goToFactorVerify = () => {
    this.setState({
      isAuthSet: true,
    });
  };

  cancelFactorSelection = () => {
    this.props.cleanMfaSelectState(['mfaFactors', 'mfaStatus', 'skippable']);
  };

  render() {
    const orgName = getOrgName();
    const {
      success, history, loading, error, email, secret, stateToken,
    } = this.props;
    const { isManual, isAuthSet } = this.state;

    if (!stateToken) {
      return <Redirect to={`${ROUTES.LOGIN}${history.location.search}`} />;
    }
    if (isAuthSet) {
      return <Redirect to={`${ROUTES.VERIFY_AUTHENTICATOR}${history.location.search}`} />;
    }

    return !isManual
      ? (
        <SetAuthenticatorPage
          loading={loading}
          errorMessage={error}
          email={email}
          orgName={orgName}
          goToManualAuthenticator={this.goToManualAuthenticator}
          onSubmit={this.goToFactorVerify}
          secret={secret}
          cancelFactorSelection={this.cancelFactorSelection}
        />
      )
      : (
        <SetAuthenticatorManualPage
          loading={loading}
          errorMessage={error}
          secret={secret}
          onSubmit={this.goToFactorVerify}
          invalid={success}
          cancelFactorSelection={this.cancelFactorSelection}
        />
      );
  }
}

function mapStateToProps(state) {
  return {
    ...state.setAuthenticatorState,
    email: state.setEmailState.email || state.loginState.userEmail,
    stateToken: state.AppState.stateToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cleanState, getSecret, cleanMfaSelectState }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SetAuthenticatorContainer);

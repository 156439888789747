export const NSOF_REFRESH_TOKEN = 'nsof-refreshToken';
export const HOST_REFRESH_TOKEN = '__Secure-metanetworks-login-';
export const SESSION_TOKEN = 'sessionToken';
export const INVOKE_LOCAL_LOGIN = 'metalogin';
export const PERSIST_LOGIN = 'persist';

export default {
  NSOF_REFRESH_TOKEN,
  HOST_REFRESH_TOKEN,
  SESSION_TOKEN,
  INVOKE_LOCAL_LOGIN,
  PERSIST_LOGIN,
};

export const BLOCKED_PAGE_KEYS = {
  BLOCKED_PAGE_TITLE: 'blockedPageTitle',
  BLOCKED_PAGE_MESSAGE: 'blockedPageMessage',
};
export const BLOCKED_PAGE_URLS = {
  'https://dropbox.com/': {
    navigationTitle: 'OneDrive',
    navigationUrl: 'https://pfpt-my.sharepoint.com',
  },
  'https://box.com/': {
    navigationTitle: 'OneDrive',
    navigationUrl: 'https://pfpt-my.sharepoint.com',
  },
};

export const gartnerOrgName = 'shesek';

import React, { Component } from 'react';
import { config } from '../..//config/config';
import MetaLoader from '../../components/meta-loader';
import { redirectToRoute } from '../../utils/utils';
import PageTitles from '../../components/meta-page-titles';
import { FormError } from '../../components/form-fields';
import Localization from '../../assets/localization';
import ZoneLoginForm from './zone-login-form';

class zoneLoginContainer extends Component {
  goToNextUrl = (formData) => {
    let uiZonePrefix = '';
    if (formData.uiZone === 'stg1') {
      uiZonePrefix = '.stage';
    }
    if (formData.uiZone === 'dev') {
      uiZonePrefix = '.dev';
    }
    const nextRoute = `https://${formData.zone || 'p'}-${
      formData.org
    }${uiZonePrefix}.${config.metaSite}`;
    redirectToRoute(nextRoute);
  };

  render() {
    const { loading, errorMessage } = this.props;
    return (
      <div>
        <div className="form-background">
          <div className="form-content">
            <PageTitles
              subtitle={Localization.ZONE_LOGIN.SUBTITLE}
              paragraph={Localization.ZONE_LOGIN.PARAGRAPH}
              paragraphStyle={{ marginBottom: '44px', maxWidth: '400px' }}
            />
            <ZoneLoginForm onSubmit={this.goToNextUrl} loading={false} />
          </div>
          {loading
            ? <MetaLoader isShown={loading} />
            : <FormError message={errorMessage} isShown={Boolean(errorMessage)} />}
        </div>
      </div>
    );
  }
}

export default zoneLoginContainer;

import queryString from 'query-string'
import qs from 'qs';
import jwtDecode from 'jwt-decode';
import { config } from '../config/config';
import { KEYSTROKE_KEYS, PFPT_HOSTNAME, PFPT_SUFFIX, META_HOSTNAME, EXTERNAL_ROUTES } from '../consts/consts';
import Localization from '../assets/localization';
import Cookies from 'js-cookie';
import { ROUTES } from '../consts/routes';
import { NSOF_REFRESH_TOKEN, HOST_REFRESH_TOKEN } from '../consts/general-consts';

export const saveCookie = (key, value, options = {}) => {
  const cookieOptions = {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'Lax',
  };
  if (options.expirationInSeconds) {
    //  Turn seconds to day fragments 60 sec / 60 min / 24 hours
    cookieOptions.expires = options.expirationInSeconds / 60 / 60 / 24;
  }
  if (options.domain) {
    cookieOptions.domain = options.domain;
  }
  Cookies.set(key, value, cookieOptions);
};

export const removeCookie = (key, options = {}) => {
  Cookies.remove(key, options);
};
export const getCookie = key => Cookies.get(key);

export const generateLoginLink = (
  next = null,
  withLogoutFlag = false,
  appPathName,
  addExtraQuery,
) => {
  const pathName = appPathName || window.location.pathname;
  const search = addExtraQuery ? window.location.search : '';
  const nextUrl = next || `${window.location.origin}${pathName}${search}`;
  const hostNames = window.location.hostname.split('.');
  const orgShortName = hostNames.length > 1 ? hostNames[0] : '';
  const endpoint = withLogoutFlag
    ? config.loginLinkLogoutEndpoint
    : config.loginLinkLoginEndpoint;
  const envSuffix = getEnvSuffix();
  let extraQuery = '';
  if (addExtraQuery) {
    const invokeLoginWithSSO = getUrlParameter('invokeLoginWithSSO');
    extraQuery = invokeLoginWithSSO && invokeLoginWithSSO !== 'false'
      ? `&invokeLoginWithSSO=${invokeLoginWithSSO}`
      : '';
  }
  if (config.isRunningLocalServer) {
    extraQuery += '&isRunningLocalMonkey=true';
  }
  return Localization.formatString(
    config.loginLinkTemplate,
    orgShortName,
    envSuffix,
    endpoint,
    pathName.slice(1),
    encodeURIComponent(nextUrl),
    extraQuery,
  );
};

export const redirectToLogin = (appRoute, addExtraQuery, nextUrl = null, logoutFlag = false) => {
  window.location.href = generateLoginLink(nextUrl, logoutFlag, appRoute, addExtraQuery);
};
export const getUrlParameter = (name = '', isCheckOnHref) => {
  if (!name) {
    return '';
  }
  const nameParam = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${nameParam}=([^&]*)`);
  const urlQuery = isCheckOnHref ? window.location.href : window.location.search;
  const results = regex.exec(urlQuery);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const parseApiEnv = (
  includeProdPrefix = false,
  includeStagePrefix = true,
  isMetaConnect = false,
) => {
  const parsedHostname = window.location.hostname.split('.');
  if (isMetaConnect) {
    const envSuffix = parsedHostname.length > 3 ? `${parsedHostname[1]}` : '';
    const splitEnvSuffix = envSuffix.split('-')[0];
    switch (splitEnvSuffix) {
      case 'mcstg1':
        return 'stg1-';
      case 'mcdev':
        return 'dev-';
      default:
        return '';
    }
  }
  const parseEnv = parsedHostname[0] ? parsedHostname[0].split('-') : [];
  // vpnVerification requires prod prefix although all the rest don't need it explicitly
  let apiPrefix = includeProdPrefix ? 'p-' : '';
  const strippedHostname = parsedHostname.length > 3 ? parsedHostname[1] : '';
  /**
   Dev and Staging environment should go to staging api by default
   unless explicitly written otherwise
   */
  if ((strippedHostname === 'stage' || parsedHostname[1] === 'localhost') && includeStagePrefix) {
    apiPrefix = 'stg1-';
  }
  if ((strippedHostname === 'dev' || strippedHostname === 'dev2' || strippedHostname === 'dev3' || strippedHostname === 'mui') && includeStagePrefix) {
    apiPrefix = 'dev-';
  }

  if (parseEnv.length > 1) {
    apiPrefix = `${parseEnv[0]}-`;
  }
  return apiPrefix;
};

export const getEnvSuffix = () => {
  const { hostname } = window.location;
  const parsedHostname = hostname ? hostname.split('.') : [];
  let envSuffix = parsedHostname.length > 3 ? `.${parsedHostname[1]}` : '';

  if (
    parsedHostname.length === 4
        && parsedHostname.includes(PFPT_HOSTNAME.split('.')[0])
        && parsedHostname.includes(PFPT_HOSTNAME.split('.')[1])
  ) {
    envSuffix = '';
  }

  if (parsedHostname[1] === 'localhost') {
    envSuffix = '.stage';
  }

  if (parsedHostname[1] === 'mui') {
    return `.${parsedHostname[1]}.${parsedHostname[2]}`;
  }
  return envSuffix;
};

const APPLICATION_ENUMS = {
  PORTAL: 'portal',
  SSP: 'ssp',
  META_CONNECT: 'metaconnect',
  APPS: 'apps',
  OVERLAY: 'overlay',
  API: 'api',
};

const defaultAppRoute = `${APPLICATION_ENUMS.PORTAL}/#/`;

const serviceParamToServiceName = {
  apps: Localization.CHOOSE_LOGIN_METHOD.APP_NAMES.APPS,
  metaconnect: Localization.CHOOSE_LOGIN_METHOD.APP_NAMES.META_CONNECT,
  portal: Localization.CHOOSE_LOGIN_METHOD.APP_NAMES.PORTAL,
  overlay: Localization.CHOOSE_LOGIN_METHOD.APP_NAMES.MFA,
  ssp: Localization.CHOOSE_LOGIN_METHOD.APP_NAMES.SSP,
};

function getOrgName() {
  return config.org_short_name;
}

function getQueryParams() {
  return queryString.parse(window.location.search);
}

function getQueryParam(param) {
  const parsedQueryParams = queryString.parse(window.location.search);
  if (param && parsedQueryParams[param]) {
    return parsedQueryParams[param];
  }
  return '';
}

function addUrlParam(key, value) {
  if (key !== undefined && key !== null && value !== undefined && value !== null) {
    const encodedValue = encodeURIComponent(value);
    const searchParams = window.location.search
      ? `${window.location.search}&${key}=${encodedValue}`
      : `?${key}=${encodedValue}`;
    window.history.replaceState(null, null, searchParams);
  }
}

function getQueryStringWithoutParam(param) {
  const parsedQueryParams = queryString.parse(window.location.search);
  if (parsedQueryParams[param]) {
    delete parsedQueryParams[param];
  }
  return queryString.stringify(parsedQueryParams);
}

function getServiceName() {
  return serviceParamToServiceName[getUrlParameter('service')] || serviceParamToServiceName.portal;
}

function updateServiceName() {
  config.service_name = getServiceName();
}

export const parseOrgName = (orgName) => {
  if (!orgName) {
    return null;
  }
  const parsedOrg = orgName.split('-');
  // this check is for mcdiag - the org section there contains 3 parts
  if (parsedOrg.length > 2) {
    return parsedOrg[1];
  }
  return parsedOrg[parsedOrg.length - 1];
};

export const updateOrgsInConfig = () => {
  const hostNames = window.location.hostname.split('.');
  let orgShortName = hostNames.length > 1 ? hostNames[0] : null;
  if (window.location.hostname === `${PFPT_HOSTNAME}.${PFPT_SUFFIX}`) {
    orgShortName = null;
  }
  const eOrgShortName = getUrlParameter('eorg', true) || orgShortName;
  config.org_short_name = parseOrgName(orgShortName);
  config.eorg_short_name = parseOrgName(eOrgShortName);
};


function getDefaultNextRouteForService(routeParams) {
  const service = getUrlParameter('service');
  const apiEnv = parseApiEnv(false, false);
  const envSuffix = getEnvSuffix();
  const parsedQuery = queryString.stringify(routeParams);
  let queryPrefix = '';
  if (parsedQuery.length) {
    queryPrefix = defaultAppRoute.includes('?') ? '&' : '?';
  }

  switch (service) {
    case APPLICATION_ENUMS.META_CONNECT:
      return `https://${apiEnv}${config.org_short_name}${envSuffix}.${config.metaSite}/${APPLICATION_ENUMS.META_CONNECT}${queryPrefix}${parsedQuery}`;
    case APPLICATION_ENUMS.SSP:
      return `https://${apiEnv}${config.org_short_name}${envSuffix}.${config.metaSite}/${APPLICATION_ENUMS.SSP}${queryPrefix}${parsedQuery}`;
    default:
      if (config.metaSite === `${PFPT_HOSTNAME}.${PFPT_SUFFIX}`) {
        return `https://${apiEnv}${config.org_short_name}${envSuffix}.${config.metaSite}${ROUTES.REDIRECT}`;
      }
      return `https://${apiEnv}${config.org_short_name}${envSuffix}.${config.metaSite}/${defaultAppRoute}${queryPrefix}${parsedQuery}`;
  }
}

export const getRedirectRoute = route => {
  const apiEnv = parseApiEnv(false, false) || '';
  const envSuffix = getEnvSuffix();
  const org = config.org_short_name || '';
  return `https://${apiEnv}${org}${envSuffix}.${config.metaSite}/${route}`;
};

function sanitizeNext(nextUrl = '') {
  try {
    const decodedURL = decodeURIComponent(nextUrl);
    const parsedURL = new URL(decodedURL);
    const urlHostname = parsedURL.hostname;
    const splitUrlHostname = urlHostname.split('.');
    const urlProtocol = parsedURL.protocol;

    if (
      ((urlHostname.endsWith(`.${config.metaSite}`) || urlHostname.endsWith(`.${META_HOSTNAME}.com`)) && urlProtocol === 'https:')
      || (splitUrlHostname.slice(-1)[0] === 'localhost' && urlProtocol === 'http:')
    ) {
      return nextUrl;
    }
    return '';
  } catch (e) {
    console.log('invalid url in next');
    return '';
  }
}

function getNextParam() {
  return getUrlParameter('next') || getDefaultNextRouteForService();
}

function getRouteWithoutInternalQueries(params) {
  const paramsCopy = { ...params };
  const paramsToOmit = [
    'next',
    'signup',
    'service',
    'app',
    'debugOnScreen',
    'debugStore',
    'invokeLoginWithSSO',
    'isRunningLocalMonkey',
    'hide_sso',
    'hide_local_login',
    'direct_sso',
  ];
  // Delete all internal parameters from
  paramsToOmit.forEach(param => delete paramsCopy[param]);
  return paramsCopy;
}

function routeToNext(unsanitizedQuery = '') {
  const query = unsanitizedQuery.startsWith('?') ? unsanitizedQuery.substring(1) : unsanitizedQuery;
  const routeParams = qs.parse(query, { decoder: str => str });
  const nextURL = getNextParam();
  const decodedNextURL = decodeURIComponent(nextURL);
  const routeWithoutInternalQueries = getRouteWithoutInternalQueries(routeParams);
  const parsedQuery = queryString.stringify(routeWithoutInternalQueries);
  let queryPrefix = '';
  if (parsedQuery.length) {
    queryPrefix = decodedNextURL.includes('?') ? '&' : '?';
  }
  const parsedNextURL = `${nextURL}${queryPrefix}${parsedQuery}`;
  return parsedNextURL;
}

function redirectToRoute(url, params = {}) {
  const parsedQuery = queryString.stringify(params);
  const queryPrefix = url.includes('?') ? '&' : '?';
  window.location.replace(`${url}${queryPrefix}${parsedQuery}`);
}

function getAppRouteForEnv(orgName) {
  const apiEnv = parseApiEnv(false, false);
  const envSuffix = getEnvSuffix();
  const strippedOrgName = orgName ? orgName.split(' ').join('') : '';
  const orgShortName = strippedOrgName || config.org_short_name;

  if (window.location.hostname.indexOf('localhost') !== -1 && window.location.port) {
    return `http://${apiEnv}${orgShortName}.localhost:${window.location.port}`;
  }
  return `https://${apiEnv}${orgShortName}${envSuffix}.${config.metaSite}`;
}

function redirectToApp() {
  const code = getUrlParameter('code');
  window.location.href = `metanet://metanetworks.com?code=${code}`;
}

function getNextRoute() {
  const next = getUrlParameter('next');
  const app = getUrlParameter('app');
  const inbrowser = getUrlParameter('inbrowser');
  const includeRealm = getUrlParameter('includeRealm');
  const { realm } = config;
  const nextRoute = {
    nextUrl: defaultAppRoute,
    redirectUri: false,
  };
  if (next) {
    /**
     * If UI approves the next param (sanitizeNext comes back with a value) we don't use redirectUri
     */
    nextRoute.redirectUri = sanitizeNext(next) ? false : next;
    const metapkce = getUrlParameter('metapkce');
    let nextUrl = window.location.search;
    if (metapkce || includeRealm) {
      nextUrl = `${nextUrl}&realm=${realm}`;
    }
    nextRoute.nextUrl = routeToNext(nextUrl);
  } else if (inbrowser) {
    nextRoute.nextUrl = `${getAppRouteForEnv()}${ROUTES.OPEN_APP}${window.location.search}`;
  } else if (app) {
    nextRoute.nextUrl = `${getAppRouteForEnv()}${ROUTES.LOADING}${window.location.search}${includeRealm ? `&realm=${realm}` : ''}`;
  } else {
    const serviceRoute = getDefaultNextRouteForService();
    if (serviceRoute.length) {
      nextRoute.nextUrl = serviceRoute;
    }
  }
  return nextRoute;
}

function getClientId() {
  const service = getUrlParameter('service');
  const clientIdFromUrl = getUrlParameter('clientId');
  if (clientIdFromUrl) {
    return clientIdFromUrl;
  }
  return service === APPLICATION_ENUMS.OVERLAY
    ? APPLICATION_ENUMS.OVERLAY
    : APPLICATION_ENUMS.PORTAL;
}

function getNetworkElementId() {
  const networkElementId = getUrlParameter('networkElementId');
  return networkElementId || '';
}

function isIosNotSafari() {
  const userAgent = navigator.userAgent.toLowerCase();

  const isSafari = navigator.vendor
    && navigator.vendor.indexOf('Apple') > -1
    && userAgent.match(/webkit/i)
    && userAgent.match(/safari/i)
    && !userAgent.match('crios')
    && !userAgent.match('opios')
    && !userAgent.match('opera')
    && !userAgent.match('opr')
    && !userAgent.match('fxios');
  const isIos = /iphone|ipod|ipad/i.test(navigator.userAgent);
  return !isSafari && isIos;
}

function isAppAndSignup() {
  return getUrlParameter('app') && getUrlParameter('signup');
}

function getIdpIconPath(fileName) {
  return `${config.assetsBase}/idp-icons-white/${fileName || 'sso.svg'}`;
}

function getIdpIconStyle(fileName) {
  let backgroundAndBorderColor = 'blue';
  switch (fileName) {
    case 'auth0.svg':
      backgroundAndBorderColor = '#EB5424';
      break;
    case 'azure.svg':
      backgroundAndBorderColor = '#0089D6';
      break;
    case 'duo.svg':
      backgroundAndBorderColor = '#6FBF51';
      break;
    case 'gemalto.svg':
      backgroundAndBorderColor = '#EF7C00';
      break;
    case 'general.svg':
      backgroundAndBorderColor = '#2957FB';
      break;
    case 'gsuite.svg':
      backgroundAndBorderColor = '#EA4335';
      break;
    case 'microsoft.svg':
      backgroundAndBorderColor = '#D83B01';
      break;
    case 'ping.svg':
      backgroundAndBorderColor = '#B8232F';
      break;
    case 'okta.svg':
      backgroundAndBorderColor = '#0064A7';
      break;
    case 'onelogin.svg':
      backgroundAndBorderColor = '#00A9E0';
      break;
    case 'oracle.svg':
      backgroundAndBorderColor = '#F80000';
      break;
    default:
      backgroundAndBorderColor = '#3A4470';
  }
  return {
    backgroundColor: backgroundAndBorderColor,
    borderColor: backgroundAndBorderColor,
  };
}

function log(text) {
  const isApp = getUrlParameter('app');
  if (config.debugOnScreen) {
    const node = document.createElement('LI');
    const textNode = document.createTextNode(`${new Date()}:${text}`);
    node.appendChild(textNode);
    document.getElementById('meta-debug').appendChild(node);
  }
  if (isApp) {
    window.location = `#log- ${text}`;
  }
}

function startDebugCalls() {
  /**
   *
   * @param endpoint
   * This function is just to be able to debug webview connection
   * status and could be deprecated later
   */
  function checkConnection(endpoint) {
    const overlayAccess = new XMLHttpRequest();
    overlayAccess.open('GET', endpoint, true);
    log('making overlay status check');
    overlayAccess.timeout = 8000;
    overlayAccess.onload = () => {
      log(
        `check overlay status response is back: ${overlayAccess.status} ${JSON.stringify(
          overlayAccess.response,
        )}`,
      );
    };
    overlayAccess.onerror = (ev) => {
      log(`Status request error ${JSON.stringify(ev)}`);
    };
    overlayAccess.ontimeout = () => {
      log('Status request timeout');
    };
    overlayAccess.send(null);
  }

  if (config.debugOnScreen) {
    const apiPrefix = parseApiEnv(true, true);
    const apiPrefixNoDash = apiPrefix.replace('-', '');
    const API_ENDPOINT_SUFFIX = apiPrefixNoDash === 'p' || apiPrefixNoDash === 'stg1' ? 'io' : 'me';
    const vpnAccessEndpoint = `https://access.nsof.${API_ENDPOINT_SUFFIX}/v1/check?zone=${apiPrefixNoDash}`;
    setInterval(() => checkConnection(vpnAccessEndpoint), 10000);
  }
}

function extractErrMsg(response, withDetail = false) {
  const responseTitle = response?.response?.data?.title;
  const responseDetail = response?.response?.data?.detail;
  const responseTitleWithDetail = `${responseTitle} - ${responseDetail}`;
  if (withDetail) {
    return (responseTitle && responseDetail && responseTitleWithDetail)
      || Localization.INTERNAL_ERROR_MESSAGE;
  }
  return (
    responseTitle || Localization.INTERNAL_ERROR_MESSAGE
  );
}

function secondsToHms(time) {
  const parsedTime = Number(time);
  const h = Math.floor(parsedTime / 3600);
  const m = Math.floor((parsedTime % 3600) / 60);
  const s = Math.floor((parsedTime % 3600) % 60);

  const hDisplay = h > 0
    ? h + (h === 1 ? ` ${Localization.TIME_UNITS.HOUR}, ` : ` ${Localization.TIME_UNITS.HOURS}, `)
    : '';
  const mDisplay = m > 0
    ? m
    + (m === 1 ? ` ${Localization.TIME_UNITS.MINUTE}, ` : ` ${Localization.TIME_UNITS.MINUTES}, `)
    : '';
  const sDisplay = s > 0
    ? s + (s === 1 ? ` ${Localization.TIME_UNITS.SECOND}` : ` ${Localization.TIME_UNITS.SECONDS}`)
    : '';
  return hDisplay + mDisplay + sDisplay;
}

function isValidToken(str) {
  if (!str || str === '' || str.trim() === '') {
    return false;
  }
  try {
    return jwtDecode(str);
  } catch (err) {
    return false;
  }
}

function saveCookiesToDomain(tokenResponse = {}) {
  const refreshToken = tokenResponse.refresh_token;
  saveCookie(NSOF_REFRESH_TOKEN, refreshToken);
  saveCookie(`${HOST_REFRESH_TOKEN}${config.org_short_name}`, refreshToken, {
    domain: config.metaSite,
  });
}

function clearCookiesFromDomain() {
  removeCookie(NSOF_REFRESH_TOKEN);
  saveCookie(`${HOST_REFRESH_TOKEN}${config.org_short_name}`, '', {
    domain: config.metaSite,
  });
}

const isBase64 = str => {
  if (str) {
    const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}={2})$/;
    return base64Regex.test(str);
  }
  return false;
};

const blobToJson = blob => {
  if (blob) {
    return new Promise(resolve => {
      try {
        const fr = new FileReader();
        fr.onload = (e) => {
          const result = JSON.parse(e?.target?.result);
          resolve(result);
        };
        fr.readAsText(blob);
      } catch (e) {
        resolve(null);
      }
    });
  }
  return null;
};

const getWhiteLabelAsset = (fileName) => {
  const orgName = config.org_short_name;
  const apiEnv = parseApiEnv(true, true);
  const apiPrefixNoDash = apiEnv.replace('-', '');
  return `${config.assetsBase}${EXTERNAL_ROUTES.META_APPEARANCE_ASSETS}${apiPrefixNoDash}/${orgName}/${fileName}`;
};

const checkImage = (imageSrc, good, bad) => {
  const img = new Image();
  img.onload = good;
  img.onerror = bad;
  img.src = imageSrc;
};

export const executeOnEnterKey = (event, func) => {
  if (func && event && event.keyCode === KEYSTROKE_KEYS.ENTER) {
    func();
  }
};



export {
  APPLICATION_ENUMS,
  getClientId,
  getNetworkElementId,
  addUrlParam,
  getQueryStringWithoutParam,
  getNextParam,
  getServiceName,
  isIosNotSafari,
  routeToNext,
  updateServiceName,
  getNextRoute,
  redirectToRoute,
  getAppRouteForEnv,
  redirectToApp,
  log,
  isAppAndSignup,
  isValidToken,
  getIdpIconPath,
  getIdpIconStyle,
  startDebugCalls,
  getOrgName,
  extractErrMsg,
  secondsToHms,
  sanitizeNext,
  getQueryParams,
  getQueryParam,
  saveCookiesToDomain,
  clearCookiesFromDomain,
  isBase64,
  blobToJson,
  getWhiteLabelAsset,
  checkImage,
};

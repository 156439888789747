import React from 'react';
import QRCode from 'qrcode.react';
import { reduxForm } from 'redux-form';
import { BUTTON_DESIGNS } from '../../../../../consts/consts';
import MetaButton from '../../../../../components/meta-button';
import BottomLink from '../../../../../components/bottom-link';
import Localization from '../../../../../assets/localization';
import './set-authenticator.sass';

const SetAuthenticatorForm = (props) => {
  const {
    email,
    orgName,
    handleSubmit,
    loading,
    invalid,
    secret,
    handleLinkClick,
  } = props;
  const qrString = `Proofpoint - ${email} (${orgName}) `;
  const qrEncodedString = encodeURIComponent(qrString);
  const issuer = Localization.AUTHENTICATOR_ISSUER;
  const issuerEncodedString = encodeURIComponent(issuer);

  const qrLink = `otpauth://totp/${qrEncodedString}?secret=${secret}&issuer=${issuerEncodedString}`;
  return (
    <form className="set-authenticator-form" onSubmit={handleSubmit}>
      <div>
        <QRCode value={qrLink} size={125} />
      </div>
      <BottomLink
        textBeforeLink={Localization.SET_AUTHENTICATOR_PAGE.GO_TO_MANUAL_BEFORE_TEXT}
        linkTitle={Localization.SET_AUTHENTICATOR_PAGE.GO_TO_MANUAL_TEXT}
        handleLinkClick={handleLinkClick}
      />
      <MetaButton
        text={Localization.SET_AUTHENTICATOR_PAGE.CONFIRM_BUTTON}
        buttonType="submit"
        disabled={loading || invalid}
        variant={BUTTON_DESIGNS.SECONDARY}
      />
    </form>
  );
};

export default reduxForm({
  form: 'setAuthenticatorForm',
  touchOnBlur: false,
})(SetAuthenticatorForm);

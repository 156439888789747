import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ROUTES } from '../../../../../consts/routes';
import PageTitles from '../../../../../components/meta-page-titles';
import BottomLink from '../../../../../components/bottom-link/bottom-link';
import Localization from '../../../../../assets/localization';
import SetAuthenticatorForm from './set-authenticator-form';
import './set-authenticator.sass';

class SetAuthenticatorPage extends Component {
  render() {
    const {
      loading,
      invalid,
      errorMessage,
      email,
      orgName,
      onSubmit,
      secret,
      cancelFactorSelection,
    } = this.props;
    return (
      <div className="set-authenticator-page">
        <div className="form-background set-authenticator-form-wrapper">
          <div className="form-content">
            <PageTitles
              subtitle={Localization.SET_AUTHENTICATOR_PAGE.TITLE}
            />
            <ul className="instructions">
              <li>{Localization.SET_AUTHENTICATOR_PAGE.INSTRUCTION_1}</li>
              <li>
                {Localization.SET_AUTHENTICATOR_PAGE.INSTRUCTION_2}
                <span>{Localization.SET_AUTHENTICATOR_PAGE.INSTRUCTION_2A}</span>

              </li>
            </ul>
            <SetAuthenticatorForm
              loading={loading}
              errorMessage={errorMessage}
              email={email}
              orgName={orgName}
              onSubmit={onSubmit}
              invalid={invalid}
              secret={secret}
              handleLinkClick={this.props.goToManualAuthenticator}
            />
            <BottomLink
              textBeforeLink={
                Localization.SET_AUTHENTICATOR_PAGE.BACK_TO_MFA_LINK_BEFORE_TEXT
              }
              linkTitle={Localization.SET_AUTHENTICATOR_PAGE.BACK_TO_MFA_LINK_TEXT}
              linkHref={ROUTES.SELECT_MFA}
              handleLinkClick={cancelFactorSelection}
            />
          </div>
        </div>
      </div>
    );
  }
}

SetAuthenticatorPage.defaultProps = {
  errorMessage: '',
  loading: false,
  invalid: false,
  email: '',
  secret: '',
  orgName: '',
  goToManualAuthenticator: '',
  onSubmit: () => {},
};

SetAuthenticatorPage.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  invalid: PropTypes.bool,
  email: PropTypes.string,
  secret: PropTypes.string,
  orgName: PropTypes.string,
  goToManualAuthenticator: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SetAuthenticatorPage;

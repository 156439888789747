import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUrlParams } from './blocked-page-state';
import BlockedPage from './components/blocked-page';

class BlockedPageContainer extends Component {
  componentDidMount() {
    this.props.getUrlParams();
  }

  render() {
    return (
      <div className="login-wrapper">
        <BlockedPage {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.blockedPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getUrlParams }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockedPageContainer);

import React, { Component } from 'react';
import MetaButton from '../../../../components/meta-button';
import { BUTTON_DESIGNS } from '../../../../consts/consts';
import PageImage from '../../../../components/meta-page-image';
import PageTitles from '../../../../components/meta-page-titles';
import image from '../../../../assets/images/status-success.svg';
import { ROUTES } from '../../../../consts/routes';
import Localization from '../../../../assets/localization';
import './reset-password-success.sass';

//  TODO: add email not received link on this page to clear state and try again

class ResetPasswordSuccessPage extends Component {
  render() {
    return (
      <div>
        <div className="form-background reset-password-success">
          <div className="form-content">
            <PageImage
              image={image}
            />
            <PageTitles
              subtitle={Localization.RESET_PASSWORD_SUCCESS_PAGE.SUBTITLE}
              paragraph={Localization.RESET_PASSWORD_SUCCESS_PAGE.PARAGRAPH}
            />
            <MetaButton
              text={Localization.BACK_TO_LOGIN_LINK}
              link={{ pathname: ROUTES.LOGIN, search: window.location.search }}
              variant={BUTTON_DESIGNS.SECONDARY}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordSuccessPage;

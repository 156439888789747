import split from 'lodash/split';
import Localization from '../../assets/localization';

const BLOCKED_PAGE_REASONS = {
  CUSTOM: 'custom',
  ATP: 'atp',
  CONTENT: 'content',
  THREAT: 'threat',
  COUNTRY: 'country',
};

function createCategoriesString(reason, content = '', threat = '') {
  let categoriesString = '';
  switch (reason) {
    case BLOCKED_PAGE_REASONS.CONTENT: {
      categoriesString = content;
      break;
    }
    case BLOCKED_PAGE_REASONS.THREAT: {
      categoriesString = threat;
      break;
    }
    case BLOCKED_PAGE_REASONS.ATP: {
      categoriesString = Localization.BLOCK_PAGE.MALICIOUS_WEBSITE;
      break;
    }
    default:
      break;
  }
  if (categoriesString) {
    const categories = split(categoriesString, ',');
    if (categories.length === 1) {
      return categories.join('');
    }
    if (categories.length === 2) {
      return `${categories[0]} and ${categories[1]}`;
    }
    const lastItem = categories.pop();
    return `${categories.join(', ')} and ${lastItem}`;
  }
  return '';
}

function createBlockDescription(reason) {
  switch (reason) {
    case BLOCKED_PAGE_REASONS.CONTENT:
      return Localization.BLOCK_PAGE.REASONS.CONTENT;
    case BLOCKED_PAGE_REASONS.THREAT:
      return Localization.BLOCK_PAGE.REASONS.THREAT;
    case BLOCKED_PAGE_REASONS.ATP:
      return Localization.BLOCK_PAGE.REASONS.ATP;
    case BLOCKED_PAGE_REASONS.CUSTOM:
      return Localization.BLOCK_PAGE.REASONS.CUSTOM;
    case BLOCKED_PAGE_REASONS.COUNTRY:
      return Localization.BLOCK_PAGE.REASONS.COUNTRY;
    default:
      return Localization.BLOCK_PAGE.REASONS.CUSTOM;
  }
}

const getOrgFromUrl = () => {
  const hostNames = window.location.hostname.split('.');
  return hostNames.length > 1 ? hostNames[0] : null;
};

export {
  BLOCKED_PAGE_REASONS,
  createBlockDescription,
  createCategoriesString,
  getOrgFromUrl,
};

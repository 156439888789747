import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MetaLoader from '../../components/meta-loader';
import { EnforcerEnrichmentRedirect } from './enforcer-state';

const EnforcerPage = props => {
  const {
    redirectUrl,
    samlData,
    error,
    errorMessage,
  } = props;

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
    if (samlData) {
      setTimeout(() => {
        if (document.metaIdpForm) {
          document.metaIdpForm.submit();
        }
      }, 1000);
    }
  }, [props]);

  useEffect(props.EnforcerEnrichmentRedirect, []);
  return (
    <div className="enforcer-page">
      {!error && <MetaLoader />}
      {
        samlData && (
          <form name="metaIdpForm" action={samlData.destination} method="post">
            <input type="hidden" name="SAMLResponse" value={samlData.saml_response} />
            <input type="hidden" name="RelayState" value={samlData.relay_state || ''} />
          </form>
        )
      }
      {
        error && (
          <div>
            {errorMessage}
          </div>
        )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.enforcerPageState,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    EnforcerEnrichmentRedirect,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EnforcerPage);

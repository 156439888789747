import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import errorIcon from '../../assets/images/status-denied.svg';
import './form-error.sass';

function FormError(props) {
  const { message, className, isShown } = props;
  return (
    <div className={classNames('meta-form-error', isShown ? '' : 'hidden', className)}>
      <div className="error-icon">
        <img src={errorIcon} alt="error" />
      </div>
      <span className="input-error">{message}</span>
    </div>
  );
}

FormError.defaultProps = {
  message: '',
  isShown: true,
};

FormError.propTypes = {
  message: PropTypes.string,
  isShown: PropTypes.bool,
};

export default FormError;

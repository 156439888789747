import {
  ACTION_NAMES,
} from '../../../../actions/actions';

const initialState = {
  loading: false,
};

export function cleanState(propsToKeepArr = false) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SELECT_MFA_INITIAL_STATE, propsToKeepArr });
  };
}

export function chooseFactor(methodName) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SELECT_MFA_FACTOR_SELECTED, selectedFactor: methodName });
  };
}

export default function selectMfaReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.SELECT_MFA_INITIAL_STATE: {
      if (!action.propsToKeepArr) {
        return initialState;
      }
      const obj = {};

      Object.keys(state).forEach((key) => {
        if (action.propsToKeepArr.includes(key)) {
          obj[key] = state[key];
        }
      });
      return { ...initialState, ...obj };
    }

    case ACTION_NAMES.SELECT_MFA_GET_FACTORS_SUCCESS:
      return { ...state,
        loading: true,
        mfaFactors: action.mfaFactors,
        mfaStatus: action.mfaStatus,
        skippable: action.skippable,
        offerToEnroll: action.offerToEnroll,
        factor: action.factor };
    case ACTION_NAMES.SELECT_MFA_FACTOR_SELECTED:
      return { ...state, factor: action.selectedFactor };
    case ACTION_NAMES.SEND_PHONE_CHALLENGE_SUCCESS:
      return { ...state,
        sendPhoneChallengeSuccess: true,
        phone: action.phone };
    case ACTION_NAMES.SELECT_MFA_SKIP_ENROLL_ERROR:
      return { ...state, error: action.error };
    case ACTION_NAMES.SEND_PHONE_CHALLENGE_FAIL:
      return { ...state, error: action.error };

    default:
      return { ...state };
  }
}

import React, { Component } from 'react';
import { getUrlParameter } from '../../utils/utils';
import PageTitles from '../../components/meta-page-titles';
import BottomLink from '../../components/bottom-link';
import Localization from '../../assets/localization';
import { getOrgName } from '../../utils/utils';
import { ROUTES } from '../../consts/routes';
import { redirectToOrgLogin } from '../../actions/actions';
import MajorButton from '../../components/major-button';
import './welcome-known-org-container.sass';

class welcomeKnownOrgContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      orgName: decodeURIComponent(getUrlParameter('orgName')),
    });
  }

  moveToOrgLogin = e => {
    e.preventDefault();
    redirectToOrgLogin(getOrgName());
  };

  moveToNoOrgLogin = () => {
    redirectToOrgLogin('login');
  };

  render() {
    const { orgName } = this.state;
    return (
      <div className="login-wrapper">
        <div className="form-background">
          <div className="form-content">
            <PageTitles subtitle={Localization.WELCOME_KNOWN_ORG.TITLE(orgName)} />
            <form className="known-org-form" onSubmit={this.moveToOrgLogin}>
              <MajorButton
                title={Localization.WELCOME_KNOWN_ORG.CONFIRM_BUTTON}
                type="submit"
                style={{ margin: '30px 0 10px' }}
                disabled={false}
              />
              <BottomLink
                containerStyle={{ whiteSpace: 'pre-wrap' }}
                handleLinkClick={this.moveToNoOrgLogin}
                textBeforeLink={Localization.WELCOME_KNOWN_ORG.BOTTOM_LINK_LABEL}
                linkTitle={Localization.WELCOME_KNOWN_ORG.BOTTOM_LINK_LINK}
                linkHref={ROUTES.LOGIN_NO_ORG_PAGE}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default welcomeKnownOrgContainer;

import get from 'lodash/get';
import {
  ACTION_NAMES,
  changePassword,
  VerificationMethodsEnum,
} from '../../actions/actions';
import { STATUS_CODES } from '../../consts/status-codes';
import Localization from '../../assets/localization';
import { authorize } from '../app/app-state';

const initialState = {
  loading: false,
  tokenExpired: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SET_PASSWORD_INITIAL_STATE });
  };
}

export function setPassword(password, stateToken, pkceChallenge) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SET_PASSWORD_REQUEST_START });

    changePassword(password, stateToken).then((res) => {
      const data = (res && res.data) || {};
      if (data.status === STATUS_CODES.AUTHORIZED) {
        dispatch({ type: ACTION_NAMES.SET_PASSWORD_REQUEST_SUCCESS });
        dispatch(authorize(data.session_token, true, pkceChallenge));
      } else if (data.status === STATUS_CODES.MFA_ENROLL
                && data.factors
                && data.factors.length > 0) {
        const changePasswordStateToken = data.state_token;
        dispatch({ type: ACTION_NAMES.GET_STATE_TOKEN_SUCCESS,
          stateToken: changePasswordStateToken });
        dispatch({
          type: ACTION_NAMES.SELECT_MFA_GET_FACTORS_SUCCESS,
          mfaFactors: data.factors.filter(
            availableFactor => availableFactor !== VerificationMethodsEnum.EMAIL,
          ),
          mfaStatus: data.status,
          skippable: data.skippable,
          factor: null,
          offerToEnroll: true,
        });
      } else {
        dispatch({ type: ACTION_NAMES.WRONG_STATUS, status: JSON.stringify(data) });
      }
    }).catch((response = {}) => {
      let error = '';
      const responseStatus = get(response, 'response.status');
      if (responseStatus === 401) {
        const errorType = get(response, 'response.data.type');
        if (errorType === STATUS_CODES.PASSWORD_REUSED) {
          error = Localization.RESET_PASSWORD_PAGE.PASSWORD_REUSED;
        } else if (errorType === STATUS_CODES.WEAK_PASSWORD) {
          error = Localization.RESET_PASSWORD_PAGE.PASSWORD_NOT_STRONG_ENOUGH;
        } else {
          error = Localization.RESET_PASSWORD_PAGE.TOKEN_EXPIRED;
        }
        dispatch({ type: ACTION_NAMES.SET_PASSWORD_TOKEN_EXPIRED });
      } else {
        error = get(response, 'response.data.detail') || Localization.INTERNAL_ERROR_MESSAGE;
      }
      dispatch({ type: ACTION_NAMES.SET_PASSWORD_REQUEST_FAIL, error });
    });
  };
}

export default function setPasswordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.SET_PASSWORD_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.SET_PASSWORD_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.SET_PASSWORD_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true };

    case ACTION_NAMES.SET_PASSWORD_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    case ACTION_NAMES.SET_PASSWORD_TOKEN_EXPIRED: {
      return { ...state, tokenExpired: true };
    }

    default:
      return { ...state };
  }
}

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MetaButton from '../../../../components/meta-button';
import { BUTTON_DESIGNS } from '../../../../consts/consts';
import PasswordMeter from '../../../../components/password-meter';
import Localization from '../../../../assets/localization';
import BottomLink from '../../../../components/bottom-link/bottom-link';

import './reset-password.sass';

const ResetPasswordForm = (props) => {
  const {
    handleSubmit,
    loading = false,
    isValid,
    btnText,
    passwordFieldChange,
    bottomLinkBeforeText,
    bottomLinkText,
    bottomLinkHref,
    bottomLinkShow,
  } = props;
  return (
    <form className="reset-password-form" onSubmit={handleSubmit}>
      <Field
        name="password"
        type="password"
        changeCallback={passwordFieldChange}
        minLength={8}
        props={{ minScore: 2 }}
        component={PasswordMeter}
        placeholder={Localization.FORM_FIELDS.PASSWORD_FIELD.NEW_PASSWORD_PLACEHOLDER}
        autocomplete="current-password"
        className="password-field"
        icon="icon-ic-lock"
        autoFocus
      />
      <MetaButton
        text={btnText || Localization.FORM_BUTTONS.RESET}
        buttonType="submit"
        disabled={loading || !isValid}
        variant={BUTTON_DESIGNS.SECONDARY}
      />
      <BottomLink
        textBeforeLink={bottomLinkBeforeText}
        linkTitle={bottomLinkText}
        linkHref={bottomLinkHref}
        bottomLinkShow={bottomLinkShow}
      />
    </form>
  );
};

export default reduxForm({
  form: 'resetPasswordForm',
  touchOnBlur: false,
})(ResetPasswordForm);

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './meta-loader.sass';

function MetaLoader(props) {
  const { isShown } = props;
  return (
    <div className={classNames('ball-beat', isShown ? '' : 'hidden')}>
      <div />
      <div />
      <div />
    </div>
  );
}

MetaLoader.defaultProps = {
  isShown: true,
};

MetaLoader.propTypes = {
  isShown: PropTypes.bool,
};

export default MetaLoader;

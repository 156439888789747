import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../../../consts/routes';
import { setPhone } from '../../../app/app-state';
import { cleanState as cleanMfaSelectState } from '../../../signup/components/select-mfa/select-mfa-state';
import { cleanState } from './set-phone-state';
import SetPhonePage from './components/set-phone-page';

class SetPhoneContainer extends Component {
  componentWillUnmount() {
    this.props.cleanState();
  }

  setPhone = (formData) => {
    const { factor, stateToken } = this.props;
    this.props.setPhone(formData.phone, factor, stateToken);
  };

  handleBackBtnClick = () => {
    this.props.cleanMfaSelectState(['mfaFactors', 'mfaStatus', 'skippable']);
  };

  render() {
    const {
      success, history, phone, loading, error, stateToken,
    } = this.props;
    if (!stateToken) {
      return <Redirect to={`${ROUTES.LOGIN}${history.location.search}`} />;
    }
    if (success) {
      return <Redirect to={`${ROUTES.VERIFY_PHONE}${history.location.search}`} />;
    }

    return (
      <SetPhonePage
        setPhone={this.setPhone}
        loading={loading}
        errorMessage={error}
        phone={phone}
        handleBackBtnClick={this.handleBackBtnClick}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.setPhoneState,
    stateToken: state.AppState.stateToken,
    phone: state.AppState.phone,
    factor: state.selectMfaState.factor,
    mfaFactors: state.selectMfaState.mfaFactors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setPhone, cleanState, cleanMfaSelectState }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPhoneContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
// import CopyToClipboard from 'react-copy-to-clipboard';
// import { BUTTON_DESIGNS } from '../../../../consts/consts';
// import MetaToolTip from 'common/ui/meta-tooltip';
// import MetaButton from 'common/ui/meta-button';
import ErrorImg from '../../../../assets/images/error.svg';
import Localization from '../../../../assets/localization';
import PageTitles from '../../../../components/meta-page-titles';
import './error-page.sass';

class ErrorPage extends Component {
  state = {
    urlCopied: false,
  };

  onCopy = () => {
    this.setState({ urlCopied: true });
    setTimeout(() => this.setState({ urlCopied: false }), 3000);
  };

  render() {
    const { urlCopied } = this.state;
    return (
      <div>
        <div className="form-background error-page">
          <div className="form-content">
            <ReactSVG
              beforeInjection={(svg) => {
                svg.classList.add('error-image');
              }}
              src={ErrorImg}
            />
            <PageTitles
              subtitle={this.props.title || Localization.ERROR_PAGE.TITLE}
            />
            {this.props.error && <div className="input-error">{this.props.error}</div> }
            {this.props.requestId && (
              <div className="request-id">
                {Localization.ERROR_PAGE.REQUEST_ID}
                {' '}
                {this.props.requestId}
              </div>
            ) }
            {this.props.relatedURL
              && (
                <div className="related-url-container">
                  {/*<MetaToolTip*/}
                  {/*  text={this.props.relatedURL}*/}
                  {/*  placement="bottom"*/}
                  {/*>*/}
                  {/*  <div className="related-url">*/}
                  {/*    <div className="url-title">{Localization.ERROR_PAGE.RELATED_URL}</div>*/}
                  {/*    <div>{this.props.relatedURL}</div>*/}
                  {/*  </div>*/}
                  {/*</MetaToolTip>*/}
                  <div className="copy-section-container">
                    {/*<CopyToClipboard text={this.props.relatedURL} onCopy={this.onCopy}>*/}
                    {/*  <MetaButton*/}
                    {/*    icon="icon-copy"*/}
                    {/*    variant={BUTTON_DESIGNS.SECONDARY}*/}
                    {/*  />*/}
                    {/*</CopyToClipboard>*/}
                    {urlCopied ? (
                      <span className="url-copied-indication">
                        {Localization.ERROR_PAGE.COPY_INDICATION}
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
            {!this.props.avoidAdminMessage
              && (
                <PageTitles
                  paragraph={Localization.ERROR_PAGE.SUBTITLE.FIRST}
                />
              )}
            {this.props.requestResponse && (
              <div className="request-response">
                {Localization.ERROR_PAGE.SERVER_RESPONSE}
                {' '}
                {this.props.requestResponse}
              </div>
            ) }
          </div>
        </div>
      </div>
    );
  }
}

ErrorPage.defaultProps = {
  error: '',
  title: '',
  requestId: '',
  requestResponse: '',
};

ErrorPage.propTypes = {
  error: PropTypes.string,
  title: PropTypes.string,
  requestId: PropTypes.string,
  requestResponse: PropTypes.string,
};

export default ErrorPage;

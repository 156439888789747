import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MetaLoader from '../..//components/meta-loader';
import { ROUTES } from '../../consts/routes';
import { loginWithAac, onAacAppViewLoaded } from './aac-app-view-state';
import './aac-app-view-container.sass';

class AacAppViewContainer extends Component {
  componentDidMount() {
    this.props.onAacAppViewLoaded();
  }

  render() {
    const {
      loading,
      idpRequestSuccessful,
      SAMLResponse,
      RelayState,
      destinationURL,
      idpLoginError,
    } = this.props;

    if (loading) {
      return <div />;
    }

    if (idpRequestSuccessful) {
      setTimeout(() => {
        if (document.metaIdpForm) {
          document.metaIdpForm.submit();
        }
      }, 1000);
      return (
        <form name="metaIdpForm" action={destinationURL} method="post">
          <input type="hidden" name="SAMLResponse" value={SAMLResponse} />
          <input type="hidden" name="RelayState" value={RelayState} />
          <MetaLoader />
        </form>
      );
    }

    if (!idpLoginError) {
      const id = window.location.pathname.split('/').pop();
      const query = window.location.search;
      this.props.loginWithAac(id, query);
    }
    if (idpLoginError) {
      const { title, detail, request_id: requestId } = idpLoginError;
      return (
        <Redirect
          to={{
            pathname: `${ROUTES.ERROR_PAGE}/?title=${encodeURIComponent(
              title,
            )}&reason=${encodeURIComponent(detail)}&request_id=${encodeURIComponent(requestId)}`,
          }}
        />
      );
    }

    return (
      <div className="app-page-container">
        {!idpLoginError && <MetaLoader />}
        <div>{idpLoginError}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.aacAppViewState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginWithAac,
      onAacAppViewLoaded,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AacAppViewContainer);

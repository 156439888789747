import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MetaLoader from '../../../../components/meta-loader';
import PageTitles from '../../../../components/meta-page-titles';
import { FormError } from '../../../../components/form-fields';
import Localization from '../../../../assets/localization';
import ForgotPasswordForm from './forgot-password-form';
import './forgot-password.sass';

class ForgotPasswordPage extends Component {
  render() {
    const { loading, errorMessage } = this.props;
    return (
      <div>
        <div className="form-background forgot-password-wrapper">
          <div className="form-content">
            <PageTitles
              subtitle={Localization.FORGOT_PASSWORD_PAGE.SUBTITLE}
              paragraph={Localization.FORGOT_PASSWORD_PAGE.PARAGRAPH}
            />
            <ForgotPasswordForm
              onSubmit={this.props.forgotPassword}
              loading={this.props.loading}
              errorMessage={this.props.errorMessage}
              initialValues={{ email: this.props.email }}
            />
          </div>
          { loading
            ? <div className="loader-wrapper"><MetaLoader isShown={loading} /></div>
            : <FormError message={errorMessage} isShown={Boolean(errorMessage)} /> }
        </div>
      </div>
    );
  }
}

ForgotPasswordPage.defaultProps = {
  errorMessage: '',
  loading: false,
  forgotPassword: () => {},
  email: '',
};

ForgotPasswordPage.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  forgotPassword: PropTypes.func,
  email: PropTypes.string,
};

export default ForgotPasswordPage;

import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { getCookie, getQueryParam } from '../../utils/utils';
import MetaLoader from '../../components/meta-loader';
import { NSOF_REFRESH_TOKEN } from '../../consts/general-consts';
import { ROUTES } from '../../consts/routes';
import Localization from '../../assets/localization';
import SuccessPage from '../../components/success-page';
import { onReportsContainerLoaded, getReport } from './reports-container-state';
import * as Actions from '../../redux/action-creators';

const ReportsContainer = props => {
  const { logInLoading, reportLoading, reportLoaded, accessToken, getReportError } = props;
  const reportId = getQueryParam('report_id');
  const reportFormat = getQueryParam('report_format');
  const timeFrom = getQueryParam('time_from');
  const timeTo = getQueryParam('time_to');
  const reportData = { reportId, reportFormat, timeFrom, timeTo, accessToken };

  useEffect(() => {
    const parsedQueryParams = queryString.parse(window.location.search);
    const existingCookie = getCookie(NSOF_REFRESH_TOKEN);
    if (parsedQueryParams.code) {
      props.getTokenFromOtp(parsedQueryParams.code);
    } else if (existingCookie) {
      props.getTokenFromCookie(existingCookie);
    } else {
      props.onReportsContainerLoaded();
    }
  }, []);

  if (logInLoading) {
    return (
      <div className="app-page-container">
        <MetaLoader />
      </div>
    );
  }

  if (!reportLoaded && accessToken && !getReportError) {
    props.getReport(reportData);
  } else if (!accessToken) {
    return <Redirect to={`${ROUTES.LOGIN}?next=${encodeURIComponent(window.location.href)}`} />;
  }
  if (getReportError) {
    const { title, error } = getReportError;
    return (
      <Redirect
        to={{
          pathname: `${ROUTES.ERROR_PAGE}/?title=${encodeURIComponent(
            title,
          )}&reason=${Buffer.from(error).toString('base64')}`,
        }}
      />
    );
  }

  return (
    <div className="app-page-container">
      <SuccessPage
        subtitle={Localization.DOWNLOAD_REPORT_PAGE?.[reportLoading ? 'DOWNLOADING_REPORT' : 'REPORT_DOWNLOADED']}
        textBeforeLink={Localization.DOWNLOAD_REPORT_PAGE.PARAGRAPH}
        linkTitle={Localization.DOWNLOAD_REPORT_PAGE.LINK}
        onClick={() => props.getReport(reportData)}
        hideImage={reportLoading}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ...state.reportsContainerState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTokenFromOtp: Actions.getTokenFromOtp,
      getTokenFromCookie: Actions.getTokenFromCookie,
      getOAuthInfo: Actions.getOAuthInfo,
      onReportsContainerLoaded,
      getReport,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);

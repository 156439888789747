import React, { Component } from 'react';
import SuccessPage from '../../components/success-page';
import Localization from '../../assets/localization';

class ZeroLoginDoneContainer extends Component {
  render() {
    return (
      <div className="login-wrapper">
        <SuccessPage
          subtitle={Localization.ZERO_LOGIN_DONE_PAGE.SUBTITLE}
          paragraphA={Localization.ZERO_LOGIN_DONE_PAGE.PARAGRAPH}
        />
      </div>
    );
  }
}

export default ZeroLoginDoneContainer;

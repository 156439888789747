import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MetaLoader from '../../../../components/meta-loader';
import Localization from '../../../../assets/localization';
import PageTitles from '../../../../components/meta-page-titles';
import { FormError } from '../../../../components/form-fields';
import ResetPasswordForm from './reset-password-form';
import './reset-password.sass';

class ResetPasswordPage extends Component {
  render() {
    const {
      subtitle,
      paragraph,
      passwordExpired,
      loading,
      errorMessage,
    } = this.props;
    const subtitleText = passwordExpired
      ? Localization.RESET_PASSWORD_PAGE.EXPIRED_PASSWORD_SUBTITLE
      : subtitle || Localization.RESET_PASSWORD_PAGE.SUBTITLE;
    const paragraphText = passwordExpired
      ? Localization.RESET_PASSWORD_PAGE.EXPIRED_PASSWORD_PARAGRAPH
      : paragraph || Localization.RESET_PASSWORD_PAGE.SUBTITLE;
    return (
      <div>
        <div className="form-background reset-password-wrapper">
          <div className="form-content">
            <PageTitles
              subtitle={subtitleText}
              paragraph={paragraphText}
            />
            <ResetPasswordForm
              onSubmit={this.props.resetPassword}
              loading={this.props.loading}
              errorMessage={this.props.errorMessage}
              btnText={this.props.btnText}
              passwordFieldChange={this.props.passwordFieldChange}
              isValid={this.props.isValid}
              bottomLinkBeforeText={this.props.bottomLinkBeforeText}
              bottomLinkText={this.props.bottomLinkText}
              bottomLinkHref={this.props.bottomLinkHref}
              bottomLinkShow={this.props.bottomLinkShow}
            />
          </div>
          { loading
            ? <div className="loader-wrapper"><MetaLoader isShown={loading} /></div>
            : <FormError message={errorMessage} isShown={Boolean(errorMessage)} />}
        </div>
      </div>
    );
  }
}

ResetPasswordPage.defaultProps = {
  errorMessage: '',
  loading: false,
  isValid: false,
  passwordExpired: false,
  resetPassword: () => {
  },
};

ResetPasswordPage.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  isValid: PropTypes.bool,
  resetPassword: PropTypes.func,
  passwordExpired: PropTypes.bool,
};

export default ResetPasswordPage;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PageTitles from '../../../../../components/meta-page-titles';
import BottomLink from '../../../../../components/bottom-link';
import { FormError } from '../../../../../components/form-fields';
import Localization from '../../../../../assets/localization';
import { STATUS_CODES } from '../../../../../consts/status-codes';
import SelectMfaForm from './select-mfa-form';

class SelectMfaPage extends Component {
  render() {
    const { skippable, errorMessage } = this.props;
    let skipLink = {};
    if (skippable) {
      skipLink = {
        linkTitle: Localization.SELECT_MFA_PAGE.SKIP_BUTTON_TEXT,
        onClick: this.props.onSkipEnroll,
      };
    }

    const pageTitleSubtitle = skippable
      ? Localization.SELECT_MFA_PAGE.SUBTITLE_ENROLL_ANOTHER
      : Localization.SELECT_MFA_PAGE.SUBTITLE;

    const pageTitleParagraph = skippable
      ? Localization.SELECT_MFA_PAGE.PARAGRAPH_ENROLL_ANOTHER
      : Localization.SELECT_MFA_PAGE.PARAGRAPH;

    return (
      <div>
        <div className="form-background select-mfa-container">
          <div className="form-content">
            <PageTitles
              subtitle={pageTitleSubtitle}
              subtitleStyle={{ marginBottom: '0' }}
              paragraph={pageTitleParagraph}
            />
            <SelectMfaForm
              loading={this.props.loading}
              mfaFactors={this.props.mfaFactors}
              onFactorChange={this.props.onFactorChange}
            />
            {this.props.mfaStatus === STATUS_CODES.MFA_REQUIRED && (
              <PageTitles
                paragraphStyle={{ marginTop: '60px' }}
                paragraph={Localization.SELECT_MFA_PAGE.NEED_HELP_TEXT}
              />
            )}
            {skippable && (
              <BottomLink
                linkTitle={skipLink.linkTitle}
                handleLinkClick={skipLink.onClick}
                className={skipLink.class}
              />
            )}
          </div>
          <FormError message={errorMessage} isShown={Boolean(errorMessage)} />
        </div>
      </div>
    );
  }
}

SelectMfaPage.defaultProps = {
  errorMessage: '',
  loading: false,
  skippable: false,
  mfaFactors: [],
  mfaStatus: '',
  onFactorChange: () => {},
  onSkipEnroll: () => {},
};

SelectMfaPage.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  skippable: PropTypes.bool,
  mfaFactors: PropTypes.arrayOf(PropTypes.string),
  mfaStatus: PropTypes.string,
  onFactorChange: PropTypes.func,
  onSkipEnroll: PropTypes.func,
};

export default SelectMfaPage;

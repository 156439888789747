import {
  setFactorAuthenticationMethod,
  ACTION_NAMES,
  VerificationMethodsEnum,
} from '../../../../actions/actions';
import Localization from '../../../../assets/localization';

const initialState = {
  loading: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SET_AUTHENTICATOR_INITIAL_STATE });
  };
}

export function getSecret(stateToken) {
  const reqData = {
    factor_type: VerificationMethodsEnum.SOFTWARE_TOTP,
  };
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SET_AUTHENTICATOR_REQUEST_START });
    setFactorAuthenticationMethod(stateToken, reqData).then((response) => {
      const resStateToken = (response && response.data && response.data.state_token);
      const secret = (response && response.data && response.data.secret);
      dispatch({ type: ACTION_NAMES.GET_STATE_TOKEN_SUCCESS, stateToken: resStateToken });
      dispatch({ type: ACTION_NAMES.SET_AUTHENTICATOR_REQUEST_SUCCESS, secret });
    }).catch((response = {}) => {
      const errorMessage = (response.response && response.response.data
        && response.response.data.title)
        || Localization.INTERNAL_ERROR_MESSAGE;
      dispatch({ type: ACTION_NAMES.SET_AUTHENTICATOR_REQUEST_FAIL, error: errorMessage });
    });
  };
}

export default function setAuthenticatorReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.SET_AUTHENTICATOR_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.SET_AUTHENTICATOR_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.SET_AUTHENTICATOR_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true,
        secret: action.secret };

    case ACTION_NAMES.SET_AUTHENTICATOR_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    default:
      return { ...state };
  }
}

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EnterPinPage from '../../../../../components/enter-pin';
import Localization from '../../../../../assets/localization';
import { ROUTES } from '../../../../../consts/routes';
import { STATUS_CODES } from '../../../../../consts/status-codes';
import { VerificationMethodsEnum } from '../../../../../actions/actions';
import { cleanState as cleanMfaSelectState } from '../../../../../scenes/signup/components/select-mfa/select-mfa-state';
import { cleanState as cleanLoginState } from '../../../../../scenes/login/login-state';
import { setPhone, sendPhoneChallenge } from '../../../../app/app-state';
import {
  cleanState,
  verifyFactor,
  resetErrorMessage,
} from '../verify-factor-state';

const CONFIRMATION_CODE_LENGTH = 6;

class VerifyPhoneContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      disabledBtn: false,
    };
  }

  componentWillUnmount() {
    this.props.cleanState();
  }

  onChange = (value) => {
    if (value.length === CONFIRMATION_CODE_LENGTH) {
      const {
        stateToken, factor, mfaStatus, pkceChallenge,
      } = this.props;
      this.setState({ isValid: true });
      this.props.verifyFactor(
        value,
        stateToken,
        factor,
        mfaStatus,
        pkceChallenge,
      );
    }
  };

  resetState = () => {
    this.props.resetErrorMessage();
    this.props.cleanMfaSelectState();
    this.props.cleanLoginState();
  };

  resendCode = () => {
    const {
      phone, factor, stateToken, mfaStatus,
    } = this.props;
    this.props.resetErrorMessage();
    if (this.state.disabledBtn) {
      return false;
    }
    this.setState({ disabledBtn: true });
    setTimeout(() => {
      this.setState({ disabledBtn: false });
    }, 3000);
    if (mfaStatus === STATUS_CODES.MFA_REQUIRED) {
      this.props.sendPhoneChallenge(stateToken, factor);
    } else {
      this.props.setPhone(phone, factor, stateToken);
    }
    return true;
  };

  render() {
    const { isValid, disabledBtn } = this.state;
    const {
      expired,
      factor,
      passwordExpired,
      stateToken,
      history,
      offerToEnroll,
    } = this.props;
    const resendCodeLink = {
      class: disabledBtn ? 'disabled' : '',
      linkTitle:
        Localization.VERIFY_CONFIRMATION_CODE_PAGE.PREV_LINK_TEXT_EXPIRED,
      onClick: this.resendCode,
    };
    let goBackLink;

    if (!stateToken) {
      return <Redirect to={`${ROUTES.LOGIN}${history.location.search}`} />;
    }
    if (!expired) {
      goBackLink = {
        href: ROUTES.SELECT_MFA,
        linkTitle: Localization.SELECT_MFA_LINK_TITLE,
        onClick: () => this.props.cleanMfaSelectState([
          'mfaFactors',
          'mfaStatus',
          'skippable',
        ]),
      };
    } else {
      goBackLink = {
        href: ROUTES.LOGIN,
        title: Localization.VERIFY_CONFIRMATION_CODE_PAGE.PREV_LINK_TEXT,
        onClick: this.resetState,
      };
    }

    if (passwordExpired) {
      return (
        <Redirect to={`${ROUTES.SET_PASSWORD}${history.location.search}`} />
      );
    }

    if (offerToEnroll && !factor) {
      return <Redirect to={`${ROUTES.SELECT_MFA}${history.location.search}`} />;
    }

    const METHOD_NAME = Localization.VERIFY_CONFIRMATION_CODE_PAGE[`METHOD_${factor}`];
    return (
      <div className="login-wrapper">
        <EnterPinPage
          onChange={this.onChange}
          loading={this.props.loading}
          errorMessage={this.props.error}
          phone={this.props.phone}
          isValid={isValid || false}
          title={Localization.VERIFY_CONFIRMATION_CODE_PAGE.TITLE(METHOD_NAME)}
          paragraph={Localization.VERIFY_CONFIRMATION_CODE_PAGE.PARAGRAPH(
            factor === VerificationMethodsEnum.EMAIL
              ? this.props.userEmail
              : this.props.phone,
          )}
          formFooterText={Localization.VERIFY_CONFIRMATION_CODE_PAGE.FOOTER(
            METHOD_NAME,
          )}
          resendCodeLink={resendCodeLink}
          goBackLink={goBackLink}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    passwordExpired: state.AppState.passwordExpired,
    stateToken: state.AppState.stateToken,
    factor: state.selectMfaState.factor,
    offerToEnroll: state.selectMfaState.offerToEnroll,
    mfaStatus: state.selectMfaState.mfaStatus,
    phone: state.setPhoneState.phone || state.AppState.phone,
    userEmail: state.loginState.userEmail,
    pkceChallenge: state.loginState.pkceChallenge,
    ...state.verifyFactorState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cleanState,
      verifyFactor,
      resetErrorMessage,
      setPhone,
      cleanMfaSelectState,
      cleanLoginState,
      sendPhoneChallenge,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyPhoneContainer);

export const ROUTES = {
  LOGIN: '/login/',
  REPORTS: '/login/reports',
  REDIRECT: '/login/redirect',
  LOGOUT: '/login/logout',
  FORGOT_PASSWORD: '/login/forgot-password',
  RESET_PASSWORD: '/login/reset-password',
  NO_OVERLAY: '/login/no-overlay',
  PAGE_NOT_FOUND: '/login/404',
  ORG_REDIRECT: '/login/org-redirect',
  ERROR_PAGE: '/login/error',
  BLOCK_PAGE: '/login/blocked',
  SELECT_MFA: '/login/signup/select-mfa',
  SET_PHONE: '/login/signup/set-phone',
  LOADING: '/login/loading',
  OPEN_APP: '/login/open-app',
  SP_APP_VIEW: '/login/apps/:id',
  SP_APP_VIEW_WITH_COOKIE: '/login/apps-idp/:id',
  AAC_APP_VIEW: '/login/aac-apps/:id',
  PERSIST_SSO_VIEW: '/login/persist',
  HEADLESS_LOGIN_DONE: '/login/successful-headless-login',
  ZERO_LOGIN_DONE: '/login/vpn-app-login-done',
  WELCOME_KNOWN_ORG: '/login/settings-org-login',
  SIGNUP: '/login/signup/',
  SET_EMAIL: '/login/signup/set-email',
  VERIFY_EMAIL: '/login/signup/verify-email',
  SET_PASSWORD: '/login/signup/set-password',
  VERIFY_PHONE: '/login/signup/verify-phone',
  SET_AUTHENTICATOR: '/login/signup/set-authenticator',
  VERIFY_AUTHENTICATOR: '/login/signup/verify-authenticator',
  FORGOT_ORG: '/login/forgot-org',
  ZONE: '/login/zone',
  ENFORCER: '/login/aac-rules/enforcer',
  APPS_ISOLATION: '/login/apps',
  LOGIN_SSO_OIDC_PAGE: '/login/apps/sso/oidc',
  LOGIN_SSO_OIDC_PAGE_WITH_COOKIE: '/login/apps-idp/sso/oidc',
};

export default {
  ROUTES,
};

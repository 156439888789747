import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MetaButton from '../../components/meta-button';
import { BUTTON_DESIGNS } from '../../consts/consts';
import Localization from '../../assets/localization';
import './zone-login-form.sass';

const validate = (values) => {
  const errors = {};

  if (!values.org) {
    errors.org = Localization.FORM_FIELDS.REQUIRED_FIELD_ERROR;
  }

  return errors;
};

const ZoneLoginForm = (props) => {
  const {
    handleSubmit, loading = false, invalid,
  } = props;

  return (
    <form className="zone-login-form" onSubmit={handleSubmit}>
      <div className="input-group">
        <div>
          <label htmlFor="shortname-input">{Localization.ZONE_LOGIN.ORG_SHORTNAME_FORM_FIELD}</label>
          <Field
            id="shortname-input"
            component="input"
            type="text"
            placeholder={Localization.ZONE_LOGIN.ORG_SHORTNAME_FORM_FIELD}
            name="org"
            autoComplete="on"
            autoCapitalize="off"
            spellCheck="false"
            autoCorrect="off"
          />
        </div>
        <div>
          <label htmlFor="zone-input">{Localization.ZONE_LOGIN.ZONE_FIELD}</label>
          <Field
            id="zone-input"
            component="input"
            type="text"
            placeholder={Localization.ZONE_LOGIN.ZONE_FIELD_PLACEHOLDER}
            name="zone"
            autoComplete="on"
            autoCapitalize="off"
            spellCheck="false"
            autoCorrect="off"
          />
        </div>
        <div>
          <div>
            <label htmlFor="ui-zone-input">{Localization.ZONE_LOGIN.UI_ZONE_FIELD}</label>
          </div>
          <Field
            id="ui-zone-input"
            component="select"
            placeholder={Localization.ZONE_LOGIN.UI_ZONE_FIELD}
            name="uiZone"
          >
            <option value="p">Production</option>
            <option value="stg1">Staging</option>
            <option value="dev">Dev</option>
          </Field>
        </div>
      </div>
      <MetaButton
        text={Localization.ZONE_LOGIN.SUBMIT_BUTTON_TEXT}
        buttonType="submit"
        disabled={loading || invalid}
        variant={BUTTON_DESIGNS.SECONDARY}
      />
    </form>
  );
};

export default reduxForm({
  form: 'zoneLoginForm',
  touchOnBlur: false,
  validate,
})(ZoneLoginForm);

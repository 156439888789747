import Validator from '../../../../../utils/validators';
import Localization from '../../../../../assets/localization';

const isPhoneValid = value => Validator.isPhoneNumber(value);

const validate = (values) => {
  const errors = {};
  if (!values.phone) {
    errors.phone = Localization.FORM_FIELDS.EMAIL_FIELD.REQUIRED;
  } else if (!isPhoneValid(values.phone)) {
    errors.phone = 'Wrong phone format';
  }
  return errors;
};

export default validate;

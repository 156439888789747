import LocalizedStrings from 'react-localization';
import en from './translations.en';
import de from './translations.de';
import es from './translations.es';
import it from './translations.it';
import pt from './translations.pt';
import fr from './translations.fr';

const strings = new LocalizedStrings({
  en,
  de,
  es,
  fr,
  it,
  pt,
});

export default strings;

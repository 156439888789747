import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MetaLoader from '../../../../components/meta-loader';
import PageTitles from '../../../../components/meta-page-titles'
import { FormError } from '../../../../components/form-fields';
import Localization from '../../../../assets/localization';
import LoginNoOrgForm from './login-no-org-form';

class LoginNoOrgPage extends Component {
  render() {
    const { loading, errorMessage } = this.props;
    return (
      <div>
        <div className="form-background">
          <div className="form-content">
            <PageTitles
              subtitle={this.props.subtitle}
              paragraph={this.props.paragraph}
            />
            <LoginNoOrgForm
              onSubmit={this.props.onSubmit}
              loading={this.props.loading}
              envSuffix={this.props.envSuffix}
            />
          </div>
          {loading
            ? <MetaLoader isShown={loading} />
            : <FormError message={errorMessage} isShown={Boolean(errorMessage)} />}
        </div>
      </div>
    );
  }
}

LoginNoOrgPage.defaultProps = {
  envSuffix: 'net',
  loading: false,
  subtitle: Localization.LOGIN_NO_ORG_PAGE.SUBTITLE,
  paragraph: Localization.LOGIN_NO_ORG_PAGE.PARAGRAPH,
};

LoginNoOrgPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  envSuffix: PropTypes.string,
  subtitle: PropTypes.string,
  paragraph: PropTypes.string,
  loading: PropTypes.bool,
};

export default LoginNoOrgPage;

import { ACTION_NAMES, getEnforcerEnrich, getNewToken } from '../../actions/actions';
import { extractErrMsg, getCookie } from '../../utils/utils';
import { NSOF_REFRESH_TOKEN } from '../../consts/general-consts';

const initialState = {};

export const EnforcerEnrichmentRedirect = () => dispatch => {
  dispatch({ type: ACTION_NAMES.ENFORCER_START });
  const queryParams = window.location.search;
  const existingCookie = getCookie(NSOF_REFRESH_TOKEN);
  if (existingCookie) {
    getNewToken(existingCookie)
      .then(tokenResponse => {
        const accessToken = tokenResponse?.data?.access_token;
        getEnforcerEnrich(queryParams, accessToken).then(res => {
          if (res.status === 202) {
            dispatch({
              type: ACTION_NAMES.ENFORCER_REDIRECT_SUCCESS,
              response: res.data,
            });
          }
          if (res.status === 200) {
            dispatch({
              type: ACTION_NAMES.ENFORCER_SAML_SUCCESS,
              response: res.data,
            });
          }
          if (res.status >= 400) {
            const errorMessage = extractErrMsg(res, true);
            const error = res?.data || {};
            dispatch({
              type: ACTION_NAMES.ENFORCER_FAIL,
              error,
              errorMessage,
            });
          }
        }).catch(res => {
          const errorMessage = extractErrMsg(res, true);
          const error = res?.response?.data || {};
          dispatch({
            type: ACTION_NAMES.ENFORCER_FAIL,
            error,
            errorMessage,
          });
        });
      })
  }
  else {
    getEnforcerEnrich(queryParams).then(res => {
      if (res.status === 202) {
        dispatch({
          type: ACTION_NAMES.ENFORCER_REDIRECT_SUCCESS,
          response: res.data,
        });
      }
      if (res.status === 200) {
        dispatch({
          type: ACTION_NAMES.ENFORCER_SAML_SUCCESS,
          response: res.data,
        });
      }
      if (res.status >= 400) {
        const errorMessage = extractErrMsg(res, true);
        const error = res?.data || {};
        dispatch({
          type: ACTION_NAMES.ENFORCER_FAIL,
          error,
          errorMessage,
        });
      }
    }).catch(res => {
      const errorMessage = extractErrMsg(res, true);
      const error = res?.response?.data || {};
      dispatch({
        type: ACTION_NAMES.ENFORCER_FAIL,
        error,
        errorMessage,
      });
    });
  }

};

export default function EnforcerPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.ENFORCER_REDIRECT_SUCCESS:
      return { ...state, redirectUrl: action.response.redirect_url };

    case ACTION_NAMES.ENFORCER_SAML_SUCCESS:
      return { ...state, samlData: action.response };
    case ACTION_NAMES.ENFORCER_FAIL:
      return { ...state,
        error: action.error,
        errorMessage: action.errorMessage };
    default:
      return { ...state };
  }
}

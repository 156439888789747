import React from 'react';
import Popover from 'react-popover';
import { ReactSVG } from 'react-svg';
import { VerificationMethodsEnum } from '../../../../actions/actions';
import Localization from '../../../../assets/localization';
import AuthenticatorAppIcon from '../../../../assets/images/software_totp.svg';
import SmsIcon from '../../../../assets/images/sms.svg';
import VoiceIcon from '../../../../assets/images/voicecall.svg';
import EmailIcon from '../../../../assets/images/email.svg';
import './verify-pin-popover.sass';

const VerifyPinPopover = props => (
  <Popover
    body={(
      <div>
        <div className="popover-header">
          {Localization.VERIFY_PIN_MODAL.HEADER}
        </div>
        <div className="popover-body">
          <table>
            <tbody>
              {props.methods.includes(VerificationMethodsEnum.SOFTWARE_TOTP)
            && (
              <tr onClick={() => { props.changeMethod(VerificationMethodsEnum.SOFTWARE_TOTP); }}>
                <td className="icon-cell">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.classList.add('icon');
                    }}
                    src={AuthenticatorAppIcon}
                  />
                </td>
                <td>
                  <span>{Localization.VERIFY_PIN_MODAL.AUTHENTICATOR_METHOD_LINK}</span>
                </td>
              </tr>
            )}
              {props.methods.includes(VerificationMethodsEnum.SMS)
            && (
              <tr onClick={() => { props.changeMethod(VerificationMethodsEnum.SMS); }}>
                <td className="icon-cell">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.classList.add('icon');
                    }}
                    src={SmsIcon}
                  />
                </td>
                <td>
                  <span>{Localization.VERIFY_PIN_MODAL.SMS_METHOD_LINK}</span>
                </td>
              </tr>
            )}
              {props.methods.includes(VerificationMethodsEnum.VOICECALL)
            && (
              <tr onClick={() => { props.changeMethod(VerificationMethodsEnum.VOICECALL); }}>
                <td className="icon-cell">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.classList.add('icon');
                    }}
                    src={VoiceIcon}
                  />
                </td>
                <td>
                  <span>{Localization.VERIFY_PIN_MODAL.VOICE_METHOD_LINK}</span>
                </td>
              </tr>
            )}
              {props.methods.includes(VerificationMethodsEnum.EMAIL)
            && (
              <tr onClick={() => { props.changeMethod(VerificationMethodsEnum.EMAIL); }}>
                <td className="icon-cell">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.classList.add('icon');
                    }}
                    src={EmailIcon}
                  />
                </td>
                <td>
                  <span>{Localization.VERIFY_PIN_MODAL.EMAIL_METHOD_LINK}</span>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    )}
    isOpen={props.visible}
    className="verify-pin-popover"
    tipSize={0.01}
    onOuterAction={props.onClose}
    place="below"
  >
    {props.children}
  </Popover>
);

export default VerifyPinPopover;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './secondary-button.sass';

function SecondaryButton(props) {
  if (props.type === 'externalLink') {
    return (
      <a
        className={classNames('secondary-button', props.className)}
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={props.onClick}
        style={{ ...props.style }}
      >
        {props.title}
      </a>
    );
  }

  if (props.type === 'link') {
    return (
      <Link
        className={classNames('secondary-button', props.className)}
        to={props.link}
        onClick={props.onClick}
        style={{ ...props.style }}
      >
        {props.title}
      </Link>
    );
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames('secondary-button', props.className)}
      style={{ ...props.style }}
      onClick={props.onClick}
    >
      {props.title}
    </div>
  );
}

SecondaryButton.defaultProps = {
  type: 'button',
  title: '',
  onClick: () => {},
};

SecondaryButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default SecondaryButton;

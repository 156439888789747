export const STATUS_CODES = {
  PASSWORD_EXPIRED: 'password_expired',
  MFA_ENROLL: 'mfa_enroll',
  MFA_REQUIRED: 'mfa_required',
  AUTHORIZED: 'authorized',
  PASSWORD_REUSED: 'password-reused',
  WEAK_PASSWORD: 'weak-password',
  INVALID_TOTP: 'invalid-totp',
  PASSWORD_LOCKED: 'password-locked',
};

export default {
  STATUS_CODES,
};

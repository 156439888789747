import get from 'lodash/get';
import {
  verifyPin,
  ROUTES,
  ACTION_NAMES,
  VerificationMethodsEnum,
} from '../../../../actions/actions';
import { ERRORS } from '../../../../consts/errors';
import { STATUS_CODES } from '../../../../consts/status-codes';
import { authorize } from '../../../app/app-state';
import Localization from '../../../../assets/localization';

const initialState = {
  loading: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.VERIFY_PHONE_INITIAL_STATE });
  };
}

export function verifyFactor(pinCode, stateToken, factor, mfaStatus, pkceChallenge) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.VERIFY_FACTOR_REQUEST_START });

    const route = mfaStatus === STATUS_CODES.MFA_REQUIRED
      ? ROUTES.VERIFY_PIN : ROUTES.ACTIVATE_FACTOR;
    verifyPin(pinCode, stateToken, factor, route).then((response) => {
      dispatch({ type: ACTION_NAMES.VERIFY_FACTOR_REQUEST_SUCCESS });
      const data = (response && response.data) || {};
      if (data.status === STATUS_CODES.PASSWORD_EXPIRED) {
        dispatch({
          type: ACTION_NAMES.PASSWORD_EXPIRED,
          stateToken: response.data.state_token,
        });
      } else if (data.status === STATUS_CODES.MFA_ENROLL) {
        dispatch({
          type: ACTION_NAMES.GET_STATE_TOKEN_SUCCESS,
          stateToken: response.data.state_token,
        });
        dispatch({
          type: ACTION_NAMES.SELECT_MFA_GET_FACTORS_SUCCESS,
          mfaFactors: data.factors.filter(availableFactor => availableFactor !== factor
            && availableFactor !== VerificationMethodsEnum.EMAIL),
          mfaStatus: data.status,
          skippable: data.skippable,
          factor: null,
          offerToEnroll: true,
        });
      } else {
        const sessionToken = data.session_token;
        dispatch(authorize(sessionToken, true, pkceChallenge));
      }
    }).catch((response = {}) => {
      let errorMessage;
      let expired = false;
      const responseData = get(response, 'response.data');
      if (responseData) {
        const errDetail = responseData.detail;
        const { status } = responseData;
        const { type } = responseData;
        switch (true) {
          case (errDetail === ERRORS.LOCKED_USER): {
            errorMessage = Localization.CONFIRMATION_CODE_EXPIRED;
            break;
          }
          // user entered the wrong code
          case (
            status === 403
            || type === STATUS_CODES.INVALID_TOTP
            || errDetail === ERRORS.INVALID_CLIENT
            || errDetail === ERRORS.INVALID_CODE
          ): {
            errorMessage = Localization.CONFIRMATION_CODE_INVALID;
            break;
          }
          //  Token was already used
          case (errDetail.startsWith('Token jti')): {
            errorMessage = Localization.CONFIRMATION_CODE_ALREADY_USED;
            expired = true;
            break;
          }
          // token has expired
          case (errDetail === ERRORS.INVALID_TOKEN): {
            errorMessage = Localization.EXPIRED_SESSION;
            expired = true;
            break;
          }
          default:
            errorMessage = Localization.INTERNAL_ERROR_MESSAGE;
        }
      }
      dispatch({
        type: ACTION_NAMES.VERIFY_FACTOR_REQUEST_FAIL,
        error: errorMessage,
        expired,
      });
    });
  };
}

export function resetErrorMessage() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.RESET_ERROR_MESSAGE });
  };
}

export default function verifyPhoneReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.VERIFY_PHONE_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.VERIFY_FACTOR_REQUEST_START:
      return { ...state,
        loading: true,
        successMessage: '',
        errorMessage: '' };

    case ACTION_NAMES.VERIFY_FACTOR_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true };

    case ACTION_NAMES.VERIFY_FACTOR_REQUEST_FAIL:
    case ACTION_NAMES.AUTHORIZE_REQUEST_FAIL: {
      const errorMessage = typeof action.error === 'object' ? action.errorMessage : action.error;
      return { ...state,
        loading: false,
        success: false,
        expired: action.expired,
        error: errorMessage };
    }

    case ACTION_NAMES.RESET_ERROR_MESSAGE:
      return { ...state,
        errorMessage: '',
        error: '' };

    default:
      return { ...state };
  }
}

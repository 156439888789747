import get from 'lodash/get';
import { config } from '../../../../config/config';
import {
  ACTION_NAMES,
  login,
} from '../../../../actions/actions';
import { ERRORS } from '../../../../consts/errors';
import { STATUS_CODES } from '../../../../consts/status-codes';
import Localization from '../../../../assets/localization';
import { secondsToHms } from '../../../../utils/utils';

const initialState = {
  loading: false,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.VERIFY_EMAIL_INITIAL_STATE });
  };
}

export function verifyEmail(email, verificationCode) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.VERIFY_EMAIL_REQUEST_START });

    /* login/auth */
    login(email, verificationCode).then((res) => {
      const data = (res && res.data) || {};
      if (data.status !== STATUS_CODES.PASSWORD_EXPIRED) {
        dispatch({ type: ACTION_NAMES.WRONG_STATUS, status: JSON.stringify(data) });
      } else {
        if (data.user && data.user.phone) {
          dispatch({ type: ACTION_NAMES.GET_USER_PHONE_SUCCESS, phone: data.user.phone });
        }
        const stateToken = res.data.state_token;
        dispatch({ type: ACTION_NAMES.GET_STATE_TOKEN_SUCCESS, stateToken });
        dispatch({ type: ACTION_NAMES.VERIFY_EMAIL_REQUEST_SUCCESS, stateToken });
      }
    }).catch((response = {}) => {
      let errorMessage;
      const responseData = get(response, 'response.data');
      if (responseData) {
        const errDetail = responseData.detail;
        const { type } = responseData;
        switch (true) {
          case errDetail === ERRORS.REQUIRED_PROPERTY:
            errorMessage = Localization.RESTART_THE_PROCESS;
            break;
          case errDetail === ERRORS.LOCKED_USER || type === STATUS_CODES.PASSWORD_LOCKED: {
            errorMessage = Localization.CONFIRMATION_CODE_EXPIRED;
            break;
          }
          case errDetail === ERRORS.INVALID_CLIENT: {
            errorMessage = Localization.CONFIRMATION_CODE_INVALID;
            break;
          }
          case errDetail === ERRORS.TOO_MANY_ATTEMPTS: {
            const errorHeaders = get(response, 'response.headers');
            let time = '';
            if (errorHeaders['retry-after']) {
              time = secondsToHms(errorHeaders['retry-after']);
            }
            errorMessage = Localization.RATE_LIMIT_ERROR(time);
            break;
          }
          case errDetail === ERRORS.SSO_REQUIRED: {
            errorMessage = Localization.formatString(
              Localization.LOGIN_WITHOUT_SSO_ERROR,
              config.org_short_name,
            );
            break;
          }
          default:
            errorMessage = Localization.INTERNAL_ERROR_MESSAGE;
        }
      }
      dispatch({ type: ACTION_NAMES.VERIFY_EMAIL_REQUEST_FAIL, error: errorMessage });
    });
  };
}

export default function verifyEmailReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.VERIFY_EMAIL_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.VERIFY_EMAIL_REQUEST_START:
      return { ...state, loading: true };

    case ACTION_NAMES.VERIFY_EMAIL_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        success: true };

    case ACTION_NAMES.VERIFY_EMAIL_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };

    default:
      return { ...state };
  }
}

import React, { Component } from 'react'
import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Localization from '../../assets/localization';
import SecondaryButton from '../secondary-button';
import './password-meter.sass';

class PasswordMeter extends Component {
  state = {
    score: 0,
    isValid: false,
    password: '',
    isPasswordRevealed: false,
    showIcon: false,
    suggestions: [],
    warning: '',
  };

  setRef = (ref) => {
    this.PasswordMeterInput = ref;
  };

  handleChange = (e) => {
    const { changeCallback, minScore, userInputs, minLength } = this.props;
    const password = this.PasswordMeterInput.value;

    this.props.input.onChange(e);
    this.setState({ showIcon: password.length > 0 });

    let score = 0;
    let result = null;
    let suggestions = ['Password is too short'];
    let warning = '';

    // always sets a zero score when min length requirement is not met
    // avoids unnecessary zxcvbn computations (CPU intensive)
    if (password.length >= minLength) {
      result = zxcvbn(password, userInputs);
      const { score: resultScore, feedback: resultFeedback } = result;
      const {
        warning: resultWFeedbackWarning,
        suggestions: resultWFeedbackSuggestions,
      } = resultFeedback;
      score = resultScore;
      warning = resultWFeedbackWarning;
      suggestions = resultWFeedbackSuggestions;
      if (warning.length) {
        suggestions = suggestions.concat(warning);
      }
    }

    this.setState(
      {
        isValid: score >= minScore && password.length >= minLength,
        password,
        score,
        suggestions,
        warning,
      },
      () => {
        if (typeof changeCallback === 'function') {
          changeCallback(this.state, result);
        }
      },
    );
  };

  togglePasswordReveal = () => {
    this.setState(prevState => ({ isShown: !prevState.isShown }));
  };

  render() {
    const { score, password, isValid, isShown, showIcon } = this.state;
    const {
      scoreWords,
      className,
      tooShortWord,
      minLength,
      input,
      autoFocus,
      placeholder,
    } = this.props;

    const inputClasses = ['password-input'];
    const strengthDesc = password.length < minLength ? tooShortWord : scoreWords[score];

    if (isValid === true) {
      inputClasses.push('is-password-valid');
    } else if (password.length > 0) {
      inputClasses.push('is-password-invalid');
    }

    return (
      <div>
        <div
          className={classNames('password-meter', className, {
            [`active is-strength-${score}`]: password.length > 0,
          })}
        >
          <input
            type={isShown ? 'text' : 'password'}
            {...input}
            className={classNames(inputClasses)}
            onChange={this.handleChange}
            ref={this.setRef}
            value={password}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />

          <div className="strength-bar-container">
            <span className="strength-desc">{strengthDesc}</span>
            <div className="strength-bar">
              <span className="strength-bar-meter" />
            </div>
          </div>
          <SecondaryButton
            title={isShown ? 'Hide password' : 'Show password'}
            onClick={this.togglePasswordReveal}
            className={classNames('show-password-btn', { visible: showIcon })}
          />
        </div>
        <div className="password-suggestions-container">
          <ul
            className={classNames('password-suggestions', {
              visible: showIcon && this.state.suggestions.length,
            })}
          >
            {/*  eslint-disable-next-line */}
            {this.state.suggestions.map(suggestion => (
              <li key={`suggestion-${suggestion}`} className="suggestion">
                {suggestion}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

PasswordMeter.propTypes = {
  changeCallback: PropTypes.func,
  className: PropTypes.string,
  minLength: PropTypes.number,
  minScore: PropTypes.number,
  scoreWords: PropTypes.arrayOf(PropTypes.string),
  tooShortWord: PropTypes.string,
  userInputs: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
};

PasswordMeter.defaultProps = {
  changeCallback: () => {},
  className: '',
  minLength: 8,
  minScore: 0,
  scoreWords: Localization.FORM_FIELDS.PASSWORD_FIELD.PASSWORD_STRENGTH_WORDS,
  tooShortWord: Localization.FORM_FIELDS.PASSWORD_FIELD.TOO_SHORT,
  userInputs: [],
  placeholder: 'New Password',
};

export default PasswordMeter;

import React, { Component } from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { isValidToken } from '../../utils/utils';
import SuccessPage from '../../components/success-page';
import Localization from '../../assets/localization';
import IdpButton from '../../components/idp-button';
import ErrorPage from '../error/components/error-page';
import './headless-login-done-container.sass';

class HeadlessLoginDoneContainer extends Component {
  constructor(props) {
    super(props);
    const { location, history } = props;
    const parsedQueryParams = qs.parse(window.location.search);
    const { code } = parsedQueryParams;
    delete parsedQueryParams.code;
    history.push({
      ...location,
      search: qs.stringify(parsedQueryParams),
    });
    this.state = {
      code: isValidToken(code) ? code : null,
    };
  }

  render() {
    return (
      <div className="headless-login-done-container login-wrapper">
        {this.state.code && (
          <SuccessPage
            subtitle={Localization.HEADLESS_LOGIN_DONE_PAGE.SUBTITLE}
            paragraphA={Localization.HEADLESS_LOGIN_DONE_PAGE.PARAGRAPH}
          >
            <CopyToClipboard text={this.state.code}>
              <IdpButton
                className="medium apps-icon"
                idpIconClassName="icon-copy"
                onClick={() => {}}
                title={Localization.HEADLESS_LOGIN_DONE_PAGE.BUTTON}
              />
            </CopyToClipboard>
          </SuccessPage>
        )}
        {!this.state.code && (
          <ErrorPage
            title={Localization.HEADLESS_LOGIN_DONE_PAGE.NO_CODE_PROVIDED_SUBTITLE}
            error={Localization.HEADLESS_LOGIN_DONE_PAGE.NO_CODE_PROVIDED_PARAGRAPH}
            avoidAdminMessage
          />
        )}
      </div>
    );
  }
}

export default withRouter(HeadlessLoginDoneContainer);

import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import SecondaryButton from '../secondary-button';
import { getQueryStringWithoutParam } from '../../utils/utils';
import './bottom-link.sass';

function BottomLink(props) {
  const {
    textBeforeLink,
    linkHref,
    linkTitle,
    handleLinkClick,
    textAfterLink,
    type,
    className,
    bottomLinkShow,
    containerStyle,
  } = props;
  return bottomLinkShow
    ? (
      <div className="bottom-link-container" style={containerStyle}>
        <span className="bottom-link-text">{textBeforeLink}</span>
        <SecondaryButton
          className={ClassNames(className, 'link-container')}
          type={type || 'link'}
          link={{
            pathname: linkHref,
            search: getQueryStringWithoutParam('email'),
          }}
          onClick={handleLinkClick}
          title={linkTitle}
        />
        <span className="bottom-link-text">{textAfterLink}</span>
      </div>
    ) : '';
}

BottomLink.defaultProps = {
  handleLinkClick: () => {},
  bottomLinkShow: true,
};

BottomLink.propTypes = {
  handleLinkClick: PropTypes.func,
  bottomLinkShow: PropTypes.bool,
};

export default BottomLink;

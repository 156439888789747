import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './major-button.sass';

function MajorButton(props) {
  if (props.type === 'externalLink') {
    return (
      <a
        className={classNames('major-button', props.className)}
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={props.onClick}
        style={{ ...props.style }}
      >
        {props.title}
      </a>
    );
  }
  if (props.type === 'link') {
    return (
      <Link
        className={classNames('major-button', props.className)}
        to={props.link}
        style={{ ...props.style }}
      >
        {props.title}
      </Link>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type}
      className={classNames('major-button', props.className)}
      style={{ ...props.style }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
}

MajorButton.defaultProps = {
  type: 'button',
  title: '',
  disabled: false,
  onClick: () => {},
};

MajorButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MajorButton;

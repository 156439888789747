import React from 'react';
import './meta-page-image.sass';

function MetaPageImage(props) {
  return (
    <div
      className="meta-page-image"
      style={{ ...{ backgroundImage: `url(${props.image})` }, ...props.style }}
    />
  );
}

export default MetaPageImage;

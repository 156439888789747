const letterNumber = /^[a-z0-9]+$/;
const email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const phoneNumber = /^\+[1-9]\d{4,16}$/;
const lowerCase = /^[a-z]*$/;
// eslint-disable-next-line no-control-regex
const asciiOnly = /^[\x00-\x7F]*$/;

const Validator = {
  isAsciiOnly(value) {
    return Boolean(value.match(asciiOnly));
  },
  isNotEmpty(value) {
    const val = Number.isInteger(value) ? value.toString() : value;
    return Boolean(val && val?.trim?.());
  },
  isEmail(value) {
    return Boolean(value.match(email));
  },
  isLowerCaseAlphaNumeric(value) {
    return Boolean(value.match(letterNumber));
  },
  isNumberInRange(value, min, max) {
    const number = Number(value);
    return Number.isInteger(number) ? number >= min && number <= max : false;
  },
  isPhoneNumber(value) {
    return Boolean(value.match(phoneNumber));
  },
  isAllLowerCase(value) {
    return Boolean(value.match(lowerCase));
  },
};

export default Validator;

export const tableCellTopDate = 'MMM DD, YYYY';
export const tableCellBottomDate = 'h:mm:ss A';
export const shortHourTime = 'h:mm:ss A';
export const dateLongTime = 'YYYY-M-DD, h:mm:ss A';
export const reportDateLongTime = 'YYYY-M-DD_hh:mm';
export const longDate = 'MMMM Do YYYY';
export const shortDate = 'D MMM';
export const monthDate = 'MMM';
export const shortDateTime = 'D MMM h:mm A';
export const shortTime = 'h:mm A';
export const longTime = 'h:mm:ss';
export const longDateTime = 'DD MMM YYYY HH:mm:ss';

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import ReactCodeInput from 'react-code-input';
import { getUrlParameter } from '../../utils/utils';
import { FormError, TextInputField } from '../../components/form-fields';
import BottomLink from '../../components/bottom-link/bottom-link';
import Localization from '../../assets/localization';
import './enter-pin.sass';

const iosPlatformKey = 'ios';

const EnterPinForm = (props) => {
  const {
    onChange,
    errorMessage,
    isValid,
    formFooterText,
    goBackLink,
    resendCodeLink,
  } = props;

  const commonInputStyle = {
    width: '40px',
    height: '40px',
    margin: '4px',
    fontFamily: 'Meta-Regular',
    textAlign: 'center',
    borderRadius: '7px',
    fontSize: '30px',
    border: '2px solid #5479FF',
  };

  const reactCodeInputProps = {
    inputStyle: {
      ...commonInputStyle,
      border: '2px solid #5479FF',
    },
    inputStyleInvalid: {
      ...commonInputStyle,
      border: '2px solid #9EA9C8',
    },
  };

  const app = getUrlParameter('app') || '';

  function onInputFieldChange(event) {
    if (event.currentTarget) {
      onChange(event.currentTarget.value);
    }
  }

  return (
    <form className="enter-pin-form">
      <div className="code-input-container">
        {(app === iosPlatformKey)
          ? (
            <div>
              <Field
                name="pin"
                type="tel"
                component={TextInputField}
                autoFocus
                placeholder="Enter Code"
                className="ios-code-field"
                input={{ maxLength: '6', onKeyUp: onInputFieldChange }}
              />
              <FormError message={errorMessage} isShown={Boolean(errorMessage)} />
            </div>
          )
          : (
            <div>
              <ReactCodeInput
                type="number"
                fields={6}
                {...reactCodeInputProps}
                onChange={onChange}
                isValid={isValid}
              />
              <div className="digits-separator">-</div>
            </div>
          )}
      </div>
      <div className="form-footer">{formFooterText}</div>
      {resendCodeLink
      && (
        <BottomLink
          linkTitle={resendCodeLink.linkTitle}
          handleLinkClick={resendCodeLink.onClick}
          className={resendCodeLink.class}
        />
      )}
      {goBackLink
        && (
          <BottomLink
            textBeforeLink={Localization.GO_BACK_LINK}
            linkTitle={goBackLink.linkTitle}
            linkHref={goBackLink.href}
            className={goBackLink.class}
            handleLinkClick={goBackLink.onClick}
            type={goBackLink.type}
          />
        )}
    </form>
  );
};

export default reduxForm({
  form: 'enterPinForm',
  touchOnBlur: false,
})(EnterPinForm);

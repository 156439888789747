import {
  ACTION_NAMES,
  loginWithOIDC,
} from '../../actions/actions';

const initialState = {
  loading: true,
};

export function cleanState() {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.APP_VIEW_INITIAL_STATE });
  };
}

export function loginWithOIDCDispatch(accessToken) {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.GET_OIDC_REDIRECT });
    loginWithOIDC(accessToken)
      .then(response => {
        dispatch({
          type: ACTION_NAMES.LOGIN_WITH_OIDC_SUCCESS,
          redirectURL: response.data.redirect_url,
        });
      })
      .catch(error => {
        dispatch({
          type: ACTION_NAMES.LOGIN_WITH_OIDC_FAIL,
          error: error?.response?.data,
        });
      });
  };
}

export function onOidcViewLoaded() {
  return dispatch => {
    dispatch({ type: ACTION_NAMES.OIDC_VIEW_PAGE_LOADED });
  };
}

export default function appViewReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.OIDC_VIEW_PAGE_LOADED:
      return { ...state, loading: false };
    case ACTION_NAMES.OIDC_VIEW_INITIAL_STATE:
      return initialState;
    case ACTION_NAMES.LOGIN_WITH_OIDC_SUCCESS:
      return { ...state, redirectURL: action.redirectURL, loading: false };
    case ACTION_NAMES.LOGIN_WITH_OIDC_FAIL:
      return { ...state, error: action.error, loading: false };

    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        accessToken: action.response.data.access_token };
    case ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_FAIL:
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_FAIL:
      return { ...state,
        loading: false,
        success: false,
        error: action.error };
    case ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_SUCCESS:
      return { ...state,
        tokenFetched: true,
        loading: false,
        accessToken: action.response.data.access_token };
    case ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        ssoRedirectToLogin: action.response };
    case ACTION_NAMES.GET_DIRECT_SSO_IDP_REQUEST_FAIL:
      return { ...state,
        loading: false,
        ssoRedirectToLogin: {} };
    default:
      return { ...state };
  }
}

import { loginWithCode, getOAuthInfo as getOAuthInfoAction, getNewToken, ACTION_NAMES } from '../actions/actions'
import { log, saveCookiesToDomain, clearCookiesFromDomain } from '../utils/utils';

export const getOAuthInfo = accessToken => dispatch => {
  dispatch({ type: ACTION_NAMES.GET_OAUTH_INFO_START });
  log('ACTION: getOrgLicenses');
  getOAuthInfoAction(accessToken)
    .then((response = {}) => {
      dispatch({ type: ACTION_NAMES.GET_OAUTH_INFO_SUCCESS, response });
    })
    .catch((error = {}) => {
      const errorMessage = error.message;
      log(`ERR: getOrgLicenses ${error.message}`);
      dispatch({ type: ACTION_NAMES.GET_OAUTH_INFO_FAIL, error: errorMessage });
    });
};

export const getTokenFromOtp = otp => dispatch => {
  dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_START });
  log('ACTION: getTokenFromOtp');
  loginWithCode(otp)
    .then((response = {}) => {
      saveCookiesToDomain(response.data);
      dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_SUCCESS, response });
    })
    .catch((error = {}) => {
      const errorMessage = error.message;
      log(`ERR: getTokenFromOtp ${error.message}`);
      dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_OTP_REQUEST_FAIL, error: errorMessage });
    });
};

export const getTokenFromCookie = refreshToken => dispatch => {
  dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_START });
  log('ACTION: getTokenFromCookie');
  getNewToken(refreshToken)
    .then((response = {}) => {
      saveCookiesToDomain(response.data);
      dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS, response });
    })
    .catch((error = {}) => {
      clearCookiesFromDomain();
      const errorMessage = error.message;
      log(`ERR: getTokenFromCookie ${error.message}`);
      dispatch({ type: ACTION_NAMES.GET_TOKEN_FROM_COOKIE_REQUEST_FAIL, error: errorMessage });
    });
};

import React from 'react';
import classNames from 'classnames';
import './meta-page-titles.sass';

function PageTitles(props) {
  return (
    <div className={classNames('meta-page-titles', props.className)}>
      {props.subtitle && <div className="meta-page-sub-title" title={props.subtitleTitle} style={{ ...props.subtitleStyle }}>{props.subtitle}</div>}
      {props.paragraph && <div className="meta-page-paragraph" title={props.paragraphTitle} style={{ ...props.paragraphStyle }}>{props.paragraph}</div>}
    </div>
  );
}

export default PageTitles;

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import find from 'lodash/find';
import Cookies from 'js-cookie';
import { config } from '../../config/config';
import { getEnvSuffix } from '../../utils/utils';
import MetaLoader from '../../components/meta-loader';
import {
  login,
  loginWithSaml,
  sendPinCode,
  toggleMoreMethodsModal,
  changeVerificationMethod,
  resetErrorMessage,
  checkExistingToken,
  checkSamlAvailable,
  getLoginSettings,
  goToPasswordLogin,
  checkRememberMeAvailable,
  goToOrgLogin,
} from '../../scenes/login/login-state';
import { verifyPinCode } from '../../scenes/app/app-state';
import { ROUTES } from '../../consts/routes';
import { INVOKE_LOCAL_LOGIN, PERSIST_LOGIN } from '../../consts/general-consts';
import LoginNoOrgPage from './components/login-no-org-page';
import VerifyPinPage from './components/verify-pin-page';
import ChooseLoginMethodPage from './components/choose-login-method';
import './login-container.sass'

export const getUrlParameter = (name = '', isCheckOnHref) => {
  if (!name) {
    return '';
  }
  const nameParam = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${nameParam}=([^&]*)`);
  const urlQuery = isCheckOnHref ? window.location.href : window.location.search;
  const results = regex.exec(urlQuery);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getCookie = key => Cookies.get(key);
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      envSuffix: getEnvSuffix(),
    };
  }

  componentDidMount() {
    this.props.checkRememberMeAvailable();
    this.props.checkExistingToken(this.props.pkceChallenge);
    this.props.checkSamlAvailable(this.props.pkceChallenge);
    this.props.getLoginSettings();
    const invokeLoginWithSSO = getUrlParameter('invokeLoginWithSSO') || getUrlParameter('direct_sso');
    if (
      invokeLoginWithSSO
      && invokeLoginWithSSO !== INVOKE_LOCAL_LOGIN
    ) {
      this.loginWithSaml(
        invokeLoginWithSSO,
        this.props.pkceChallenge,
      );
    }
  }

  login = (formData) => {
    this.props.login(
      formData.email,
      formData.password,
      formData.remember,
      this.props.pkceChallenge,
    );
  };

  loginWithSaml = (idpId, pkceChallenge) => {
    this.props.loginWithSaml(idpId, pkceChallenge);
  };

  goToOrgLogin = (formData) => {
    this.props.goToOrgLogin(formData.org);
  };

  goToPasswordLogin = () => {
    this.props.goToPasswordLogin();
  };

  verifyPinCode = (formData) => {
    this.props.verifyPinCode(
      formData.pin,
      this.props.stateToken,
      this.props.verificationMethod,
      this.props.pkceChallenge,
    );
  };

  resendPinCode = () => {
    this.props.sendPinCode(
      this.props.verificationMethod,
      this.props.stateToken,
    );
  };

  toggleMoreMethodsModal = (showModal) => {
    this.props.toggleMoreMethodsModal(showModal);
  };

  isAllLoginFormValuesExist = () => {
    if (this.props.loginForm) {
      const { registeredFields = {}, fields = {} } = this.props.loginForm;
      if (!fields) {
        return false;
      }
      return (
        Object.keys(registeredFields).length === Object.keys(fields).length
      );
    }
    return false;
  };

  isApp = () => window.location.search.indexOf('app') !== -1;

  render() {
    const {
      mfaFactors,
      idps = [],
      passwordExpired,
      componentReady,
      samlCheckReady,
      appearance,
      appearanceCheckReady,
      invokeSSOFailed,
      invokeLoginWithSSO,
      pkceChallenge,
      hideSSOFromQS,
      hideLocalLoginFromQS,
      showSelectMethodScreen,
      shouldShowVerifyPinPage,
      loading,
      successMessage,
      errorMessage,
      showRememberMe,
      samlAvailable,
      samlCheckError,
      email,
      phoneNumber,
      userEmail,
      verificationMethod,
      verificationMethodOptions,
      showVerificationMethodsModal,
    } = this.props;
    const { envSuffix } = this.state;
    const isApp = this.isApp();
    const organizationShortName = config.org_short_name || 'login';
    const serviceName = config.service_name;
    const persistLogin = getCookie(PERSIST_LOGIN);
    let persistObject;
    try {
      persistObject = persistLogin && JSON.parse(persistLogin);
    } catch (e) {
      console.log(e);
    }
    let returnComp;

    if (mfaFactors && mfaFactors.length > 0) {
      return <Redirect to={`${ROUTES.SELECT_MFA}${window.location.search}`} />;
    }

    if (passwordExpired) {
      return (
        <Redirect to={`${ROUTES.SET_PASSWORD}${window.location.search}`} />
      );
    }
    if (
      !componentReady
      || !samlCheckReady
      || !appearanceCheckReady
      || (!invokeSSOFailed
        && invokeLoginWithSSO
        && invokeLoginWithSSO !== INVOKE_LOCAL_LOGIN)
    ) {
      returnComp = (
        <div className="login-container-loader">
          <MetaLoader isShown />
        </div>
      );
    } else if (persistObject?.idpId && find(idps, { id: persistObject.idpId })) {
      this.loginWithSaml(persistObject.idpId);
      returnComp = (
        <div className="login-container-loader">
          <MetaLoader isShown />
        </div>
      );
    } else if (organizationShortName === 'login') {
      returnComp = (
        <LoginNoOrgPage
          onSubmit={this.goToOrgLogin}
          envSuffix={envSuffix}
          isApp={isApp}
        />
      );
    } else if (
      appearance
      && appearance.directSSO
      && find(idps, { id: appearance.directSSO })
      && this.props.invokeLoginWithSSO !== 'false'
    ) {
      this.loginWithSaml(appearance.directSSO, pkceChallenge);
      returnComp = (
        <div className="login-container-loader">
          <MetaLoader isShown />
        </div>
      );
    } else if (showSelectMethodScreen || !shouldShowVerifyPinPage) {
      returnComp = (
        <ChooseLoginMethodPage
          loginWithSaml={this.loginWithSaml}
          appearance={this.props.appearance}
          organizationName={this.props.orgName}
          organizationShortName={organizationShortName}
          serviceName={serviceName}
          idps={idps}
          goToPasswordLogin={this.goToPasswordLogin}
          invokeLoginWithSSO={invokeLoginWithSSO}
          hideSSOFromQS={hideSSOFromQS}
          hideLocalLoginFromQS={hideLocalLoginFromQS}
          pkceChallenge={pkceChallenge}
          loading={loading}
          login={this.login}
          errorMessage={errorMessage}
          resetErrorMessage={this.props.resetErrorMessage}
          isAllValuesExist={this.isAllLoginFormValuesExist()}
          showRememberMe={showRememberMe}
          samlAvailable={samlAvailable}
          samlCheckError={samlCheckError}
          email={email}
        />
      );
    } else {
      returnComp = (
        <VerifyPinPage
          loading={loading}
          successMessage={successMessage}
          errorMessage={errorMessage}
          phoneNumber={phoneNumber}
          userEmail={userEmail}
          resendPinCode={this.resendPinCode}
          verifyPinCode={this.verifyPinCode}
          verificationMethod={verificationMethod}
          verificationMethodOptions={verificationMethodOptions}
          changeVerificationMethod={this.props.changeVerificationMethod}
          showVerificationMethodsModal={showVerificationMethodsModal}
          toggleMoreMethodsModal={this.toggleMoreMethodsModal}
        />
      );
    }
    return <div className="login-wrapper">{returnComp}</div>;
  }
}

function mapStateToProps(state) {
  return {
    ...state.loginState,
    ...state.AppState,
    mfaFactors: state.selectMfaState.mfaFactors,
    loginForm: state.form.loginForm,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
      loginWithSaml,
      goToOrgLogin,
      goToPasswordLogin,
      verifyPinCode,
      sendPinCode,
      toggleMoreMethodsModal,
      changeVerificationMethod,
      resetErrorMessage,
      checkExistingToken,
      checkSamlAvailable,
      getLoginSettings,
      checkRememberMeAvailable,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);

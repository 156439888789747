import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import MetaLoader from '../../components/meta-loader';
import { ROUTES } from '../../consts/routes';
import { getRedirectRoute } from '../../utils/utils';
import * as Actions from '../../redux/action-creators';

const RedirectContainer = ({
  getTokenFromOtp,
  getOAuthInfo,
  tokenFetched,
  accessToken,
  oauthInfoLoaded,
  privileges,
  ztnaLicense,
  wsLicense,
  error,
}) => {
  const redirectRoutes = {
    ssp: 'ssp',
    ztna: 'ztna',
    ws: 'ws',
  };
  const [isCodeValid, setIsCodeValid] = useState(true);

  useEffect(() => {
    const parsedQueryParams = queryString.parse(window.location.search);
    if (parsedQueryParams?.code) {
      getTokenFromOtp(parsedQueryParams.code);
    } else {
      setIsCodeValid(false);
    }
  }, []);

  useEffect(() => {
    if (tokenFetched && accessToken) {
      getOAuthInfo(accessToken);
    }
  }, [tokenFetched]);

  const redirectToAdminPortal = () => {
    if (!privileges || !privileges.length) {
      getRedirectRoute(redirectRoutes.ssp);
    }
    const redirectUrl = ztnaLicense && !wsLicense
      ? getRedirectRoute(redirectRoutes.ztna)
      : getRedirectRoute(redirectRoutes.ws);
    window.location.replace(redirectUrl);
    return <MetaLoader />;
  };

  if (oauthInfoLoaded) {
    redirectToAdminPortal();
  }

  if (!isCodeValid || error) {
    return <Redirect to={`${ROUTES.LOGIN}`} />;
  }
  return <MetaLoader />;
};

const mapStateToProps = state => ({
  ...state.redirectContainerState,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getTokenFromOtp: Actions.getTokenFromOtp,
    getOAuthInfo: Actions.getOAuthInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(RedirectContainer);

import get from 'lodash/get'
import { config } from '../../config/config';
import { authorize } from '../../scenes/app/app-state';
import {
  ACTION_NAMES,
  login as loginAction,
  skipEnroll as skipAction,
  loginWithSaml as loginWithSamlAction,
  getAppearanceSettings,
  sendChallenge,
  VerificationMethodsEnum,
  redirectToOrgLogin,
} from '../../actions/actions';

import {
  APPLICATION_ENUMS,
  secondsToHms,
  log,
  saveCookie,
  removeCookie,
  getCookie,
} from '../../utils/utils';
import { SESSION_TOKEN, PERSIST_LOGIN } from '../../consts/general-consts';
import { STATUS_CODES } from '../../consts/status-codes';
import Localization from '../../assets/localization';
import { decode } from 'js-base64';

export const getErrorMessage = error => {
  if (typeof error === 'string') {
    return error;
  }

  if (error && error.message) {
    return error.message;
  }

  return '';
};

export const getUrlParameter = (name = '', isCheckOnHref) => {
  if (!name) {
    return '';
  }
  const nameParam = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${nameParam}=([^&]*)`);
  const urlQuery = isCheckOnHref ? window.location.href : window.location.search;
  const results = regex.exec(urlQuery);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const initialState = {
  componentReady: false,
  samlAvailable: false,
  showRememberMe: true,
  samlCheckReady: false,
  appearanceCheckReady: false,
  appearance: {},
  orgName: '',
  loading: false,
  showSelectMethodScreen: true,
};

export function cleanState() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.LOGIN_INITIAL_STATE });
  };
}

function getPinCodeErrorMessage(errorMessage, method) {
  if (errorMessage === 'Network Error') {
    return Localization.NO_CONNECTION_MESSAGE;
  }
  switch (method) {
    case VerificationMethodsEnum.VOICECALL:
      return Localization.LOGIN_PAGE.UNABLE_TO_SEND_CODE_BY_PHONE;
    case VerificationMethodsEnum.SMS:
      return Localization.LOGIN_PAGE.UNABLE_TO_SEND_SMS;
    default:
      return Localization.LOGIN_PAGE.UNABLE_TO_SEND_SMS;
  }
}

export function sendPinCode(verificationMethod, stateToken, resentSms = true) {
  return (dispatch, getState) => {
    dispatch({ type: ACTION_NAMES.SEND_PIN_REQUEST_START });
    sendChallenge(verificationMethod, stateToken).then((response = {}) => {
      const phoneNumber = response.data && response.data.phone;
      const currentMethods = getState().loginState.verificationMethodOptions;
      const verificationMethods = currentMethods || [VerificationMethodsEnum.SMS];
      dispatch({ type: ACTION_NAMES.SEND_PIN_REQUEST_SUCCESS, resentSms });
      dispatch({
        type: ACTION_NAMES.LOGIN_WITH_2FA, verificationMethods, stateToken, phoneNumber,
      });
    }).catch((response = {}) => {
      const error = getPinCodeErrorMessage(response.message, verificationMethod);
      dispatch({ type: ACTION_NAMES.SEND_PIN_REQUEST_FAIL, error });
    });
  };
}

export function checkSamlAvailable(pkceChallenge) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.CHECK_SAML_EXIST_START });
    loginWithSamlAction(pkceChallenge)
      .then((response = {}) => {
        const samlResponse = response.data;
        if (samlResponse && samlResponse.length) {
          dispatch({
            type: ACTION_NAMES.CHECK_SAML_EXIST_FINISH,
            samlAvailable: true,
            idps: samlResponse,
          });
        } else {
          dispatch({ type: ACTION_NAMES.CHECK_SAML_EXIST_FINISH, samlAvailable: false });
        }
      }).catch((error = {}) => {
        dispatch({
          type: ACTION_NAMES.CHECK_SAML_EXIST_FINISH,
          samlAvailable: false,
          samlCheckError: error.message === 'Network Error',
        });
      });
  };
}

export function getLoginSettings() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.GET_APPEARANCE_SETTINGS_START });
    getAppearanceSettings()
      .then((response = {}) => {
        const appearanceResponse = response.data;
        if (appearanceResponse) {
          dispatch({
            type: ACTION_NAMES.GET_APPEARANCE_SETTINGS_FINISH,
            directSSO: appearanceResponse.direct_sso,
            hideLocalLogin: appearanceResponse.hide_local_login,
            hideSSOLogin: appearanceResponse.hide_sso,
            orgName: appearanceResponse.org_name,
          });
        } else {
          dispatch({ type: ACTION_NAMES.GET_APPEARANCE_SETTINGS_FINISH });
        }
      }).catch((error = {}) => {
        dispatch({
          type: ACTION_NAMES.GET_APPEARANCE_SETTINGS_FINISH,
          error,
        });
      });
  };
}

export function checkRememberMeAvailable() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.CHECK_REMEMBER_ME_AVAILABLE_START });
    const service = getUrlParameter('service');
    let showRememberMe = true;
    if (service === APPLICATION_ENUMS.OVERLAY) {
      showRememberMe = false;
    }
    dispatch({ type: ACTION_NAMES.CHECK_REMEMBER_ME_AVAILABLE_FINISH, showRememberMe });
  };
}

export function checkExistingToken(pkceChallenge) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.CHECK_EXISTING_TOKEN_START });
    const sessionToken = getCookie(SESSION_TOKEN);
    const logout = getUrlParameter('logout');
    if (logout) {
      removeCookie(SESSION_TOKEN);
      removeCookie(PERSIST_LOGIN);
    }
    if (sessionToken && !logout) {
      dispatch(authorize(sessionToken, true, pkceChallenge));
    } else {
      dispatch({ type: ACTION_NAMES.CHECK_EXISTING_TOKEN_FINISH });
    }
  };
}

export function goToOrgLogin(orgShortName) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.REDIRECT_TO_ORG_LOGIN_START, orgShortName });
    redirectToOrgLogin(orgShortName);
    dispatch({ type: ACTION_NAMES.REDIRECT_TO_ORG_LOGIN_FINISH });
  };
}

export function goToPasswordLogin() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.GO_TO_PASSWORD_LOGIN });
  };
}

export function resetErrorMessage() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.RESET_ERROR_MESSAGE });
  };
}

export function skipEnroll(stateToken, pkceChallenge) {
  return (dispatch) => {
    skipAction(stateToken).then((response = {}) => {
      const data = (response && response.data) || {};
      const sessionToken = data.session_token;
      if (sessionToken) {
        dispatch({ type: ACTION_NAMES.LOGIN_REQUEST_SUCCESS });
        dispatch(authorize(sessionToken, false, pkceChallenge));
      }
    }).catch((error = {}) => {
      const errorStatus = get(error, 'response.status');
      const errorMessage = error.message;
      log(`ERR: login error status: ${errorStatus} error Message: ${errorMessage}`);
      let errorLabel = '';
      if (errorMessage === 'Network Error') {
        errorLabel = Localization.NO_CONNECTION_MESSAGE;
      } else if (errorStatus === 429) {
        const errorHeaders = get(error, 'response.headers');
        let time = '';
        if (errorHeaders['retry-after']) {
          time = secondsToHms(errorHeaders['retry-after']);
        }
        errorLabel = Localization.RATE_LIMIT_ERROR(time);
      } else {
        errorLabel = Localization.EXPIRED_SESSION_REFRESH;
      }

      dispatch({ type: ACTION_NAMES.SELECT_MFA_SKIP_ENROLL_ERROR, error: errorLabel });
    });
  };
}

export function login(email, password, remember, pkceChallenge) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.LOGIN_REQUEST_START, email });
    log('ACTION: login email password');
    loginAction(email, password).then((response = {}) => {
      const data = (response && response.data) || {};
      const sessionToken = data.session_token;
      const expirationInSeconds = data.expires_in;
      const stateToken = data.state_token;
      if (data.user && data.user.phone) {
        dispatch({ type: ACTION_NAMES.GET_USER_PHONE_SUCCESS, phone: data.user.phone });
      }

      if (sessionToken) {
        dispatch({ type: ACTION_NAMES.LOGIN_REQUEST_SUCCESS });
        if (remember) {
          saveCookie(
            SESSION_TOKEN,
            sessionToken,
            { expirationInSeconds: expirationInSeconds - 20 },
          );
        }
        dispatch(authorize(sessionToken, false, pkceChallenge));
      } else if (data.status === STATUS_CODES.PASSWORD_EXPIRED) {
        dispatch({
          type: ACTION_NAMES.PASSWORD_EXPIRED,
          stateToken,
        });
      } else if (data.status === STATUS_CODES.MFA_REQUIRED
        || data.status === STATUS_CODES.MFA_ENROLL) {
        dispatch({
          type: ACTION_NAMES.GET_STATE_TOKEN_SUCCESS,
          stateToken,
        });
        dispatch({
          type: ACTION_NAMES.SELECT_MFA_GET_FACTORS_SUCCESS,
          mfaFactors: data.factors,
          mfaStatus: data.status,
        });
      } else {
        dispatch({
          type: ACTION_NAMES.LOGIN_REQUEST_FAIL,
          error: Localization.INTERNAL_ERROR_BAD_RESPONSE,
        });
      }
    }).catch((error = {}) => {
      // todo: get the error reason from one field, see NSOF-2275 for reference
      const errorStatus = get(error, 'response.status');
      const errorMessage = error.message;
      log(`ERR: login error status: ${errorStatus} error Message: ${errorMessage}`);
      let errorLabel = '';
      if (errorMessage === 'Network Error') {
        errorLabel = Localization.NO_CONNECTION_MESSAGE;
      } else if (errorStatus === 429) {
        const errorHeaders = get(error, 'response.headers');
        let time = '';
        if (errorHeaders['retry-after']) {
          time = secondsToHms(errorHeaders['retry-after']);
        }
        errorLabel = Localization.RATE_LIMIT_ERROR(time);
      } else if (get(error, 'response.data.detail') === 'The client must use SSO to login') {
        errorLabel = Localization.formatString(
          Localization.LOGIN_WITHOUT_SSO_ERROR,
          config.org_short_name,
        );
      } else {
        errorLabel = Localization.LOGIN_PAGE.INVALID_CREDENTIALS;
      }

      dispatch({ type: ACTION_NAMES.LOGIN_REQUEST_FAIL, error: errorLabel });
    });
  };
}

export function loginWithSaml(idpId, pkceChallenge) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.SAML_LOGIN_REQUEST_START });
    log('ACTION: login with SAML');

    loginWithSamlAction(pkceChallenge).then((response = {}) => {
      const samlResponse = response.data;
      if (samlResponse && samlResponse.length) {
        try {
          dispatch({ type: ACTION_NAMES.SAML_LOGIN_REQUEST_SUCCESS });
          const requestedIdp = idpId !== 'true' ? samlResponse.find(idp => idp.id === idpId) : samlResponse[0];
          if (!requestedIdp) {
            throw new Error('IDP_NOT_FOUND');
          }
          const parsedRedirectUrl = decode(requestedIdp.url);
          window.location.href = parsedRedirectUrl;
        } catch (e) {
          dispatch({
            type: ACTION_NAMES.SAML_LOGIN_REQUEST_FAIL,
            error: e && e.message === 'IDP_NOT_FOUND' ? Localization.LOGIN_PAGE.IDP_NOT_FOUND : Localization.LOGIN_PAGE.SSO_DECODE_FAILURE,
          });
        }
      } else {
        dispatch({ type: ACTION_NAMES.SAML_LOGIN_REQUEST_FAIL, error: samlResponse });
      }
    }).catch((error = {}) => {
      const errorMessage = error.message;
      log(`ERR: login with SAML: ${error.message}`);
      dispatch({ type: ACTION_NAMES.SAML_LOGIN_REQUEST_FAIL, error: errorMessage });
    });
  };
}

export function toggleMoreMethodsModal(showModal) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.TOGGLE_VERIFICATION_METHODS_MODAL, showModal });
  };
}

export function changeVerificationMethod(verificationMethod) {
  return (dispatch, getState) => {
    dispatch({ type: ACTION_NAMES.CHANGE_VERIFICATION_METHOD, verificationMethod });
    if (verificationMethod === VerificationMethodsEnum.SMS) {
      const { stateToken } = getState().loginState;
      dispatch(sendPinCode(verificationMethod, stateToken, true));
    }
    if (verificationMethod === VerificationMethodsEnum.VOICECALL) {
      const { stateToken } = getState().loginState;
      dispatch(sendPinCode(verificationMethod, stateToken, true));
    }
    dispatch({ type: ACTION_NAMES.TOGGLE_VERIFICATION_METHODS_MODAL, showModal: false });
  };
}

export default function loginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.LOGIN_INITIAL_STATE:
      return initialState;

    case ACTION_NAMES.CHECK_EXISTING_TOKEN_FINISH:
      return { ...state, componentReady: true };

    case ACTION_NAMES.CHECK_SAML_EXIST_FINISH:
      return { ...state,
        samlCheckReady: true,
        samlAvailable: action.samlAvailable,
        samlCheckError: action.samlCheckError,
        idps: action.idps };

    case ACTION_NAMES.GET_APPEARANCE_SETTINGS_FINISH:
      return { ...state,
        appearanceCheckReady: true,
        appearance: {
          directSSO: action.directSSO,
          hideLocalLogin: action.hideLocalLogin,
          hideSSOLogin: action.hideSSOLogin,
        },
        orgName: action.orgName };

    case ACTION_NAMES.AUTHORIZE_REQUEST_START:
    case ACTION_NAMES.LOGIN_REQUEST_START:
    case ACTION_NAMES.VERIFY_PIN_REQUEST_START:
    case ACTION_NAMES.SEND_PIN_REQUEST_START:
      return { ...state,
        loading: true,
        successMessage: '',
        errorMessage: '',
        userEmail: action.email || state.userEmail };

    case ACTION_NAMES.AUTHORIZE_REQUEST_SUCCESS:
      // case ACTION_NAMES.LOGIN_REQUEST_SUCCESS:
      // case ACTION_NAMES.VERIFY_PIN_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        successMessage: '',
        errorMessage: '' };

    case ACTION_NAMES.SEND_PIN_REQUEST_SUCCESS:
      return { ...state,
        loading: false,
        successMessage: action.resentSms ? Localization.LOGIN_PAGE.NEW_CODE_SENT : '',
        errorMessage: '' };

    case ACTION_NAMES.AUTHORIZE_REQUEST_FAIL:
    case ACTION_NAMES.LOGIN_REQUEST_FAIL:
    case ACTION_NAMES.VERIFY_PIN_REQUEST_FAIL:
    case ACTION_NAMES.SEND_PIN_REQUEST_FAIL:
    case ACTION_NAMES.SAML_LOGIN_REQUEST_FAIL:
      return { ...state,
        loading: false,
        successMessage: '',
        errorMessage: action.silent ? '' : action.errorMessage || getErrorMessage(action.error),
        componentReady: true,
        invokeSSOFailed: true };

    case ACTION_NAMES.LOGIN_WITH_2FA:
      return { ...state,
        loading: false,
        shouldShowVerifyPinPage: true,
        verificationMethodOptions: action.verificationMethods,
        verificationMethod: action.verificationMethod || state.verificationMethod,
        stateToken: action.stateToken,
        phoneNumber: action.phoneNumber };
    case ACTION_NAMES.CHANGE_VERIFICATION_METHOD:
      return { ...state,
        verificationMethod: action.verificationMethod,
        successMessage: '',
        errorMessage: '' };
    case ACTION_NAMES.TOGGLE_VERIFICATION_METHODS_MODAL:
      return { ...state, showVerificationMethodsModal: action.showModal };

    case ACTION_NAMES.REDIRECT_TO_ORG_LOGIN_START:
      return { ...state, loading: true };

    case ACTION_NAMES.REDIRECT_TO_ORG_LOGIN_FINISH:
      return { ...state, loading: false };

    case ACTION_NAMES.RESET_ERROR_MESSAGE:
      return { ...state, errorMessage: '' };

    case ACTION_NAMES.GO_TO_SELECT_LOGIN_METHOD:
      return { ...state, showSelectMethodScreen: true };

    case ACTION_NAMES.GO_TO_PASSWORD_LOGIN:
      return { ...state, showSelectMethodScreen: false };

    case ACTION_NAMES.CHECK_REMEMBER_ME_AVAILABLE_FINISH:
      return { ...state, showRememberMe: action.showRememberMe };

    case ACTION_NAMES.BACK_TO_LOGIN_CLICKED:
      return { ...state, showSelectMethodScreen: false };

    default: {
      const email = getUrlParameter('email');
      const invokeLoginWithSSO = getUrlParameter('invokeLoginWithSSO') || getUrlParameter('direct_sso');
      const hideSSO = getUrlParameter('hide_sso');
      const hideLocalLogin = getUrlParameter('hide_local_login');
      const pkceChallenge = getUrlParameter('metapkce');
      const isRunningLocalMonkey = getUrlParameter('isRunningLocalMonkey');
      const expandedObj = {};
      if (email) {
        expandedObj.email = email;
      }
      if (hideSSO) {
        expandedObj.hideSSOFromQS = hideSSO === 'true';
      }
      if (hideLocalLogin) {
        expandedObj.hideLocalLoginFromQS = hideLocalLogin === 'true';
      }
      if (invokeLoginWithSSO && invokeLoginWithSSO !== 'false') {
        expandedObj.invokeLoginWithSSO = invokeLoginWithSSO;
      }
      if (pkceChallenge) {
        expandedObj.pkceChallenge = pkceChallenge;
      }
      if (isRunningLocalMonkey) {
        expandedObj.isRunningLocalMonkey = isRunningLocalMonkey;
      }
      return { ...state, ...expandedObj };
    }
  }
}
